import React, { memo, useEffect, useState } from "react";
import { Dialog } from "@mui/material";
import LoginTypesForm from "./components/LoginTypesForm";
import styles from "./Login.module.scss";
import LoginClientOrAgentForm from "./components/LoginClientOrAgentForm";
import { useQuery } from "../../core/hooks/useQuery";
import { useNavigate } from "react-router-dom";
import { FormType } from "../../core/consts/common";
import { stringParser } from "../../core/utils/queryParsers";
// import AgreementModal from "../agreement-modal/AgreementModal";
import CheckComponents from "./components/check-components/CheckComponents";
import CertificateModal from "./components/Certificate/Certificate";
import PhoneForm from "./components/PhoneForm/PhoneForm";
import EmailForm from "./components/EmailForm/EmailForm";
import EmailFormWithCode from "./components/EmailFormWithCode/EmailFormWithCode";
import PhoneFormWithCode from "./components/PhoneFormWithCode/PhoneFormWithCode";
// import { useAppSelector } from "../../core/store";
import useMediaQuery from "@mui/material/useMediaQuery";
import HeaderHome from "../layoutHome/HeaderHome";
import FooterHome from "../layoutHome/FooterHome";
import { PersonalData } from "../modals/PersonalData";

const queryParsers = {
  formType: stringParser<FormType>(),
};

const Login: React.FC = () => {
  const isLagerThan480 = useMediaQuery("(min-width:480px)");

  const [open, setOpen] = useState<boolean>(false);
  const { formType } = useQuery(queryParsers);
  const navigate = useNavigate();
  const [phone, setPhone] = useState<string>("");
  const [agreementOpen, setAgreementOpen] = useState(false);

  // для отправки кода повторно
  const [email, setEmail] = useState<string>("");

  const handleClose = () => {
    setOpen(false);
    navigate({
      pathname: window.location.pathname,
    });
  };

  const handleReturnFromTypes = () => {
    // const accessToken = tokenService.getAccessToken();
    const isRestrationed = window.localStorage.getItem("isRegistration");

    navigate({
      pathname: global.location.pathname,
      search: `?formType=${
        isRestrationed ? FormType.LoginFirstEmail : FormType.LoginFirstPhone
      }`,
    });
  };

  useEffect(() => {
    if (
      formType === FormType.LoginFirstPhone ||
      formType === FormType.LoginSecondPhone ||
      formType === FormType.LoginFirstEmail ||
      formType === FormType.LoginSecondEmail ||
      formType === FormType.LoginCertificate ||
      formType === FormType.LoginCertificateCheck ||
      formType === FormType.LoginBlockMob ||
      formType === FormType.LoginInn ||
      formType === FormType.LoginType ||
      formType === FormType.LoginClientOrAgent ||
      formType === FormType.LoginCheckEmail ||
      formType === FormType.LoginCheckPhone
    ) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [formType]);

  // useEffect(() => {
  //   if (access_token) {
  //     navigate(`${ROUTES.PARTNER}/applications`);
  //   }
  // }, [access_token]);

  const handleAgreementModal = () => {
    setAgreementOpen(!agreementOpen);
  };

  return formType === FormType.LoginFirstPhone ||
    formType === FormType.LoginSecondPhone ||
    formType === FormType.LoginFirstEmail ||
    formType === FormType.LoginSecondEmail ||
    formType === FormType.LoginCertificate ||
    formType === FormType.LoginCertificateCheck ||
    formType === FormType.LoginBlockMob ||
    formType === FormType.LoginInn ||
    formType === FormType.LoginType ||
    formType === FormType.LoginClientOrAgent ||
    formType === FormType.LoginCheckEmail ||
    formType === FormType.LoginCheckPhone ? (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll={"body"}
      fullScreen={isLagerThan480 ? false : true}
      className={styles.dialog}
      maxWidth={"md"}
      fullWidth={true}
      // maxWidth={false}
      sx={
        {
          // width: ["100%"],
          // margin: "0px"
        }
      }
      PaperProps={{
        style: { borderRadius: isLagerThan480 ? "24px" : 0 },
      }}
    >
      <div className={styles.modal_cont}>
        {!isLagerThan480 && <HeaderHome isModal={true} />}
        <div className={styles.paper}>
          {(formType === FormType.LoginFirstEmail ||
            formType === FormType.LoginFirstPhone) && (
            <div className="link" onClick={handleClose}>
              Вернуться
            </div>
          )}
          <div className={styles.titleBox}>
            {/* <div className={styles.flex_end}>
              {(formType === FormType.LoginType ||
                formType === FormType.LoginCertificateCheck) && (
                <IconButton
                  className={styles.arrowBackBtn}
                  aria-label="close"
                  onClick={handleReturnFromTypes}
                >
                  <ArrowBackIcon />
                </IconButton>
              )}
              <IconButton
                aria-label="close"
                className={styles.closeBtnMobile}
                onClick={handleClose}
              >
                <CloseIcon />
              </IconButton>
            </div> */}

            <div className={styles.header}>
              {(formType === FormType.LoginType ||
                formType === FormType.LoginCertificateCheck ||
                formType === FormType.LoginSecondEmail ||
                formType === FormType.LoginSecondPhone) && (
                <div className="link" onClick={handleReturnFromTypes}>
                  Вернуться
                </div>
              )}
              <div className={`page_title ${styles.main_title}`}>
                {formType === FormType.LoginBlockMob
                  ? "Пожалуйста, войдите с компьютера"
                  : formType === FormType.LoginType
                  ? "Выбрать способ входа"
                  : formType === FormType.LoginClientOrAgent
                  ? "Зарегистрироваться как"
                  : formType === FormType.LoginSecondPhone
                  ? "Подтвердите номер"
                  : formType === FormType.LoginSecondEmail
                  ? "Подтвердите e-mail"
                  : formType === FormType.LoginCheckPhone
                  ? "Проверьте номер телефона"
                  : formType === FormType.LoginCheckEmail
                  ? "Проверьте e-mail"
                  : "Вход в личный кабинет"}
              </div>
            </div>
            {/* <IconButton
              aria-label="close"
              className={styles.closeBtn}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton> */}
          </div>
          {formType === FormType.LoginCertificate && <CertificateModal />}
          {formType === FormType.LoginCertificateCheck && <CheckComponents />}
          {formType === FormType.LoginFirstPhone && (
            <PhoneForm phoneGl={phone} setPhone={setPhone} />
          )}
          {formType === FormType.LoginCheckPhone && (
            <PhoneForm phoneGl={phone} setPhone={setPhone} isCheck={true} />
          )}
          {formType === FormType.LoginFirstEmail && (
            <EmailForm setEmail={setEmail} email={email} />
          )}
          {formType === FormType.LoginCheckEmail && (
            <EmailForm setEmail={setEmail} email={email} isCheck={true} />
          )}
          {formType === FormType.LoginSecondEmail && (
            <EmailFormWithCode email={email} />
          )}
          {formType === FormType.LoginSecondPhone && (
            <PhoneFormWithCode phone={phone} />
          )}
          {/* {formType === FormType.LoginBlockMob && (
          <BlockMobTemp setOpen={setOpen} />
        )} */}
          {formType === FormType.LoginType && <LoginTypesForm />}
          {formType === FormType.LoginClientOrAgent && (
            <LoginClientOrAgentForm phone={phone} />
          )}

          {(formType === FormType.LoginFirstEmail ||
            formType === FormType.LoginFirstPhone ||
            formType === FormType.LoginCheckEmail ||
            formType === FormType.LoginCheckPhone ||
            formType === FormType.LoginCertificate) && (
            <>
              <div className={styles.confirm_desc}>
                Нажимая "Продолжить" я даю {" "}
                <b className={styles.blue_text} onClick={handleAgreementModal}>
                  согласие на обработку персональных данных{" "}
                </b>
                {(formType === FormType.LoginFirstPhone ||
                  formType === FormType.LoginCheckPhone) &&
                  "и подтверждаю что являюсь владельцем указанного номера телефона"}
                {(formType === FormType.LoginFirstEmail ||
                  formType === FormType.LoginCheckEmail) &&
                  "и подтверждаю что являюсь владельцем указанного емэйла"}
              </div>
              <div
                className="link"
                onClick={() => {
                  navigate({
                    pathname: window.location.pathname,
                    search: `formType=${FormType.LoginType}`,
                  });
                }}
                style={{ alignSelf: "center" }}
              >
                Войти другим способом
              </div>
            </>
          )}
        </div>
        <PersonalData
          isOpen={agreementOpen}
          handleClose={() => handleAgreementModal()}
        />
        {/* <AgreementModal
          isOpen={agreementOpen}
          clientType="client"
          handleClose={handleAgreementModal}
        /> */}
        <div className={styles.spacer} />
        {!isLagerThan480 && <FooterHome isModal={true} />}
      </div>
    </Dialog>
  ) : null;
};

export default memo(Login);
