import clsx from "clsx";
import { COLORS } from "../../core/consts/common";
import whiteLogo from "../../images/white-logo.svg";
import styles from "./layoutHome.module.scss";
import { PersonalData } from "../modals/PersonalData";
import React from "react";

const FooterHome = ({ isModal }: { isModal?: boolean }) => {
    const [isOpenPersonalData, setIsOpenPersonalData] = React.useState(false);
    const currentYear = new Date().getFullYear();
    return (
        <div
            className={styles.footerWrapper}
            style={{ backgroundColor: isModal ? COLORS.ACCEPT : "" }}
        >
            <div className={clsx(styles.containerBlock, styles.footer)}>
                <img className={styles.logo} src={whiteLogo} alt="Логотип" />
                <div className={styles.textWrapper}>
                    <div className={styles.left}>
                        <div className={styles.text}>
                            {`Все права защищены. © ${currentYear} PAYRIX не является кредитной организацией и
                            не ведет банковскую деятельность.`}
                        </div>
                        <span
                            onClick={() => setIsOpenPersonalData(true)}
                            className={styles.acceptText}
                            style={{ color: isModal ? "black" : "" }}
                        >
                            Политика обработки персональных данных
                        </span>
                    </div>
                    <div className={clsx(styles.right, styles.text)}>
                        Данный интернет-сайт носит исключительно информационно-справочный характер,
                        и ни при каких условиях не является публичной офертой или предложением
                        делать оферты, определяемых положениями ст. 435, 437 Гражданского кодекса
                        Российской Федерации.
                    </div>
                </div>
            </div>
            <PersonalData
                isOpen={isOpenPersonalData}
                handleClose={() => setIsOpenPersonalData(false)}
            />
        </div>
    );
};

export default FooterHome;
