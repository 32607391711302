import React from "react";
import LeadsList from "./components/leads-list/LeadsList";
import classes from "./PartnerApplicationPage.module.scss";
import { MainTab, MainTabs } from "../../../UIcomponents/TabsMain";
import { useAppSelector } from "../../../core/store";
import * as selectors from "../../../core/store/selectors";
import { actions } from "../../../core/store";
import { useDispatch, useSelector } from "react-redux";
import { getLeadsCount } from "../../../core/store/thunk/partnerAplication";
import { UseHandleScroll } from "./components/UseHandleScroll";

const PartnerApplication: React.FC = () => {
    const dispatch = useDispatch<any>();
    // const scrolls = useTrackingScroll()
    const { access_token } = useAppSelector((state) => state.auth);
    const activeTab = useSelector(selectors.partnerAplication.activeTab);
    const tabs = useSelector(selectors.partnerAplication.tabs);

    const handleSetActiveTab = (event: any, newValue: number) => {
        actions.setActiveTab(newValue);
        switch (newValue) {
            case 0:
                actions.setIsNewOrders(true);
                actions.setIsArchiveOrders(undefined);
                break;
            case 1:
                actions.setIsNewOrders(false);
                actions.setIsArchiveOrders(undefined);
                break;
            case 2:
                actions.setIsNewOrders(false);
                actions.setIsArchiveOrders(true);
                break;
            default:
                actions.setIsNewOrders(true);
                actions.setIsArchiveOrders(undefined);
                break;
        }
    };

    const a11yProps = (index: number) => {
        return {
            id: `tab-${index}`,
            "aria-controls": `tabpanel-${index}`,
        };
    };

    React.useEffect(() => {
        dispatch(getLeadsCount());
    }, [access_token, dispatch]);

    return (
        <div className={classes.container}>
            <div className="page_title">Заявки </div>
            <div>
                <div>
                    <MainTabs
                        value={activeTab}
                        onChange={handleSetActiveTab}
                        className={classes.tabs}
                        variant="scrollable"
                        scrollButtons="auto"
                        // allowScrollButtonsMobile
                    >
                        {tabs?.map((tab, index: number) => (
                            <MainTab
                                className={classes.tab}
                                label={
                                    <div className="tab_main">
                                        {tab.name}
                                        <div
                                            className="grayRound"
                                            style={{
                                                paddingLeft: tab?.count >= 100 ? "8px" : "",
                                                paddingRight: tab?.count >= 100 ? "8px" : "",
                                            }}
                                        >
                                            {tab.count}
                                        </div>
                                    </div>
                                }
                                {...a11yProps(index)}
                                key={index}
                            />
                        ))}
                    </MainTabs>
                </div>
            </div>
            <div className={classes.subTitle}>Личная информация</div>
            <LeadsList index={activeTab} />
            <UseHandleScroll />
        </div>
    );
};

export default React.memo(PartnerApplication);
