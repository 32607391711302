import leadsApi from "../api/leadsApi";
import introspectService from "./IntropsectService";
import {
  leadFullInfo,
  leadsListType,
  leadStatusType,
  LeadDocType,
} from "../types/leadsApiTypes";
import { individualIpotekaType } from "../types/productTypes";

class LeadsService {
  //-----------documents-----------

  deleteLeadDocService(leadId: number, documentId: number): Promise<any> {
    return leadsApi.deleteLeadDoc(leadId, documentId);
  }

  replaceLeadDocService(
    leadId: number,
    documentId: number,
    document: any,
    IsAttachedSignature: boolean
  ): Promise<any> {
    return leadsApi.replaceLeadDoc(
      leadId,
      documentId,
      document,
      IsAttachedSignature
    );
  }

  sendNewOrderService(body: any) {
    return leadsApi
      .sendNewOrder(body)
      .then((res: any) => {
        return res;
      })
      .catch((error) => {
        introspectService.checkToken(error.response.status.toString());
      });
  }

  getLeadDocService(
    document: number,
    Q?: { partner_example: boolean }
  ): Promise<any> {
    return leadsApi.getLeadDoc(document, Q);
  }

  getLeadDocActService(
    document: number,
    Q?: { partner_example: boolean }
  ): Promise<any> {
    return leadsApi.getLeadDocAct(document, Q);
  }

  getLeadDocsService(leadId: number): Promise<LeadDocType[]> {
    return leadsApi.getLeadDocs(leadId);
  }

  getLeadDocsSendStatusService(leadId: number): Promise<{ sendDocsState: string }> {
    return leadsApi.getLeadDocsSendStatus(leadId);
  }

  sendIpotekaApplicationService(
    Q: individualIpotekaType | any,
    alias: string
  ): Promise<any> {
    return leadsApi.sendIpotekaApplication(Q, alias);
  }

  sendDocToBankService(
    leadId: number,
    docType: number,
    document: any
  ): Promise<any> {
    return leadsApi.sendDocToBank(leadId, docType, document);
  }

  attachDocSignatureService(
    leadId: number,
    documentid: number,
    document: any
  ): Promise<any> {
    return leadsApi.attachDocSignature(leadId, documentid, document);
  }

  sendDocsToBankService(leadid: string): Promise<any> {
    return leadsApi.sendDocsToBank(leadid);
  }
  getDocSignService(id: number, sid: number): Promise<any> {
    return leadsApi.getDocSign(id, sid);
  }
  //---------------заявки-----------

  deleteLeadByIdService(leadId: string): Promise<leadFullInfo> {
    return leadsApi
      .deleteLeadById(leadId)
      .then((res: any) => {
        return res;
      })
      .catch((error) => {
        introspectService.checkToken(error.response.status.toString());
      });
  }

  getLeadStatusById(leadId: number): Promise<leadStatusType> {
    return leadsApi
      .getLeadStatusById(leadId)
      .then((res: any) => {
        return res;
      })
  }
  getDeleteInfoByIdService(leadId: string): Promise<any> {
    return leadsApi
      .getDeleteInfoById(leadId)
      .then((res: any) => {
        return res;
      })
  }

  putLeadArchive(leadid: string) {
    return leadsApi
      .putLeadArchive(leadid)
      .then((res) => {
        return res;
      })
      .catch(error => {
        console.log(error);
      });
  }

  getLeadByIdService(leadId: string): Promise<leadFullInfo> {
    return leadsApi
      .getLeadById(leadId)
      .then((res: any) => {
        return res;
      })
  }

  getLeadsListByProductIdService(
    productId: string,
    archive?: boolean
  ): Promise<leadsListType> {
    return leadsApi
      .getLeadsListByProductId(productId, archive)
      .then((res: any) => {
        return res;
      })
      .catch((error) => {
        introspectService.checkToken(error.response.status.toString());
      });
  }

  getLeadsBigFieldsService(
    ordersList?: number[],
    inn?: string,
    draftOrdersArr?: number[]
  ) {
    const params = new URLSearchParams();
    if (draftOrdersArr && draftOrdersArr.length > 0) {
      draftOrdersArr.map((i) => params.append("leadId", `${i}`));
    }
    if (draftOrdersArr && draftOrdersArr.length === 0 && ordersList) {
      ordersList.map((i) => params.append("proposalIds", `${i}`));
    }
    params.append("inn", `${inn}`);
    return leadsApi.getLeadsBigFields(params);
  }

  getStatuses() {
    return leadsApi
      .getLeadStatuses()
      .then((res: any) => {
        return res;
      })
      .catch((error) => {
        introspectService.checkToken(error.response.status.toString());
      });
  }
}

const leadsService = new LeadsService();
export default leadsService;
