import React, { memo } from "react";
import { PartnerLeadType } from "../../../../../core/types/partnerApiTypes";
import classes from "../../PartnerApplicationPage.module.scss";
import { useNavigate } from "react-router-dom";
import { COLORS, ROUTES } from "../../../../../core/consts/common";
import partnerService from "../../../../../core/services/PartnerService";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useMediaQuery } from "@mui/material";

type PropsType = {
  lead: PartnerLeadType;
  searchInn: string;
};

const LeadCard: React.FC<PropsType> = ({ lead, searchInn }: PropsType) => {
  const isLagerThan760 = useMediaQuery("(min-width:1000px)");

  const navigate = useNavigate();
  const strToData = (strDate: string): string => {
    let date = new Date(strDate);
    return date ? date.toLocaleDateString("ru-RU") : "";
  };

  const handleRouteClick = () => {
    navigate(`${ROUTES.PARTNER}/application/0/${lead.id}`);
    partnerService
      .takeOffLeadFlagNewService(lead.id)
      .then((res) => {})
      .catch((error) => {
        console.log(error);
      });
  };

  const FindTextElement = ({ text }: { text: string }) => {
    if (
      !(
        searchInn?.length > 0 &&
        text.toLowerCase().includes(searchInn.toLocaleLowerCase())
      )
    ) {
      return <>{text}</>;
    }
    const indexStart = text
      .toLocaleLowerCase()
      .split(searchInn.toLocaleLowerCase())[0].length;
    const indexEnd = indexStart + searchInn.length;

    return (
      <>
        {text.slice(0, indexStart)}
        <b className="attention_text">{text.slice(indexStart, indexEnd)}</b>
        {text.slice(indexEnd, text.length)}
      </>
    );
  };

  return (
    <div
      className={`table_row ${classes.lead_item}`}
      onClick={handleRouteClick}
    >
      <div className="table_item_20">
        <div>Вид продукта</div>
        <div className="table_text">{lead.product.name}</div>
      </div>

      <div className="table_item_20">
        <div>Сумма</div>
        <div className="table_text">{lead?.sum?.toLocaleString()}  ₽</div>
      </div>

      <div className="table_item_20">
        <div>Клиент</div>
        <div className="table_text">
          <FindTextElement text={lead.companyName} />
        </div>
        <div>
          ИНН <FindTextElement text={lead.inn} />
        </div>
      </div>

      <div className="table_item_20">
        <div>Дата</div>
        <div className="table_text"> {strToData(lead.createDate)}</div>
        {lead.isNew && <div className={classes.new_flag}>Новая</div>}
      </div>

      <div className="table_item_20">
        <div>Статус</div>
        <div className="table_text">{lead.status}</div>

        {lead?.leadNotificationsCount > 0 && (
          <div className={classes.notes}>
            <>
              {lead?.isExpired === true && <div className={classes.redRound} />}
              {lead?.isExpired === false && lead?.isToday === true && (
                <div className={classes.yellowRound} />
              )}
            </>
            Заметки ({lead?.leadNotificationsCount})
          </div>
        )}
      </div>
      {isLagerThan760 && (
        <div style={{ alignSelf: "center" }}>
          <ArrowForwardIosIcon
            style={{ color: COLORS.MAIN_TEXT, width: "15px" }}
          />
        </div>
      )}
    </div>
  );
};

export default memo(LeadCard);
