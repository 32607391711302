import {
  Box,
  Typography,
  // makeStyles,
  // createStyles,
  // Theme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { getSystemInfo } from "crypto-pro";
import classes from "../../Login.module.scss"
import { ApproveSign } from "../../../../core/consts/images/approveSign";
import { WarningSign } from "../../../../core/consts/images/warningSign";

// const useStyles = makeStyles(
//   (theme: Theme) =>
//     createStyles({
//       componentBox: {
//         backgroundColor: "#F2F2F2",
//         borderRadius: "10px",
//         padding: "30px",
//         display: "flex",
//         justifyContent: "space-between",
//         marginTop: "20px",
//         "&:first-child": {
//           marginTop: "30px",
//         },
//       },
//       text: {
//         display: "flex",
//         justifyContent: "flex-end",
//         alignItems: "center",
//         flexWrap: "wrap",
//         maxWidth: "250px",
//       },
//       infoText: {
//         fontSize: "12px",
//         display: "block",
//       },
//       infoTextError: {
//         color: "#617E8C",
//         display: "block",
//         maxWidth: "380px",
//       },
//       infoTextErrorLink: {
//         color: "#306F9A",
//         textDecoration: "none",
//       },
//       boxTitle: {},
//       installLink: {
//         display: "block",
//         fontFamily: "Gotham Pro",
//         fontStyle: "normal",
//         fontSize: "16px",
//         fontWeight: "normal",
//         lineHeight: "1.3",
//         color: "#306F9A",
//         textDecoration: "none",
//       },
//       noCeritificatesText: {
//         maxWidth: "80%",
//         display: "block",
//       },
//     }),
//   { index: 1 }
// );

const detectBrowser = (): string => {
  const userAgentRes = navigator.userAgent;
  let result = "other";
  if (/Edge\/\d+/.test(userAgentRes)) {
    result = "Microsoft Edge";
  } else if (/MSIE 9/.test(userAgentRes)) {
    result = "Explorer 9";
  } else if (/MSIE 10/.test(userAgentRes)) {
    result = "Explorer 10";
  } else if (/MSIE 11/.test(userAgentRes) || /rv\:11/.test(userAgentRes)) {
    result = "Explorer 11";
  } else if (/MSIE\s\d/.test(userAgentRes)) {
    result = "Explorer 8 and below";
  } else if (/Firefox\W\d/.test(userAgentRes)) {
    result = "Firefox";
  } else if (/Chrom(e|ium)\W\d|CriOS\W\d/.test(userAgentRes)) {
    result = "Google Chrome";
  } else if (/\bSafari\W\d/.test(userAgentRes)) {
    result = "Safari";
  } else if (/\bOpera\W\d/.test(userAgentRes)) {
    result = "Opera";
  }
  return result;
};

const detectOS = (): string => {
  const userAgentRes = navigator.userAgent;
  let result = "other";
  if (/Windows NT 10/.test(userAgentRes)) {
    result = "Windows 10";
  } else if (/Windows NT 11/.test(userAgentRes)) {
    result = "Windows 11";
  } else if (/Windows NT 6\.0/.test(userAgentRes)) {
    result = "Windows Vista";
  } else if (/Windows NT 6\.1/.test(userAgentRes)) {
    result = "Windows 7";
  } else if (/Windows NT 6\.\d/.test(userAgentRes)) {
    result = "Windows 8";
  } else if (/Windows NT 5\.1/.test(userAgentRes)) {
    result = "Windows XP";
  } else if (/Windows NT [1-5]\./.test(userAgentRes)) {
    result = "other";
  } else if (/Mac/.test(userAgentRes)) {
    result = "macOS";
  } else if (/Linux/.test(userAgentRes)) {
    result = "linux";
  }
  return result;
};

type systemInfo = {
  cadesVersion: string;
  cspVersion: string;
};

const CheckComponents: React.FC = () => {
  // const classes = useStyles();
  const [systemInfo, setSystemInfo] = useState<systemInfo>();
  const [isOS, setIsOS] = useState<boolean>();
  const [nameOS, setNameOS] = useState<string>();
  const [isBrowser, setIsBrowser] = useState<boolean>();
  const [nameBrowser, setNameBrowser] = useState<string>();

  useEffect(() => {
    const browser = detectBrowser();
    const os = detectOS();
    setNameOS(os);
    setIsOS(os !== "other");
    setNameBrowser(browser);
    setIsBrowser(browser !== "other");

    getSystemInfo()
      .then((res: systemInfo) => {
        setSystemInfo(res);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <>
      <Typography
        variant="body1"
        className={classes.noCeritificatesText}
        component="span"
      >
        Для входа в личный кабинет с помощью сертификата, у вас должны быть
        установлены все необходимые компоненты
      </Typography>
      <Box className={classes.componentBox}>
        <Typography component="h4" variant="h4" className={classes.boxTitle}>
          Операционная система
          <Typography
            component="span"
            variant="body2"
            className={classes.infoText}
          >
            {nameOS}
          </Typography>
        </Typography>
        {isOS && nameOS !== "other" ? (
          <Typography component="p" variant="body2" className={classes.text}>
            <ApproveSign />
            &nbsp;&nbsp;Компонент найден
          </Typography>
        ) : (
          <Typography component="p" variant="body2" className={classes.text}>
            <WarningSign />
            &nbsp;&nbsp;Компонент отсутствует
          </Typography>
        )}
      </Box>
      <Box className={classes.componentBox}>
        <Typography component="h4" variant="h4">
          Браузер
          <Typography
            component="span"
            variant="body2"
            className={classes.infoText}
          >
            {nameBrowser}
          </Typography>
        </Typography>
        {isBrowser && nameBrowser !== "other" ? (
          <Typography component="p" variant="body2" className={classes.text}>
            <ApproveSign />
            &nbsp;&nbsp;Компонент найден
          </Typography>
        ) : (
          <Typography component="p" variant="body2" className={classes.text}>
            <WarningSign />
            &nbsp;&nbsp;Компонент отсутствует
          </Typography>
        )}
      </Box>
      <Box className={classes.componentBox}>
        <Typography component="h4" variant="h4">
          Криптографический плагин
          {systemInfo?.cadesVersion ? (
            <Typography
              component="span"
              variant="body2"
              className={classes.infoText}
            >
              Версия плагина: {systemInfo?.cadesVersion}
            </Typography>
          ) : (
            <Typography
              component="span"
              variant="body2"
              className={classes.infoTextError}
            >
              Не установлены плагин
              <a
                href="https://www.cryptopro.ru/products/cades/plugin"
                className={classes.infoTextErrorLink}
                target="_blank"
                rel="noopener"
              >
                {" "}
                КриптоПро ЭЦП Browser plug-in{" "}
              </a>
              и расширение для браузера{" "}
              <a
                href="https://cryptopro.ru/products/csp"
                className={classes.infoTextErrorLink}
                target="_blank"
                rel="noopener"
              >
                {" "}
                CryptoPro Extension for CAdES Browser Plug-in
              </a>
            </Typography>
          )}
        </Typography>
        {systemInfo?.cadesVersion ? (
          <Typography component="p" variant="body2" className={classes.text}>
            <ApproveSign />
            &nbsp;&nbsp;Компонент найден
          </Typography>
        ) : (
          <Typography component="p" variant="body2" className={classes.text}>
            <WarningSign />
            &nbsp;&nbsp;Компонент отсутствует
            <a
              href="https://www.cryptopro.ru/products/cades/plugin"
              className={classes.installLink}
              target="_blank"
              rel="noopener"
            >
              Перейти к установке
            </a>
            <a
              href="https://cryptopro.ru/products/csp"
              className={classes.installLink}
              target="_blank"
              rel="noopener"
            >
              Перейти к установке
            </a>
          </Typography>
        )}
      </Box>
      <Box className={classes.componentBox}>
        <Typography component="h4" variant="h4">
          Криптографическое ПО
          {systemInfo?.cspVersion ? (
            <Typography
              component="span"
              variant="body2"
              className={classes.infoText}
            >
              Версия криптопровайдера: {systemInfo?.cspVersion}
            </Typography>
          ) : (
            <Typography
              component="span"
              variant="body2"
              className={classes.infoTextError}
            >
              Не установлен{" "}
              <a
                href="https://cryptopro.ru/products/csp"
                className={classes.infoTextErrorLink}
                target="_blank"
                rel="noopener"
              >
                {" "}
                криптопровайдер{" "}
              </a>{" "}
              с поддержкой алгоритмов шифрования ГОСТ 34, 10-2012, ГОСТ 28147-89
            </Typography>
          )}
        </Typography>
        {systemInfo?.cspVersion ? (
          <Typography component="p" variant="body2" className={classes.text}>
            <ApproveSign />
            &nbsp;&nbsp;Компонент найден
          </Typography>
        ) : (
          <Typography component="p" variant="body2" className={classes.text}>
            <WarningSign />
            &nbsp;&nbsp;Компонент отсутствует
            <a
              href="https://cryptopro.ru/products/csp"
              className={classes.installLink}
              target="_blank"
              rel="noopener"
            >
              Перейти к установке
            </a>
          </Typography>
        )}
      </Box>
    </>
  );
};
export default CheckComponents;
