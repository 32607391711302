export const ROUTES = {
  MAIN: "/",
  ABOUT: "/about/",
  PRODUCTS: "/products/",
  PRODUCT: "/product/",
  BANK_GAURANTEE: "/bankovskiye-garantii/",
  OTKRITIE_RASCHETNOGO_SCHETA: "/otkritie-raschetnogo-scheta/",
  FACTORING: "/factoring/",
  USER_AGREEMENT: "/user-agreement/",
  PERSONAL_DATA_PROCESSING_AGREEMENT: "/personal-data-processing-agreement/",
  RKO: "/rko/",
  NEWS: "/news/",
  AGENTS: "/agents/",
  MANAGER: "/manager",
  LEASING: "/lizing/",
  CONTACTS: "/contacts/",
  CLIENT: "/client",
  PARTNER: "/partner",
  CLIENT_KNOWLEDGE_BASE: "/knowledge-base",
  AGENT: "/agent",
  CREDIT: "/credits/",
  PRODUCT_TYPE: "/producttype",
  PRIVATE_CLIENTS: "/privateclients/",
  CONSUMER_CREDIT: "/potrebcredits/",
  MORTGAGE: "/ipoteka/",
  CREDIT_CARD: "/creditcard/",
  ADMIN: "/admin",
  ARTICLES: "/articles/",
};

export const TOKEN_NAME = "c2mFinanceTokenService";

export const ROLES = {
  CLIENT: "Клиент",
  AGENT: "Агент",
  PARTNER: "Партнер",
  MANAGER: "Менеджер",
  ADMIN: "Администратор",
};

export enum FormType {
  LoginFirstPhone = "login-first-phone",
  LoginFirstEmail = "login-first-email",
  LoginSecondPhone = "login-second-phone",
  LoginSecondEmail = "login-second-email",
  LoginCertificate = "login-certificate",
  LoginCertificateCheck = "login-certificate-check",
  LoginBlockMob = "login-mob-block",
  Registration = "registration",
  RegistrationDetail = "registration-detail",
  LoginInn = "login-inn",
  LoginType = "login-type",
  LoginClientOrAgent = "login-client-or-agent",
  LoginCheckPhone = "login-check-phone",
  LoginCheckEmail = "login-check-email",
}

export enum productsId {
  credits = 1000,
  factoring = 1001,
  rko = 1002,
  bankovskiye_garantii = 1003,
  lizing = 1004,
  otkritie_raschetnogo_scheta = 1005,
  potrebcredits = 1006,
  creditcard = 1007,
  ipoteka = 1008,
}

export const CHAT_ROLES = {
  client:
    "https://secure.gravatar.com/avatar/593f878d696bba98357e1e91abc005ba?s=800&d=identicon",
  agent:
    "https://secure.gravatar.com/avatar/91c73603ef979e4fd670eb3758869318?s=800&d=identicon",
  PARTNER: "Partner",
  MANAGER: "Manager",
};

export enum ORGANIZATION_TYPE {
  bank = "Банк",
  factoringCompany = "Факторинговая компания",
  lizingCompany = "Лизинговая компания",
}
export enum ORGANIZATION_TYPE_OF_ENUM {
  "Банк" = "банка",
  "Факторинговая компания" = "факторинговой компании",
  "Лизинговая компания" = "лизинговой компании",
}

export enum UTM_EVENT_ENUM {
  FollowingLink = "FollowingLink",
  EnteredPhoneNumber = "EnteredPhoneNumber",
  ChoseRole = "ChoseRole",
  CompletedRegistration = "CompletedRegistration",
}

export enum ClientType {
  Client = "client",
  Agent = "agent",
}

export const RegisterFormCommon = {
  lastName: "*Фамилия",
  firstName: "*Имя",
  patrName: "Отчество (если есть)",
  nameLabel: "*Ваше ФИО",
  phoneLabel: "*Номер телефона",
  phone: "+7 (800) 000-00-00",
  email: "*Адрес электронной почты",
  inn: "ИНН",
  innCompany: "*ИНН компании",
  innOrganisation: "*ИНН организации",
  password: "Пароль",
  code: "Код",
  codeLabel: "Введите код",
  bik: "*БИК",
  nalogs: "*Система налогооблажения",
  fact_adress: "*Фактический адрес",
  mail: "*Почтовый адрес",
  payment: "*р/c",
};

export const regFio = /^ *[А-Я][а-яА-Я-]{1,} *$/gm;

export enum COLORS {
  MAIN_TEXT = "#242424",
  SUB_TEXT = "#636363",
  BORDER = "#CCCCCC",
  DIVIDER = "#ECECEC",
  BACKGROUND = "#FFFFFF",
  BRAND = "#99D600",
  ATTENTION = "#F4D35E",
  ERROR = "#E24E1B",
  ACCEPT = "#6AD5CB",
}

export enum SortEnam {
  AgeAsc = "AgeAsc",
  AgeDesc = "AgeDesc",
  Status = "Status",
  CompanyNameAsc = "CompanyNameAsc",
  CompanyNameDesc = "CompanyNameDesc",
  SumASc = "SumAsc",
  SumDesc = "SumDesc",
}
export const Sort = {
  [SortEnam.AgeAsc]: "Сначала старые",
  [SortEnam.AgeDesc]: "Сначала новые",
  [SortEnam.Status]: "По статусу",
  [SortEnam.CompanyNameAsc]: "По имени компании А - Я",
  [SortEnam.CompanyNameDesc]: "По имени компании Я - А",
  [SortEnam.SumASc]: "По меньшей сумме",
  [SortEnam.SumDesc]: "По большей сумме",
};

export const CKeyV2 = "6Ld3c04nAAAAALyMtrPZ_mT4wtLBf2NHNummb5Hh"; //recapcha
export const keyYandexCapcha = "ysc1_vDund9JKYF8ID5CgoNzm8Qo88hBqx9hUA7WULmNNf4393409"; //ya capcha
