import { Outlet } from "react-router-dom";
import "../../App.scss";
import styles from "./layoutHome.module.scss";
import Login from "../login/Login";
import FooterHome from "./FooterHome";
// import HeaderHome from "./HeaderHome";

const LayoutHome = () => {
  return (
    <div className={styles.layout_main}>
      <Login />
      {/* <HeaderHome /> */}
      <Outlet />
      <div className="spacer" />
      <FooterHome />
    </div>
  );
};

export default LayoutHome;
