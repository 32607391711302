import React from "react";
import styles from "./PersonalData.module.scss";
import { WhiteModal } from "../WhiteModal";
import { Typography } from "@mui/material";

export type Props = {
    title?: string;
    isOpen: boolean;
    className?: string;
    handleClose?: () => void;
};

export function PersonalData(props: Props) {
    return (
        <WhiteModal
            isOpen={props.isOpen}
            className={props.className}
            handleClose={props.handleClose}
            title={props.title}
        >
            <div className={styles.base}>
                <Typography variant="h3" component="h3">
                    Политика в отношении обработки персональных данных
                </Typography>
                <div className={styles.content}>
                    <Typography className={styles.text} variant="body1" component="p">
                        Политика обработки персональных данных Общества с ограниченной
                        ответственностью «ЛИНЕЙНЫЕ КОММУНИКАЦИИ» (далее – Политика) разработана на
                        основании Федерального закона от 27.07.2006 No 152-ФЗ «О персональных
                        данных» (далее – Федеральный закон No 152-ФЗ) в целях обеспечения защиты
                        прав и свобод субъектов персональных данных. Политика определяет порядок
                        обработки персональных данных и меры по обеспечению безопасности
                        персональных данных, принимаемые Обществом с ограниченной ответственностью
                        «ЛИНЕЙНЫЕ КОММУНИКАЦИИ» (далее – Оператор).
                    </Typography>
                    <Typography className={styles.subTitle} variant="subtitle2" component="h4">
                        1. Общие положения
                    </Typography>
                    <Typography className={styles.text} variant="body1" component="p">
                        1.1. Оператор ставит своей целью и условием осуществления своей деятельности
                        соблюдение прав и свобод человека и гражданина при обработке его
                        персональных данных, в том числе защиты прав на неприкосновенность частной
                        жизни, личную и семейную тайну.
                    </Typography>
                    <Typography className={styles.text} variant="body1" component="p">
                        1.2. Политика действует в отношении всех процессов Оператора в области
                        обработки персональных данных, возникшие у Оператора как до, так и после
                        утверждения настоящей Политики.
                    </Typography>
                    <Typography className={styles.text} variant="body1" component="p">
                        1.3. Политика применяется ко всей информации, которую Оператор может
                        получить о посетителях веб-сайта https://payrix.ru (далее – Сайт).
                    </Typography>
                    <Typography className={styles.text} variant="body1" component="p">
                        1.4. Внутренние документы, регламентирующие обработку персональных данных в
                        Обществе с ограниченной ответственностью «ЛИНЕЙНЫЕ КОММУНИКАЦИИ»,
                        разрабатываются с учетом положений настоящей Политики.
                    </Typography>
                    <Typography className={styles.text} variant="body1" component="p">
                        1.5. Политика обязательна для ознакомления и исполнения всеми лицами,
                        допущенными к обработке персональных данных в Обществе с ограниченной
                        ответственностью «ЛИНЕЙНЫЕ КОММУНИКАЦИИ».
                    </Typography>
                    <Typography className={styles.subTitle} variant="subtitle2" component="h4">
                        2. Основные понятия, используемые в Политике
                    </Typography>
                    <Typography className={styles.text} variant="body1" component="p">
                        Автоматизированная обработка персональных данных – обработка персональных
                        данных с помощью средств вычислительной техники;
                    </Typography>
                    <Typography className={styles.text} variant="body1" component="p">
                        Блокирование персональных данных – временное прекращение обработки
                        персональных данных (за исключением случаев, если обработка необходима для
                        уточнения персональных данных);
                    </Typography>
                    <Typography className={styles.text} variant="body1" component="p">
                        Веб-сайт – совокупность графических и информационных материалов, а также
                        программ для ЭВМ и баз данных, обеспечивающих их доступность в сети интернет
                        по сетевому адресу https://payrix.ru;
                    </Typography>
                    <Typography className={styles.text} variant="body1" component="p">
                        Информационная система персональных данных – совокупность содержащихся в
                        базах данных персональных данных, и обеспечивающих их обработку
                        информационных технологий и технических средств;
                    </Typography>
                    <Typography className={styles.text} variant="body1" component="p">
                        Обезличивание персональных данных – действия, в результате которых
                        невозможно определить без использования дополнительной информации
                        принадлежность персональных данных конкретному Пользователю или иному
                        субъекту персональных данных;
                    </Typography>
                    <Typography className={styles.text} variant="body1" component="p">
                        Обработка персональных данных – любое действие (операция) или совокупность
                        действий (операций), совершаемых с использованием средств автоматизации или
                        без использования таких средств с персональными данными, включая сбор,
                        запись, систематизацию, накопление, хранение, уточнение (обновление,
                        изменение), извлечение, использование, передачу (распространение,
                        предоставление, доступ), обезличивание, блокирование, удаление, уничтожение
                        персональных данных;
                    </Typography>
                    <Typography className={styles.text} variant="body1" component="p">
                        Оператор – государственный орган, муниципальный орган, юридическое или
                        физическое лицо, самостоятельно или совместно с другими лицами организующие
                        и (или) осуществляющие обработку персональных данных, а также определяющие
                        цели обработки персональных данных, состав персональных данных, подлежащих
                        обработке, действия (операции), совершаемые с персональными данными;
                    </Typography>
                    <Typography className={styles.text} variant="body1" component="p">
                        Персональные данные – любая информация, относящаяся прямо или косвенно к
                        определенному или определяемому физическому лицу/Пользователю Сайта;
                    </Typography>
                    <Typography className={styles.text} variant="body1" component="p">
                        Пользователь – любой посетитель Сайта;
                    </Typography>
                    <Typography className={styles.text} variant="body1" component="p">
                        Предоставление персональных данных – действия, направленные на раскрытие
                        персональных данных определенному лицу или определенному кругу лиц;
                    </Typography>
                    <Typography className={styles.text} variant="body1" component="p">
                        Распространение персональных данных – любые действия, направленные на
                        раскрытие персональных данных неопределенному кругу лиц (передача
                        персональных данных) или на ознакомление с персональными данными
                        неограниченного круга лиц, в том числе обнародование персональных данных в
                        средствах массовой информации, размещение в
                        информационно-телекоммуникационных сетях или предоставление доступа к
                        персональным данным каким-либо иным способом;
                    </Typography>
                    <Typography className={styles.text} variant="body1" component="p">
                        Трансграничная передача персональных данных – передача персональных данных
                        на территорию иностранного государства органу власти иностранного
                        государства, иностранному физическому или иностранному юридическому лицу;
                    </Typography>
                    <Typography className={styles.text} variant="body1" component="p">
                        Уничтожение персональных данных – любые действия, в результате которых
                        персональные данные уничтожаются безвозвратно с невозможностью дальнейшего
                        восстановления содержания персональных данных в информационной системе
                        персональных данных и (или) уничтожаются материальные носители персональных
                        данных.
                    </Typography>
                    <Typography className={styles.subTitle} variant="subtitle2" component="h4">
                        3. Цели обработки персональных данных
                    </Typography>
                    <Typography className={styles.text} variant="body1" component="p">
                        3.1. Обработка персональных данных Оператором должна ограничиваться
                        достижением конкретных, заранее определенных и законных целей. Не
                        допускается обработка персональных данных, несовместимая с целями сбора
                        персональных данных.
                    </Typography>
                    <Typography className={styles.text} variant="body1" component="p">
                        3.2. Оператор осуществляет обработку персональных данных в следующих целях:
                        - осуществление деятельности, предусмотренной Уставом/Положением Общества с
                        ограниченной ответственностью «ЛИНЕЙНЫЕ КОММУНИКАЦИИ»; <br />
                        - заключение, исполнение и прекращение гражданско-правовых договоров,
                        договоров финансирования с физическими, юридическим лицами, индивидуальными
                        предпринимателями и иными лицами, в случаях, предусмотренных действующим
                        законодательством Российской Федерации и Уставом/Положением Общества с
                        ограниченной ответственностью «ЛИНЕЙНЫЕ КОММУНИКАЦИИ»; <br />
                        - исполнение требований законодательства Российской Федерации;
                        <br /> - предоставление доступа Пользователю к сервисам, информации и/или
                        материалам, содержащимся на Сайте;
                    </Typography>
                    <Typography className={styles.text} variant="body1" component="p">
                        3.3. Также Оператор имеет право направлять Пользователю уведомления о новых
                        продуктах и услугах, специальных предложениях и различных событиях.
                        Пользователь всегда может отказаться от получения информационных сообщений,
                        направив Оператору письмо на адрес электронной почты sales@payrix.ru с
                        пометкой «Отказ от уведомлений о новых продуктах и услугах и специальных
                        предложениях».
                    </Typography>
                    <Typography className={styles.text} variant="body1" component="p">
                        3.4. Обезличенные данные Пользователей, собираемые с помощью сервисов
                        интернет-статистики, служат для сбора информации о действиях Пользователей
                        на сайте, улучшения качества сайта и его содержания.
                    </Typography>
                    <Typography className={styles.subTitle} variant="subtitle2" component="h4">
                        4. Правовые основания обработки персональных данных
                    </Typography>
                    <Typography className={styles.text} variant="body1" component="p">
                        4.1. Правовыми основаниями обработки персональных данных являются
                        совокупность нормативных правовых актов, во исполнение которых и в
                        соответствии с которыми Оператор осуществляет обработку персональных данных,
                        а именно:
                        <br /> - Гражданский кодекс Российской Федерации;
                        <br /> - Налоговый кодекс Российской Федерации;
                        <br /> - Федеральный закон от 27.07.2006 No 149-ФЗ «Об информации,
                        информационных технологиях и о защите информации» (далее – Федеральный закон
                        No 149-ФЗ);
                        <br /> - Федеральный закон от 06.04.2011 No 63-ФЗ «Об электронной подписи»;
                        <br /> - Федеральный закон от 26.12.1995 No 208-ФЗ «Об акционерных
                        обществах»;
                        <br /> - постановление Правительства Российской Федерации от 01.11.2012 No
                        1119 «Об утверждении требований к защите персональных данных при их
                        обработке в информационных системах персональных данных»;
                        <br /> - Согласие субъекта на обработку персональных данных; <br /> -
                        Устав/Положение Общества с ограниченной ответственностью «ЛИНЕЙНЫЕ
                        КОММУНИКАЦИИ».
                    </Typography>
                    <Typography className={styles.text} variant="body1" component="p">
                        4.2. Оператор обрабатывает персональные данные Пользователя только в случае
                        их заполнения и/или отправки Пользователем самостоятельно через специальные
                        формы, расположенные на Сайте. Заполняя соответствующие формы и/или
                        отправляя свои персональные данные Оператору, Пользователь выражает свое
                        согласие с данной Политикой.
                    </Typography>
                    <Typography className={styles.text} variant="body1" component="p">
                        4.3. Оператор обрабатывает обезличенные данные о Пользователе в случае, если
                        это разрешено в настройках браузера Пользователя (включено сохранение файлов
                        «cookie» и использование технологии JavaScript).
                    </Typography>
                    <Typography className={styles.subTitle} variant="subtitle2" component="h4">
                        5. Категории персональных данных, их содержание и объем, и категории
                        субъектов персональных данных
                    </Typography>
                    <Typography className={styles.text} variant="body1" component="p">
                        5.1. Содержание и объем обрабатываемых Оператором персональных данных не
                        должны соответствовать заявленным целям обработки. Обрабатываемые
                        персональные данные не должны быть избыточными по отношению к заявленным
                        целям их обработки.
                    </Typography>
                    <Typography className={styles.text} variant="body1" component="p">
                        5.2. Оператор может обрабатывать следующие категории персональных данных:
                        <br /> - фамилия, имя, отчество;
                        <br /> - пол; <br /> - дата рождения; <br /> - месяц рождения; <br /> - год
                        рождения; <br /> - место рождения; <br /> - водительское удостоверение;{" "}
                        <br /> - данные документа, удостоверяющего личность (паспорта гражданина
                        Российской Федерации/ иного документа, удостоверяющего личность); <br /> -
                        номера контактных телефонов, факсов; <br /> - адреса электронной почты;{" "}
                        <br /> - фотографическое изображение; <br /> - финансовая информация,
                        включая сведения об операциях и транзакциях; <br /> - электронная подпись;{" "}
                        <br /> - рукописная подпись; <br /> - текущее время и удобное время для
                        звонка пользователю Сайта; <br /> - IP-адрес.
                    </Typography>
                    <Typography className={styles.text} variant="body1" component="p">
                        5.3. Также на Сайте происходит сбор и обработка обезличенных персональных
                        данных о посетителях (в т.ч. файлов «cookie») с помощью сервисов
                        интернет-статистики (Яндекс Метрика, Гугл Аналитика и др.).
                    </Typography>
                    <Typography className={styles.text} variant="body1" component="p">
                        5.4. Вышеперечисленные данные далее по тексту Политики объединены общим
                        понятием Персональные данные.
                    </Typography>
                    <Typography className={styles.text} variant="body1" component="p">
                        5.5. Обработка специальных категорий персональных данных, касающихся
                        расовой, национальной принадлежности, политических взглядов, религиозных или
                        философских убеждений, состояния здоровья, интимной жизни, Оператором не
                        допускается, за исключением случаев, предусмотренных законодательством
                        Российской Федерации.
                    </Typography>
                    <Typography className={styles.text} variant="body1" component="p">
                        5.6. В зависимости от целей, предусмотренных в разделе 4 Политики Оператор
                        может обрабатывать персональные данные следующих категорий субъектов
                        персональных данных:
                        <br />
                        - потенциальные клиенты;
                        <br />
                        - контрагенты;
                        <br />
                        - работники контрагентов; <br />
                        - клиенты;
                        <br />
                        - потенциальные клиенты;
                        <br />
                        - посетители;
                        <br />- посетители Сайта.
                    </Typography>
                    <Typography className={styles.subTitle} variant="subtitle2" component="h4">
                        6. Условия, порядок и общие принципы обработки персональных данных
                    </Typography>
                    <Typography className={styles.text} variant="body1" component="p">
                        6.1. Оператор обеспечивает конфиденциальность персональных данных субъекта
                        персональных данных, а также обеспечивает использование персональных данных
                        исключительно в целях, соответствующих Федеральному закону No 152-ФЗ,
                        договору или иному соглашению, заключенному с субъектом персональных данных.
                        Оператор обязан не раскрывать третьим лицам и не распространять персональные
                        данные без согласия субъекта персональных данных, если иное не предусмотрено
                        законодательством Российской Федерации.
                    </Typography>
                    <Typography className={styles.text} variant="body1" component="p">
                        6.2. Безопасность персональных данных, которые обрабатываются Оператором,
                        обеспечивается путем реализации правовых, организационных и технических мер,
                        необходимых для выполнения в полном объеме требований действующего
                        законодательства Российской Федерации в области защиты персональных данных.
                    </Typography>
                    <Typography className={styles.text} variant="body1" component="p">
                        6.3. Оператор обеспечивает сохранность персональных данных и принимает все
                        возможные меры, исключающие доступ к персональным данным неуполномоченных
                        лиц.
                    </Typography>
                    <Typography className={styles.text} variant="body1" component="p">
                        6.4. Оператор вправе поручить обработку персональных данных другому лицу с
                        согласия субъекта персональных данных, если иное не предусмотрено
                        Федеральным законом No 152-ФЗ, на основании заключаемого с этим лицом
                        договора. Лицо, осуществляющее обработку персональных данных по поручению
                        Оператора, обязано соблюдать принципы и правила обработки персональных
                        данных, предусмотренные Федеральным законом No 152-ФЗ. В поручении Оператора
                        должны быть определены перечень действий (операций) с персональными данными,
                        которые будут совершаться лицом, осуществляющим обработку персональных
                        данных, цели обработки, должна быть установлена обязанность такого лица
                        соблюдать конфиденциальность персональных данных и обеспечивать безопасность
                        персональных данных при их обработке, а также должны быть указаны требования
                        к защите обрабатываемых персональных данных. Оператор несет ответственность
                        перед субъектом персональных данных за действия лиц, которым Оператор
                        поручает обработку персональных данных.
                    </Typography>
                    <Typography className={styles.text} variant="body1" component="p">
                        6.5. В случае выявления неточных персональных данных, субъект персональных
                        данных может актуализировать их самостоятельно, путем направления Оператору
                        уведомления на адрес электронной почты Оператора sales@payrix.ru с
                        тематической пометкой «Актуализация персональных данных». При обращении
                        субъекта персональных данных или его представителя либо по их запросу или по
                        запросу уполномоченного органа по защите прав субъектов персональных данных
                        Оператор, обязан осуществить блокирование персональных данных, относящихся к
                        этому субъекту персональных данных с момента такого обращения или получения
                        указанного запроса на период проверки, если блокирование персональных данных
                        не нарушает права и законные интересы субъекта персональных данных или
                        третьих лиц.
                    </Typography>
                    <Typography className={styles.text} variant="body1" component="p">
                        6.6. При сборе персональных данных, в том числе посредством информационно-
                        телекоммуникационной сети «Интернет», Оператор обеспечивает запись,
                        систематизацию, накопление, хранение, уточнение (обновление, изменение),
                        извлечение персональных данных граждан Российской Федерации с использованием
                        баз данных, находящихся на территории Российской Федерации, за исключением
                        случаев, предусмотренных законодательством Российской Федерации.
                    </Typography>
                    <Typography className={styles.text} variant="body1" component="p">
                        6.7. Оператор не допускает объединения созданных для несовместимых между
                        собой целей баз данных, содержащих персональные данные.
                    </Typography>
                    <Typography className={styles.text} variant="body1" component="p">
                        6.8. Хранение персональных данных в форме, позволяющей определить субъекта
                        персональных данных осуществляется Оператором не дольше, чем этого требуют
                        цели их обработки, если иной срок не установлен законодательством Российской
                        Федерации, либо договором, стороной которого, выгодоприобретателем или
                        поручителем по которому является субъект персональных данных.
                    </Typography>
                    <Typography className={styles.text} variant="body1" component="p">
                        6.9. Сроки обработки персональных данных определяются в соответствии со
                        сроками, установленными Федеральным законом No 152-ФЗ, сроком действия
                        договора, стороной которого, выгодоприобретателем или поручителем по
                        которому является субъект персональных данных, сроками действия документов,
                        регулирующих хранение архивных документов, образующихся в процессе
                        деятельности, сроком исковой давности, сроком согласия, данного субъектом
                        персональных данных на их обработку, а также иными требованиями
                        законодательства Российской Федерации.
                    </Typography>
                    <Typography className={styles.text} variant="body1" component="p">
                        6.10. Субъект персональных данных может в любой момент отозвать свое
                        согласие на обработку персональных данных, направив Оператору уведомление
                        посредством электронной почты на электронный адрес Оператора sales@payrix.ru
                        с тематической пометкой «Отзыв согласия на обработку персональных данных».
                    </Typography>
                    <Typography className={styles.text} variant="body1" component="p">
                        6.11. В случае отзыва субъектом персональных данных согласия на обработку
                        его персональных данных Оператор обязан прекратить их обработку (обеспечить
                        прекращение такой обработки, если обработка персональных данных
                        осуществляется другим лицом по поручению Банка) и уничтожить персональные
                        данные (обеспечить их уничтожение, если обработка персональных данных
                        осуществляется другим лицом по поручению Банка) в сроки, установленные
                        Федеральным законом No 152-ФЗ. Банк вправе не уничтожать персональные данные
                        и продолжить их обработку без согласия субъекта персональных данных при
                        наличии оснований, указанных в Федеральном законе No 152-ФЗ.
                    </Typography>
                    <Typography className={styles.text} variant="body1" component="p">
                        6.12. В случае изменения сведений, указанных в Уведомлении, а также в случае
                        прекращения обработки персональных данных Оператор обязан уведомить об этом
                        уполномоченный орган по защите прав субъектов персональных данных в течение
                        десяти рабочих дней с даты возникновения таких изменений или с даты
                        прекращения обработки персональных данных.
                    </Typography>
                    <Typography className={styles.subTitle} variant="subtitle2" component="h4">
                        7. Трансграничная передача персональных данных
                    </Typography>
                    <Typography className={styles.text} variant="body1" component="p">
                        7.1. Оператор до начала осуществления трансграничной передачи персональных
                        данных обязан убедиться в том, что иностранным государством, на территорию
                        которого предполагается осуществлять передачу персональных данных,
                        обеспечивается надежная защита прав субъектов персональных данных.
                    </Typography>
                    <Typography className={styles.text} variant="body1" component="p">
                        7.2. Трансграничная передача персональных данных на территории иностранных
                        государств, не отвечающих вышеуказанным требованиям, может осуществляться
                        только в случае наличия согласия в письменной форме субъекта персональных
                        данных на трансграничную передачу его персональных данных и/или исполнения
                        договора, стороной которого является субъект персональных данных.
                    </Typography>
                    <Typography className={styles.subTitle} variant="subtitle2" component="h4">
                        8. Ответственность
                    </Typography>
                    <Typography className={styles.text} variant="body1" component="p">
                        8.1. Оператор назначает ответственное лицо за организацию обработки
                        персональных данных. Лицо, ответственное за организацию обработки
                        персональных данных осуществляет внутренний контроль за соблюдением
                        законодательства Российской Федерации о персональных данных, доводит до
                        сведения работников Оператора положения законодательства Российской
                        Федерации о персональных данных и внутренних документов Оператора по
                        вопросам обработки персональных данных, осуществляет контроль за приемом и
                        обработкой обращений субъектов персональных данных или их представителей.
                    </Typography>
                    <Typography className={styles.text} variant="body1" component="p">
                        8.2. Ответственность за нарушение требований законодательства Российской
                        Федерации и внутренних документов Банка в сфере обработки и защиты
                        персональных данных определяется в соответствии с законодательством
                        Российской Федерации.
                    </Typography>
                    <Typography className={styles.text} variant="body1" component="p">
                        8.3. Ответственность работников Оператора, имеющих доступ к персональным
                        данным, за невыполнение требований и норм, регулирующих обработку и защиту
                        персональных данных, включая разглашение или утрату персональных данных,
                        определяется в соответствии с законодательством Российской Федерации и
                        внутренними документами Оператора. Лица, виновные в нарушении норм,
                        регулирующих обработку и защиту персональных данных, несут дисциплинарную,
                        административную, гражданско- правовую или уголовную ответственность в
                        соответствии с действующим законодательством Российской Федерации.
                    </Typography>
                    <Typography className={styles.subTitle} variant="subtitle2" component="h4">
                        9. Заключительные положения
                    </Typography>
                    <Typography className={styles.text} variant="body1" component="p">
                        9.1. Субъект персональных данных может получить любые разъяснения по
                        интересующим вопросам, касающимся обработки его персональных данных,
                        обратившись к Оператору с помощью электронной почты sales@payrix.ru.
                    </Typography>
                    <Typography className={styles.text} variant="body1" component="p">
                        9.2. В данном документе будут отражены любые изменения политики обработки
                        персональных данных Оператора. Политика действует бессрочно до замены ее
                        новой версией.
                    </Typography>
                    <Typography className={styles.text} variant="body1" component="p">
                        9.3. Актуальная версия Политики в свободном доступе размещена на Сайте по
                        адресу https://payrix.ru/documents.
                    </Typography>
                </div>
            </div>
        </WhiteModal>
    );
}
