import React from "react";
import styles from "./CreateOrderModal.module.scss";
import { Button } from "../../../components/HomeMain/Button";
import { WhiteModal } from "../WhiteModal";
import { MenuItem, Typography } from "@mui/material";
import { ArrowRight } from "../../../icons/ArrowRight";
import { TextFieldMain, TextFieldMainForPhone } from "../../../UIcomponents/TextFieldMain";
import clsx from "clsx";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { MARKETPLACES } from "./const";
import { PersonalData } from "../PersonalData";
import { UserAgreement } from "../UserAgreement";
import { PatternFormat } from "react-number-format";
import { http } from "../../../core/infrastructure/Https";

const INITIAL_FORM = {
    Name: "",
    Surname: "",
    Patronymic: "",
    Phone: "",
    Email: "",
    Inn: "",
    Sum: "",
    Marketplace: "",
};

export type Props = {
    title?: string;
    isOpen: boolean;
    className?: string;
    handleClose?: () => void;
    confirmFn?: () => void;
};

export function CreateOrderModal(props: Props) {
    const [isOpenPersonalData, setIsOpenPersonalData] = React.useState(false);
    const [isOpenUserAgreement, setIsOpenUserAgreement] = React.useState(false);
    const [isError, setIsError] = React.useState(false);
    const [form, setForm] = React.useState(INITIAL_FORM);
    const isValidate = form.Name && form.Surname && form.Phone && form.Inn && form.Marketplace;

    function setFormValue(fieldName: string, value: string) {
        setForm((prevState) => ({
            ...prevState,
            [fieldName]: value,
        }));
    }

    const handleChangePhone = (event: React.ChangeEvent<HTMLInputElement>) => {
        let localPhoneNumber = event.target.value;
        const phoneNumberLength = localPhoneNumber.replaceAll(" ", "").length;
        if (phoneNumberLength === 16 && localPhoneNumber[1] !== "7") {
            localPhoneNumber = "+7" + localPhoneNumber.substring(2, localPhoneNumber.length);
        } else if (localPhoneNumber[1] !== "7" && localPhoneNumber !== "") {
            if (localPhoneNumber[1] === "8") {
                localPhoneNumber = "+7";
            } else {
                localPhoneNumber = "+7" + localPhoneNumber.substring(1, localPhoneNumber.length);
            }
        } else if (localPhoneNumber === "") {
            localPhoneNumber = "";
        }
        setFormValue("Phone", localPhoneNumber);
    };

    function sendForm() {
        if (!isValidate) {
            setIsError(true);
            return;
        }
        http.post<any, any, any>("api/v1/lead/createAnonymousLead", null, {
            AnonymousLeadType: "payrixlanding",
            Request: form,
        })
            .then((res) => {
                setForm(INITIAL_FORM);
                setIsError(false);
                props.handleClose?.();
                props.confirmFn?.();
            })
            .catch((err) => {});
    }

    React.useEffect(() => {
        setForm(INITIAL_FORM);
        setIsError(false);
    }, [props.isOpen]);

    return (
        <WhiteModal
            isOpen={props.isOpen}
            className={props.className}
            handleClose={props.handleClose}
            title={props.title}
        >
            <Typography variant="h1" component="h1">
                Создать заявку
            </Typography>
            <div className={clsx(styles.blocks, styles.firstBlock)}>
                <div className={styles.block}>
                    <div className={styles.subtitle}>Личная информация</div>
                    <TextFieldMain
                        variant="filled"
                        label="Имя"
                        error={isError && !form.Name}
                        required
                        value={form.Name}
                        onChange={(event) => setFormValue("Name", event.target.value)}
                    />
                    <TextFieldMain
                        variant="filled"
                        label="Фамилия"
                        error={isError && !form.Surname}
                        required
                        value={form.Surname}
                        onChange={(event) => setFormValue("Surname", event.target.value)}
                    />
                    <TextFieldMain
                        variant="filled"
                        label="Отчество (если есть)"
                        value={form.Patronymic}
                        onChange={(event) => setFormValue("Patronymic", event.target.value)}
                    />
                    {/* <TextFieldMain
                        variant="filled"
                        label="Номер телефона"
                        required
                        value={form.phone}
                        onChange={(event) => setFormValue("phone", event.target.value)}
                    /> */}
                    <PatternFormat
                        id="phone"
                        label="Ваш номер"
                        customInput={TextFieldMainForPhone}
                        type="tel"
                        format="+# (###) ###-##-##"
                        placeholder="+7 (800) 000-00-00"
                        // className="main_input"
                        error={isError && !form.Phone}
                        required
                        value={form.Phone}
                        onChange={handleChangePhone}
                        // autoFocus={true}
                        inputMode="numeric"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextFieldMain
                        variant="filled"
                        label="E-mail"
                        value={form.Email}
                        onChange={(event) => setFormValue("Email", event.target.value)}
                    />
                </div>
                <div className={styles.block}>
                    <div className={styles.subtitle}>Информация о компании</div>
                    <TextFieldMain
                        variant="filled"
                        label="ИНН Организации"
                        error={isError && !form.Inn}
                        required
                        value={form.Inn}
                        onChange={(event) => setFormValue("Inn", event.target.value)}
                    />
                    <TextFieldMain
                        variant="filled"
                        label="Необходимая сумма"
                        error={isError && !form.Sum}
                        required
                        value={form.Sum}
                        onChange={(event) => setFormValue("Sum", event.target.value)}
                    />
                    <TextFieldMain
                        variant="filled"
                        error={isError && !form.Marketplace}
                        select
                        SelectProps={{
                            IconComponent: KeyboardArrowDownIcon,
                        }}
                        required
                        label="Маркетплейс (на котором вы работаете)"
                        value={form.Marketplace}
                        onChange={(event) => setFormValue("Marketplace", event.target.value)}
                    >
                        {/* <MenuItem value={"all"}>Все статусы</MenuItem> */}
                        {MARKETPLACES.map(({ key, name }) => (
                            <MenuItem key={key} value={name}>
                                {name}
                            </MenuItem>
                        ))}
                    </TextFieldMain>
                </div>
                <div className={styles.block}>
                    <Button className={styles.button} onClick={() => sendForm()}>
                        <div className={styles.buttonContent}>
                            <div className={styles.text}>Оставить заявку</div> <ArrowRight />
                        </div>
                    </Button>
                    <Typography className={styles.infoText} variant="body2" component="p">
                        Нажимая "Оставить заявку" я даю{" "}
                        <span
                            onClick={() => setIsOpenPersonalData(true)}
                            className={styles.textLink}
                        >
                            согласие на обработку <br /> персональных данных
                        </span>{" "}
                    </Typography>
                </div>
            </div>
            <PersonalData
                isOpen={isOpenPersonalData}
                handleClose={() => setIsOpenPersonalData(false)}
            />
            <UserAgreement
                isOpen={isOpenUserAgreement}
                handleClose={() => setIsOpenUserAgreement(false)}
            />
        </WhiteModal>
    );
}
