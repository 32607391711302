import clsx from "clsx";
import { ButtonHTMLAttributes, DetailedHTMLProps } from "react";
import styles from "./Button.module.scss";

type Props = DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
    className?: string;
    children: React.ReactNode;
    buttonType?: "primary";
};

export function Button({ className, children, buttonType = "primary", disabled, ...args }: Props) {
    return (
        <button
            className={clsx(
                buttonType === "primary" && styles.base,
                buttonType === "primary" && disabled && styles.base_disabled,
                className
            )}
            disabled={disabled}
            {...args}
        >
            {children}
        </button>
    );
}
