import { Modal, Typography, IconButton, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { memo } from "react";
import classes from "./ConfirmModal.module.scss";

type PropsType = {
  handleConfirm?: () => void;
  title?: string;
  text?: string;
  handleCancel: () => void;
  confBtnText?: string;
  cancelBtnText: string;
  open: boolean;
  isKros?: boolean;
  setModalProps?: (prev: boolean | ((prev: boolean) => boolean)) => void;
  handleClose?: () => void;
  textButtonHandle?: () => void;
  textTextBtn?: string;
  isExtraCloseModal?: boolean;
  extraSubText?: React.ReactNode;
  extraButtonText?: string;
  extraButtonClick?: () => void;
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: [350, 460],
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  p: "32px",
  borderRadius: "24px",
};

const ConfirmModal: React.FC<PropsType> = (props) => {
  const handleClose = () => {
    props.handleClose && props.handleClose();
    props.setModalProps && props.setModalProps(false);
    props.isExtraCloseModal && props.handleConfirm && props.handleConfirm();
  };

  const handleCancel = () => {
    props.handleCancel();
  };

  const handleConfirm = () => {
    props.handleConfirm && props.handleConfirm();
  };

  return (
    <Modal
      open={props.open}
      onClose={props.extraButtonText ? handleCancel : handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Box sx={style}>
        <div
          className={classes.paper}
          style={props.extraButtonText ? { padding: "50px 50px" } : {}}
        >
          <div className={classes.box}>
            <Typography
              variant="h2"
              component="h2"
              className={classes.title}
              style={{ padding: "0 0 25px 0" }}
            >
              {props.title}
            </Typography>
            {!props.isKros && (
              <IconButton
                aria-label="close"
                onClick={props.extraButtonText ? handleCancel : handleClose}
              >
                <CloseIcon />
              </IconButton>
            )}
          </div>
          <Typography variant="body1" component="p" className={classes.text}>
            {props.text}
          </Typography>
          {props.extraSubText && props.extraSubText}

          <div className={classes.buttons}>
            {props.confBtnText && props.handleConfirm && (
              <button
                onClick={handleConfirm}
                className="button_outlined"
                style={{ width: "50%" }}
              >
                <Typography
                  variant="body1"
                  component="span"
                  style={{ color: "inherit", textTransform: "none" }}
                >
                  {props.confBtnText}
                </Typography>
              </button>
            )}
            {props.extraButtonText && (
              <button
                className="button_black"
                style={{ width: "50%" }}
                onClick={props.extraButtonClick}
              >
                <Typography
                  variant="body1"
                  component="span"
                  style={{ color: "inherit", textTransform: "none" }}
                >
                  {props.extraButtonText}
                </Typography>
              </button>
            )}
            <button
              className="button_black"
              style={{ width: "50%" }}
              onClick={handleCancel}
            >
              <Typography
                variant="body1"
                component="span"
                style={{ color: "inherit", textTransform: "none" }}
              >
                {props.cancelBtnText}
              </Typography>
            </button>
          </div>
          {props.textButtonHandle && props.textTextBtn && (
            <Typography
              variant="body1"
              component="p"
              onClick={props.textButtonHandle}
              className={classes.textBtn}
            >
              {props.textTextBtn}
            </Typography>
          )}
        </div>
      </Box>
    </Modal>
  );
};

export default memo(ConfirmModal);
