import { AnyAction, applyMiddleware, bindActionCreators, compose, createStore, Dispatch } from 'redux';
import thunk, { ThunkDispatch } from 'redux-thunk';
import { rootReducer, RootState } from './reducers';
import * as actionCreators from './action-creators';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { rootState } from '../types/rootState';



declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}
const composeEnhancers =
    (process.env.NODE_ENV !== "production" &&
        typeof window !== "undefined" &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;

export const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(thunk))
);

export const actions = bindActionCreators(actionCreators, store.dispatch);

export type DispatchType = Dispatch<any>

export type AppDispatch = typeof store.dispatch
export type ThunkAppDispatch = ThunkDispatch<rootState, void, AnyAction>;

export const useAppThunkDispatch = () => useDispatch<ThunkAppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export type GetStateType = () => RootState;