import React from "react";
import { Button } from "../HomeMain/Button";
import classes from "./CookieBox.module.scss";
import { PersonalData } from "../modals/PersonalData";

export const CookieBox = React.memo(() => {
    const [isOpenPersonalData, setIsOpenPersonalData] = React.useState(false);
    const [cookiesClicked, setCookiesClicked] = React.useState<boolean>(
        !localStorage.getItem(`cookies_agreed`)
    );
    const handleClick = () => {
        if (!localStorage.getItem(`cookies_agreed`)) {
            localStorage.setItem(`cookies_agreed`, "true");
            setCookiesClicked(false);
        }
    };

    return cookiesClicked ? (
        <>
            <div className={classes.cookiesBox}>
                <div className={classes.text}>
                    Мы используем файлы «cookies» для сбора технических данных о наших посетителях с
                    целью улучшения нашего сайта и предоставления более качественного сервиса. Для
                    получения дополнительной информации о том, как мы обрабатываем персональные
                    данные, пожалуйста, ознакомьтесь с нашей{" "}
                    <span onClick={() => setIsOpenPersonalData(true)} className={classes.textLink}>
                        Политикой обработки персональных данных
                    </span>
                    . Нажимая кнопку «Согласен(-на)», вы подтверждаете свое согласие на обработку
                    ваших персональных данных."
                </div>
                <Button className={classes.button} onClick={handleClick}>
                    <div className={classes.text}>Согласен(-на)</div>
                </Button>
            </div>
            <PersonalData
                isOpen={isOpenPersonalData}
                handleClose={() => setIsOpenPersonalData(false)}
            />
        </>
    ) : null;
});
