// import { Typography, createStyles, Theme } from "@mui/material";
import { memo, useEffect,  useState } from "react";
import { useSelector } from "react-redux";
import classes from "../../Login.module.scss";
import { rootState } from "../../../../core/types/rootState";
import { useNavigate } from "react-router-dom";
import { FormType, ROLES, ROUTES } from "../../../../core/consts/common";
import authService from "../../../../core/services/AuthService";
import Counter from "../Counter/Counter";
import { TextFieldMain } from "../../../../UIcomponents/TextFieldMain";

type Props = {
  phone: string;
};

const CODE_NUMBER = 5;
// const CODE_LENGTH = new Array(CODE_NUMBER).fill(0);

// const updateArray = (arr: Array<string>, i: number, val: string) => {
//   const copy = [...arr];
//   copy[i] = val;
//   return copy;
// };

// const detectDevice = (): string => {
//   const userAgentRes = navigator.userAgent;
//   let result = "desk";
//   if (/IEMobile|Windows Phone|Lumia/i.test(userAgentRes)) {
//     result = "Windows Phone";
//   } else if (/iPhone|iP[oa]d/.test(userAgentRes)) {
//     result = "iOS";
//   } else if (/Android/.test(userAgentRes)) {
//     result = "Android";
//   } else if (/BlackBerry|PlayBook|BB10/.test(userAgentRes)) {
//     result = "BlackBerry";
//   } else if (/Mobile Safari/.test(userAgentRes)) {
//     result = "Mobile Safari";
//   } else if (
//     /webOS|Mobile|Tablet|Opera Mini|\bCrMo\/|Opera Mobi/i.test(userAgentRes)
//   ) {
//     result = "mob";
//   }
//   return result;
// };

const PhoneFormWithCode: React.FC<Props> = ({ phone }) => {
  // const inputRefs = useRef(new Array(CODE_NUMBER));
  // const classes = useStyles();
  // const [code, setCode] = useState<string[]>(["", "", "", "", ""]);
  const [errMessage, setErrMessage] = useState<string>("");
  const userId = useSelector((s: rootState) => s.currentUser.userId);
  const navigate = useNavigate();

  const [codeValue, setCodeValue] = useState<string>("");

  const handleChoiceRoutes = (role: string) => {
    // if (detectDevice() === "desk") {
    switch (role) {
      case ROLES.CLIENT:
        navigate(`${ROUTES.CLIENT}/products`);
        break;
      case ROLES.AGENT:
        navigate(`${ROUTES.AGENT}/products`);
        break;
      case ROLES.PARTNER:
        navigate(`${ROUTES.PARTNER}/applications`);
        break;
      case ROLES.MANAGER:
        navigate(`${ROUTES.MANAGER}/profile`);
        break;
      case ROLES.ADMIN:
        navigate(`${ROUTES.ADMIN}/profile`);
        break;
      default: {
        navigate({
          pathname: window.location.pathname,
          // search: `?formType=${FormType.LoginInn}`,
          search: `?formType=${FormType.LoginClientOrAgent}`,
        });
      }
    }
    // } else if (role === "NotInn") {
    //   navigate({
    //     pathname: window.location.pathname,
    //     search: `?formType=${FormType.LoginClientOrAgent}`,
    //   });
    // } else {
    //   navigate({
    //     pathname: window.location.pathname,
    //     search: `?formType=${FormType.LoginBlockMob}`,
    //   });
    // }
  };

  const handleSubmit = () => {
    // const validCode = code.join("").trim();
    const validCode = codeValue;
    if (validCode.length !== CODE_NUMBER) {
      setErrMessage("Введен неверный код");
    } else {
      authService
        .confirmCode(true, userId, validCode)
        .then((role) => {
          handleChoiceRoutes(role);
          window.localStorage.setItem("isRegistration", "true");
        })
        .catch(() => {
          setErrMessage("Введен неверный код");
          // setCode(["", "", "", "", ""]);
          // inputRefs.current[0].focus();
        });
    }
  };

  // useEffect(() => {
  //   inputRefs.current[0].focus();
  // }, []);

  // useEffect(() => {
  //   const validCode = code.join("").trim();
  //   if (validCode.length === 5) {
  //     handleSubmit();
  //   }
  // }, [code]);

  // const onChangeHandler = (
  //   e: React.ChangeEvent<HTMLInputElement>,
  //   index: number
  // ) => {
  //   setErrMessage("");
  //   const oldCode = code[index];
  //   const newCode = e.target.value.trim().replace(oldCode, "");
  //   const inputs = inputRefs.current;
  //   if (newCode < "0" || newCode > "9") {
  //     return;
  //   }
  //   if (index < inputs.length - 1) {
  //     inputs[index + 1].focus();
  //   }
  //   if (newCode.length > 1) {
  //     let indexCode = index;
  //     let result = code;
  //     for (let i = 0; i < newCode.length; i++) {
  //       if (indexCode < 5) {
  //         result = updateArray(result, indexCode, newCode[i]);
  //         indexCode++;
  //       }
  //     }
  //     setCode(result);
  //     if (inputs[indexCode]) {
  //       inputs[indexCode].focus();
  //     } else {
  //       inputs[code.length - 1]?.focus();
  //     }
  //     return;
  //   }
  //   if (code[index] !== "") {
  //     setCode(updateArray(code, index + 1, newCode));
  //   } else {
  //     setCode(updateArray(code, index, newCode));
  //   }
  // };

  // const handleKeyDown = (event: KeyboardEvent, index: number) => {
  //   if (event.code === "ArrowLeft" && index > 0) {
  //     inputRefs.current[index - 1].focus();
  //     return;
  //   } else if (event.code === "ArrowRight" && index < 4) {
  //     inputRefs.current[index + 1].focus();
  //     return;
  //   } else if (event.code !== "Backspace") {
  //     return;
  //   }
  //   if (code[index] && index > 0) {
  //     setCode(updateArray(code, index, ""));
  //     inputRefs.current[index - 1].focus();
  //   } else if (code[index] && index === 0) {
  //     setCode(updateArray(code, index, ""));
  //   } else if (index > 0) {
  //     inputRefs.current[index - 1].focus();
  //   }
  // };

  useEffect(() => {
    if (codeValue.length === 5) {
      handleSubmit();
    }
  }, [codeValue]);

  return (
    <form
      className={classes.formBox}
      onSubmit={handleSubmit}
      noValidate
      autoComplete="off"
    >
      Мы отправили код на указанный номер телефона. Пожалуйста, введите его в
      поле ниже.
      {/* <div
        style={{
          fontSize: "12px",
        }}
      >
        Введите код из СМС
      </div> */}
      {/* <div className={classes.codeContainer}>
        {CODE_LENGTH.map((v, index) => (
          <input
            key={index}
            ref={(ref) => (inputRefs.current[index] = ref)}
            className={classes.inputCode}
            onChange={(e) => onChangeHandler(e, index)}
            value={code[index]}
            onKeyDown={(e) => handleKeyDown(e, index)}
            inputMode="numeric"
          />
        ))}
      </div>
      {errMessage && <div className={classes.error_flex}>{errMessage}</div>} */}
      <div>
        <TextFieldMain
          variant="filled"
          label="Введите код из СМС"
          autoFocus={true}
          className={classes.textFieldEmail}
          value={codeValue}
          onChange={(e) => {
            setErrMessage("");
            setCodeValue(e.target.value);
          }}
          error={errMessage ? true : false}
          helperText={errMessage}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </div>
      <div></div>
      <Counter phone={phone} />
      {errMessage && (
        <div
          className="link"
          onClick={() =>
            navigate({
              pathname: window.location.pathname,
              search: `?formType=${FormType.LoginCheckPhone}`,
            })
          }
          style={{ alignSelf: "center" }}
        >
          Проверить номер телефона
        </div>
      )}
    </form>
  );
};

export default memo(PhoneFormWithCode);
