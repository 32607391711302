import styles from "./HomeMain.module.scss";

export const CARDS = [
    {
        title: (
            <div className={styles.cardTitle}>
                Быстрый <br />
                старт
            </div>
        ),
        text: "Отправьте быструю заявку через личный кабинет",
    },
    {
        title: <div className={styles.cardTitle}>Личный <br />менеджер</div>,
        text: "Никаких ботов. Контакты только со спциалистами",
    },
    {
        title: (
            <div className={styles.cardTitle}>
                Быстрая обратная <br />
                связь
            </div>
        ),
        text: "Заём или факторинг за 24 часа с момента обращения",
    },
    {
        title: (
            <div className={styles.cardTitle}>
                Собственная <br />
                технология скоринга
            </div>
        ),
        text: "Умный скоринг для автоматического принятия решений",
    },
];

export const ABOUT_US_CARDS = [
    {
        title: <div className={styles.aboutUsCardTitle}>800</div>,
        text: "Клиентов работают с нами",
    },
    {
        title: <div className={styles.aboutUsCardTitle}>1000</div>,
        text: "Обеспеченных сделок в 2022 году",
    },
];

export const POPULAR_QUESTIONS = [
    {
        title: "Где работает Payrix?",
        text: "Мы предоставляем финансирование для предприятий во всех уголках России, обеспечивая доступ ко всем продуктам через онлайн-кабинет",
    },
    {
        title: "Нужен ли залог для получения финансирования?",
        text: "Для получения финансирования не нужно предоставлять залог. В редких случая может быть запрошено личное поручительство собственников бизнеса",
    },
    {
        title: "Какие документы нужны для получения финансирования?",
        text: (
            <span>
                1. Документы по компании: <br /> - до 5 млн — паспорт и ИНН; <br />- от 5 до 100
                млн — паспорта владельцев, ИНН, согласие на БКИ; <br />
                <br /> 2. Отчёт из маркетплейса или API Ключ. Для подписания документов понадобится
                Электронная подпись. <br/>Все оформление происходит онлайн.
            </span>
        ),
    },
    {
        title: "Чем отличается финансирование селлеров от факторинга?",
        text: "Это форма финансирования, которая зависит от динамики продаж и опыта работы селлера, в отличие от факторинга, где основным фактором является сумма контракта и дебитор. Подход такого финансирования предполагает, что сумма доступного финансирования изменяется в соответствии с изменением объема продаж на маркетплейсе и опыта работы селлера. Кредитный лимит регулярно восстанавливается после каждого погашения и может увеличиваться с увеличением объема продаж на маркетплейсе. Погашение финансирования происходит в соответствии с графиком платежей, который строится на основе поступлений от маркетплейса",
    },
];
