import React, { memo } from "react";
import { leadStatusHistoryType } from "../../../core/types/leadsApiTypes";
import { dateWithTimeConverter } from "../../../core/helpers/dateConverter";
import style from "./Status.module.scss"
import { StatusCircleIcon } from "../../../core/consts/images/icons";

type propsType = {
  stages: leadStatusHistoryType[];
};

const Status: React.FC<propsType> = (props) => {

  const capitalizeFirstLetter = (str: string) => {
    return str ? str.charAt(0).toUpperCase() + str.slice(1) : "No data";
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', marginBottom: '16px' }}>
      {props.stages.map((item, i) => {
        return (
          item && (
            <div key={i} className={style.statusContainer}>
              <StatusCircleIcon color={item.statusColor} />
              <p>
                {`[${capitalizeFirstLetter(item.newStatus)}]`}
              </p>
              <p>
                {dateWithTimeConverter(item.date)}
              </p>
            </div>
          )
        );
      })}
    </div>
  );
};

export default memo(Status);
