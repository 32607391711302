import React, { FC, useState } from "react";
import { Box, IconButton, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import classes from "./ModalMain.module.scss";

type IProps = {
  isOpen: boolean;
  title: string;
  handleClose: () => void;
  handleSubmit?: any;
  nameSubmitButton?: string;
  children?: React.ReactNode;
  width?: string;
  color?: string;
  isOrange?: boolean;
  formId?: string;
  submitDisable?: boolean;
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: [350, 460],
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  p: "32px",
  borderRadius: "24px",
};

const ModalMain: FC<IProps> = ({
  isOpen,
  title,
  handleClose,
  handleSubmit,
  children,
  nameSubmitButton,
  width,
  color,
  isOrange,
  formId,
  submitDisable,
}) => {
  const [isLoading, setLoading] = useState<boolean>(false);

  const handleLocalSumbit = async () => {
    setLoading(true);
    await handleSubmit();
    setLoading(false);
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className={classes.title_box}>
          <div>
            {title}
          </div>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>

        {children &&
          <div className={classes.children}>
            {children}
          </div>}
        <div
          className={classes.button_box}
        >
          {/* {isLoading && <LocalSpinnerAbsolute size="70px" />} */}
          <button
            onClick={() => handleClose()}
            className="button_outlined"
            style={{ width: "50%" }}
          >
            Отмена
          </button>
          <button
            onClick={formId ? undefined : handleLocalSumbit}
            className="button_black"
            style={{ width: "50%" }}
            form={formId}
            type={formId ? "submit" : undefined}
            disabled={submitDisable}
          >
            {nameSubmitButton ? nameSubmitButton : "Подтвердить"}
          </button>
        </div>
      </Box>
    </Modal>
  );
};

export default ModalMain;
