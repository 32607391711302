import React from "react";

import clsx from "clsx";

import styles from "./Checkbox.module.css";
import { Checked } from "../../../icons/Checked";
import { Unchecked } from "../../../icons/Unchecked";

type Props = React.HTMLProps<HTMLInputElement> & {
    color?: string;
    labelText?: string | React.ReactNode;
    labelClassName?: string;
    iconCls?: string;
};

export function Checkbox({ color, labelText, labelClassName, iconCls, ...args }: Props) {
    return (
        <label className={clsx(styles.label, labelClassName)}>
            <input type="checkbox" {...args} className={styles.input} />
            {args.checked ? (
                <Checked className={clsx(styles.icon, iconCls)} />
            ) : (
                <Unchecked className={clsx(styles.icon, iconCls)} />
            )}
            {labelText}
        </label>
    );
}

Checkbox.WithRef = React.forwardRef(Checkbox);
