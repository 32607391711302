import React from "react";
import styles from "./Accordeon.module.css";

export type Props = {
    title: string;
    content: React.ReactNode;
    initialState?: boolean;
    setIsActive?: (isActive: boolean) => void;
    isActive?: boolean;
};

export function Accordeon(props: Props) {
    const [isActive, setIsActive] = React.useState(
        props.initialState !== undefined ? props.initialState : false
    );

    React.useEffect(() => {
        if (props.isActive !== undefined) {
            setIsActive(props.isActive);
        }
    }, [props.isActive]);

    function handleIsActiveState() {
        if (props.setIsActive) {
            props.setIsActive(!props.isActive);
            return;
        }
        setIsActive(!isActive);
    }

    return (
        <div className={styles.base}>
            <div className={styles.header} onClick={() => handleIsActiveState()}>
                <div className={styles.title}>{props.title}</div>
                <div className={styles.icon}>{isActive ? "-" : "+"}</div>
            </div>
            {isActive && <div className={styles.content}>{props.content}</div>}
        </div>
    );
}
