import { ChangeEvent, useEffect, useState } from "react";
import styles from "./Note.module.scss";
import "./Note.scss";
import { MenuItem, Checkbox, Collapse } from "@mui/material";
import EventNoteIcon from "@mui/icons-material/EventNote";

// import DateFnsUtils from "@date-io/date-fns";
// import ruLocale from "date-fns/locale/ru";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  DaysOfWeekEnum,
  FrequencyUnitEnum,
  NoteType,
  NotificationTypeEnum,
} from "../../../core/api/noteApi";
import noteService from "../../../core/services/NoteService";
import Preloader from "../../../UIcomponents/Preloader/Preloader";
import {
  TextFieldDate,
  TextFieldMain,
} from "../../../UIcomponents/TextFieldMain";
import CustomSnackBar from "../../../pages/user/partner-current-application/components/CustomSnackBar";

const DaysOfWeekRu: { [index: string]: string } = {
  [DaysOfWeekEnum.Mo]: "Пн",
  [DaysOfWeekEnum.Tu]: "Вт",
  [DaysOfWeekEnum.We]: "Ср",
  [DaysOfWeekEnum.Th]: "Чт",
  [DaysOfWeekEnum.Fr]: "Пт",
  [DaysOfWeekEnum.Sa]: "Сб",
  [DaysOfWeekEnum.Su]: "Вс",
};

const TypeOfNotificationRu: { [index: string]: string } = {
  [NotificationTypeEnum.emailPortal]: "Уведомлять на портале и на E-mail",
  [NotificationTypeEnum.email]: "Уведомлять на E-mail",
  [NotificationTypeEnum.portal]: "Уведомлять на портале",
};

type RepeatType = {
  id: number;
  name: string;
  repeatValue: string;
};

const repeats: RepeatType[] = [
  { id: 0, name: "Не повторять", repeatValue: FrequencyUnitEnum.no },
  { id: 1, name: "Повторять по неделям", repeatValue: FrequencyUnitEnum.week },
  { id: 2, name: "Повторять по дням", repeatValue: FrequencyUnitEnum.day },
];

type Props = {
  note?: NoteType | null;
  setIsEdit?: React.Dispatch<React.SetStateAction<boolean>>;
  setChosenNote?: React.Dispatch<React.SetStateAction<NoteType | null>>;
  leadId?: string;
  getNotes: () => Promise<void>;
};

const NoteView: React.FC<Props> = ({
  note,
  setIsEdit,
  setChosenNote,
  leadId,
  getNotes,
}) => {
  const [isLoading, setIsloading] = useState(false);

  const [text, setText] = useState("");
  const [date, setDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [isRemind, setIsRemind] = useState(false);
  const [frequencyUnit, setFrequencyUnit] = useState<FrequencyUnitEnum>(
    FrequencyUnitEnum.no
  );
  const [hours, setHours] = useState<string | number>("");
  const [minutes, setMinutes] = useState<string | number>("");
  const initialDays: { [index: string]: boolean } = {
    [DaysOfWeekEnum.Mo]: false,
    [DaysOfWeekEnum.Tu]: false,
    [DaysOfWeekEnum.We]: false,
    [DaysOfWeekEnum.Th]: false,
    [DaysOfWeekEnum.Fr]: false,
    [DaysOfWeekEnum.Sa]: false,
    [DaysOfWeekEnum.Su]: false,
  };
  const [repeatDays, setRepeatDays] = useState<{ [index: string]: boolean }>(
    initialDays
  );
  const [frequencyCount, setFrequencyCount] = useState<string | number>("1");
  const [notificationType, setNotificationType] =
    useState<NotificationTypeEnum>(NotificationTypeEnum.emailPortal);

  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const getDaysForCheckBox = (days: DaysOfWeekEnum[]) => {
    const daysForm = { ...initialDays };
    days.forEach((elem) => (daysForm[elem] = true));
    // console.log(daysForm)
    return daysForm;
  };

  const updateFormFromNote = (note: NoteType) => {
    cleanForm();
    setIsRemind(note.isNotificate);
    setText(note.text);
    if (note.isNotificate) {
      if (note?.notificationStartDate) {
        setDate(new Date(note?.notificationStartDate));
      } else {
        setDate(null);
      }
      if (note?.notificationEndDate) {
        setEndDate(new Date(note?.notificationEndDate));
      } else {
        setEndDate(null);
      }
      setFrequencyUnit(note?.frequencyUnit ?? FrequencyUnitEnum.no);
      setHours(note?.hours ?? "");
      setMinutes(note?.minutes ?? "");
      setFrequencyCount(note?.frequencyCount ?? "1");
      if (note?.days) {
        setRepeatDays(getDaysForCheckBox(note.days) ?? initialDays);
      } else {
        setRepeatDays(initialDays);
      }
      setNotificationType(
        note?.notificateType ?? NotificationTypeEnum.emailPortal
      );
    }
  };

  useEffect(() => {
    // console.log("note", note)
    if (note) {
      updateFormFromNote(note);
    } else {
      // cleanForm();
    }
  }, [note]);

  const handlerChangeText = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (e.target.value.length <= 200) {
      setText(e.target.value);
    }
  };
  const cleanForm = () => {
    setDate(null);
    setFrequencyUnit(FrequencyUnitEnum.no);
    setHours("");
    setMinutes("");
    setFrequencyCount("1");
    setRepeatDays(initialDays);
    setNotificationType(NotificationTypeEnum.emailPortal);
    setEndDate(null);
  };

  const cancel = () => {
    if (!note) {
      setText("");
      setIsRemind(false);
      cleanForm();
    } else {
      updateFormFromNote(note);
    }
    setIsEdit && setIsEdit(false);
    setChosenNote && setChosenNote(null);
  };

  const onChangeDateInput = (date: Date | null) => {
    // const addZero = (val: number): string => {
    //   return val > 9 ? val.toString() : "0" + val;
    // };
    // console.log("date",date);
    if (date) {
      // const dateForState = `${date.getFullYear()}-${addZero(
      //   date.getMonth() + 1
      // )}-${addZero(date.getDate())}`;
      setDate(date);
    }
  };

  const getIsDisabled = () => {
    if (isLoading) {
      return true;
    } else if (text.length === 0) {
      return true;
    } else if (text && isRemind === false) {
      return false;
    } else if (
      frequencyUnit === FrequencyUnitEnum.no &&
      date &&
      hours.toString().length > 0 &&
      minutes.toString().length > 0
    ) {
      return false;
    } else if (
      frequencyUnit === FrequencyUnitEnum.week &&
      date &&
      hours.toString().length > 0 &&
      minutes.toString().length > 0 &&
      frequencyCount &&
      Object.entries(repeatDays).find((elem) => elem[1] === true) &&
      endDate
    ) {
      return false;
    } else if (
      frequencyUnit === FrequencyUnitEnum.day &&
      date &&
      hours.toString().length > 0 &&
      minutes.toString().length > 0 &&
      frequencyCount &&
      endDate
    ) {
      return false;
    } else {
      return true;
    }
  };

  // useEffect(()=>console.log("useEffect date>>>", date), [date])

  const createOrUpdateNote = async () => {
    // console.log("createOrUpdateNote date", date)
    setIsloading(true);
    const newNote: NoteType = {
      text: text,
      isNotificate: isRemind,
      createDateTime: new Date().toLocaleString(),
    };
    if (isRemind && date) {
      newNote.notificationStartDate = date.toDateString();
    }
    if (isRemind) {
      newNote.hours = parseInt(hours.toString());
      newNote.minutes = parseInt(minutes.toString());
      newNote.frequencyUnit = frequencyUnit;
    }
    if (isRemind && frequencyUnit !== FrequencyUnitEnum.no && frequencyCount) {
      newNote.frequencyCount = frequencyCount;
    } else {
      newNote.frequencyCount = 1;
    }
    if (isRemind && frequencyUnit === FrequencyUnitEnum.week) {
      const arrDays: DaysOfWeekEnum[] = [];
      Object.entries(repeatDays).forEach((el) => {
        if (el[1] === true) {
          arrDays.push(el[0] as DaysOfWeekEnum);
        }
      });
      newNote.days = arrDays;
    }
    if (isRemind) {
      newNote.notificateType = notificationType;
    }
    if (endDate) {
      newNote.notificationEndDate = endDate.toISOString();
    }
    if (!note && leadId) {
      newNote.leadId = +leadId;
      // console.log("newNote ", newNote);

      const res = await noteService.createNote(newNote);
      if (res) {
        setIsSuccess(true);

        setTimeout(async () => {
          await getNotes();
          cancel();
        }, 1000);
      } else {
        setIsError(true);
        setTimeout(() => {
          setIsError(false);
        }, 1000);
      }
    } else if (note) {
      newNote.id = note.id;
      newNote.leadId = note?.leadId;
      // console.log("changeNote ", newNote);
      const res = await noteService.changeNote(newNote);
      if (res) {
        setIsSuccess(true);
        setTimeout(async () => {
          await getNotes();
          cancel();
        }, 1000);
      }
    }
    setIsloading(false);
  };

  return (
    <>
      {/* <div>You are running this application in <b>{process.env.NODE_ENV}</b> mode. <b>{process.env?.REACT_APP_BASE_URL}</b></div> */}
      <div className={styles.note_view}>
        {isLoading && (
          <div
            style={{
              position: "absolute",
              top: "30%",
              left: "45%",
              zIndex: 100,
            }}
          >
            <Preloader preloaderType={"block"} sizePixels={"120px"} />
          </div>
        )}
        <div className={styles.relative}>
          <textarea
            className={`main_input ${styles.note_view_text}`}
            value={text}
            onChange={(e) => handlerChangeText(e)}
          />

          <div className={styles.symbols_amount}>{text.length}/200</div>
        </div>

        <div className={`${styles.main_column}`}>
          {/* **************************ROW 1!*************************** */}

          <div
            className={`${styles.notification_settings_common} ${styles.div100}`}
            style={{ minHeight: "67px" }}
          >
            <div className={styles.flex_gap12}>
              <Checkbox
                color="secondary"
                className={styles.is_notificate_checkbox}
                sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                checked={isRemind}
                onChange={(e) => {
                  setIsRemind(e.target.checked);
                  if (e.target.checked === false) {
                    cleanForm();
                  }
                }}
              />
              <div className={styles.remind}>Напомнить </div>
            </div>
            {isRemind && (
              <div
                className={
                  isRemind
                    ? styles.notification_settings_active
                    : styles.notification_settings_passive
                }
              >
                <TextFieldDate
                  value={date}
                  onChange={onChangeDateInput}
                  // className={`main_input ${styles.field_calendar} `}
                  // slotProps={{ textField: { variant: "outlined" } }}
                  disabled={!isRemind}
                  format="dd.MM.yyyy"
                  // IconComponent={CalendarMonthIcon}
                  // openPickerIcon={EventNoteIcon}
                />
              </div>
            )}
          </div>
        </div>
        <Collapse in={isRemind}>
          <div className={styles.main_column}>
            {/* **************************ROW 2!*************************** */}
            <div
              className={`${styles.notification_settings_common} ${styles.div100} ${styles.mobile_column}`}
            >
              <div
                className={`${
                  isRemind
                    ? styles.notification_settings_active
                    : styles.notification_settings_passive
                } ${styles.no_grow}`}
              >
                <div>{"в "}</div>
                <TextFieldMain
                  variant="outlined"
                  className={styles.field_time}
                  disabled={!isRemind}
                  value={hours}
                  color="primary"
                  onChange={(event) => {
                    if (
                      event.target.value === "" ||
                      (isFinite(+event.target.value) &&
                        +event.target.value <= 24)
                    ) {
                      setHours(event.target.value as string);
                    }
                  }}
                />
                <div>:</div>
                <TextFieldMain
                  variant="outlined"
                  className={styles.field_time}
                  disabled={!isRemind}
                  value={minutes}
                  onChange={(event) => {
                    if (
                      event.target.value === "" ||
                      (isFinite(+event.target.value) &&
                        +event.target.value <= 59)
                    ) {
                      setMinutes(event.target.value as string);
                    }
                  }}
                />
              </div>

              <div
                className={`
                ${
                  isRemind
                    ? styles.notification_settings_active
                    : styles.notification_settings_passive
                } 
                ${styles.div100}
              }`}
                // style={{backgroundColor: "red"}}
              >
                <TextFieldMain
                  variant="outlined"
                  fullWidth
                  select
                  SelectProps={{
                    IconComponent: KeyboardArrowDownIcon,
                    fullWidth: true,
                  }}
                  className={styles.field_repeat_type}
                  style={{ width: "100% !important" }}
                  disabled={!isRemind}
                  value={frequencyUnit}
                  onChange={(event) =>
                    setFrequencyUnit(event.target.value as FrequencyUnitEnum)
                  }
                >
                  {repeats.map((elem) => (
                    <MenuItem key={elem.id} value={elem.repeatValue}>
                      {elem.name}
                    </MenuItem>
                  ))}
                </TextFieldMain>
              </div>
            </div>
            {/* **********************ROW 3!***************************** */}
            <Collapse
              in={frequencyUnit !== FrequencyUnitEnum.no}
              style={{
                marginTop:
                  frequencyUnit !== FrequencyUnitEnum.no ? "0px" : "-24px",
              }}
            >
              <div className={styles.mobile_column}>
                <div
                  className={
                    isRemind
                      ? styles.notification_settings_active
                      : styles.notification_settings_passive
                  }
                >
                  {frequencyUnit === FrequencyUnitEnum.week && (
                    <>
                      <div>каждую</div>
                      <TextFieldMain
                        variant="outlined"
                        className={styles.field_time}
                        value={frequencyCount}
                        onChange={(event) => {
                          if (
                            event.target.value === "" ||
                            (isFinite(+event.target.value) &&
                              +event.target.value <= 100)
                          ) {
                            setFrequencyCount(event.target.value);
                          }
                        }}
                      />
                      <div>неделю по</div>
                    </>
                  )}

                  {frequencyUnit === FrequencyUnitEnum.day && (
                    <div className={styles.notification_settings_common}>
                      <div>каждый</div>
                      <TextFieldMain
                        variant="outlined"
                        className={styles.field_time}
                        value={frequencyCount}
                        onChange={(event) => {
                          if (
                            event.target.value === "" ||
                            (isFinite(+event.target.value) &&
                              +event.target.value < 100)
                          ) {
                            setFrequencyCount(event.target.value);
                          }
                        }}
                      />
                      <div>день</div>
                    </div>
                  )}
                </div>
                <div
                  className={
                    isRemind
                      ? styles.notification_settings_active
                      : styles.notification_settings_passive
                  }
                >
                  {frequencyUnit === FrequencyUnitEnum.week && (
                    <div
                      className={`${styles.notification_settings_common} ${styles.days}`}
                    >
                      {Object.keys(DaysOfWeekEnum).map((elem) => (
                        <div className={styles.flex_gap12} key={elem}>
                          <Checkbox
                            color="secondary"
                            className={styles.is_notificate_checkbox}
                            sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                            checked={repeatDays[elem]}
                            onChange={(e) => {
                              const temp = { ...repeatDays };
                              temp[elem] = e.target.checked;
                              setRepeatDays(temp);
                            }}
                          />
                          <div className={styles.remind}>
                            {DaysOfWeekRu[elem]}
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </Collapse>

            {/* **************************ROW 4!*************************** */}

            <div
              className={`${
                isRemind
                  ? styles.notification_settings_active
                  : styles.notification_settings_passive
              } 
               ${styles.mobile_column}
          `}
            >
              <TextFieldMain
                variant="outlined"
                select
                SelectProps={{
                  IconComponent: KeyboardArrowDownIcon,
                }}
                className={`${styles.field100} ${styles.field_repeat_type}`}
                disabled={!isRemind}
                value={notificationType}
                onChange={(event) =>
                  setNotificationType(
                    event.target.value as NotificationTypeEnum
                  )
                }
              >
                {Object.keys(TypeOfNotificationRu).map((elem) => (
                  <MenuItem key={elem} value={elem}>
                    {TypeOfNotificationRu[elem]}
                  </MenuItem>
                ))}
              </TextFieldMain>
              <Collapse in={frequencyUnit !== FrequencyUnitEnum.no} style={{width: "100%"}}>
                <div
                  className={`${
                    frequencyUnit !== FrequencyUnitEnum.no
                      ? styles.notification_settings_active
                      : styles.notification_settings_passive
                  } ${styles.mobile_column}`}
                >
                  <TextFieldDate
                    label="Уведомлять до"
                    value={endDate}
                    onChange={(date: Date) => setEndDate(date)}
                    className={`${styles.field_calendar_end} `}
                    slotProps={{
                      textField: { variant: "filled", disableUnderline: true },
                      disableUnderline: true,
                    }}
                    disabled={!isRemind}
                    format="dd.MM.yyyy"
                    IconComponent={EventNoteIcon}
                    // InputProps={{ disableUnderline: true }
                  />
                </div>
              </Collapse>
            </div>
          </div>
        </Collapse>

        <div
          className={styles.notification_settings_common}
          style={{ marginTop: isRemind ? "" : "-24px" }}
        >
          <button
            className={`button_outlined ${styles.button_cancel}`}
            onClick={cancel}
          >
            Отменить
          </button>

          <button
            className={`button_black ${styles.button_submit}`}
            disabled={getIsDisabled()}
            onClick={createOrUpdateNote}
          >
            {note ? "Сохранить" : "Создать"}
          </button>
        </div>
      </div>

      {/* *****toast*************** */}
      {isSuccess && (
        <CustomSnackBar
          isOpen={isSuccess}
          handleClose={() => setIsSuccess(false)}
          title={"Сохранение заметки"}
          subTitle={"Заметка успешно сохранена"}
        />
      )}
      {isError && (
        <CustomSnackBar
          isOpen={isError}
          handleClose={() => setIsError(false)}
          title={"Сохранение заметки"}
          subTitle={"Ошибка сохранения заметки"}
        />
      )}
    </>
  );
};

export default NoteView;
