import { GetStateType, ThunkAppDispatch, actions } from "..";
import partnerService from "../../services/PartnerService";

export const getLeadsCount = () => async (dispatch: ThunkAppDispatch, getState: GetStateType) => {
    try {
        const leadsCount = await partnerService.getCount();
        actions.setTabs([
            { name: "Новые", count: leadsCount?.newLeadsCount ?? 0 },
            { name: "Все заявки", count: leadsCount?.allLeadsCount ?? 0 },
            { name: "Архив", count: leadsCount?.archiveLeadsCount ?? 0 },
        ]);
    } catch (error) {
        console.log("getLeadsCount error -->", { error });
    }
};
