import React, { memo, useState, useEffect } from "react";
import { MenuItem, useMediaQuery } from "@mui/material";
import * as selectors from "../../../../../core/store/selectors";
import {
    PartnerLeadType,
    PartnerLeadsResType,
    partnerApplicationsListQuery,
} from "../../../../../core/types/partnerApiTypes";
import partnerService from "../../../../../core/services/PartnerService";
import classes from "../../PartnerApplicationPage.module.scss";
import LeadCard from "../lead-card/LeadCard";
import Search from "../search/Search";
import usePagination from "@mui/material/usePagination/usePagination";
import PaginationMain, {
    getCurrentPage,
} from "../../../../../UIcomponents/PaginationMain/PaginationMain";
import { Sort, SortEnam } from "../../../../../core/consts/common";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { TextFieldTransparent } from "../../../../../UIcomponents/TextFieldTransparent";
import { useText } from "../../../../../core/hooks/useText";
import { useSelector } from "react-redux";
import { actions } from "../../../../../core/store";

type PropsType = {
    index: number;
    setNewLeads?: (prev: number | ((prev: number) => number)) => void;
    setAllLeads?: (prev: number | ((prev: number) => number)) => void;
};

const itemsPerPage = 16;

const DigitRegex = /^\d+$/;

const LeadsList: React.FC<PropsType> = (props) => {
    const timeout = React.useRef<number>();
    const isLagerThan760 = useMediaQuery("(min-width:1000px)");

    const filters = useSelector(selectors.partnerAplication.filtersFields);
    const isArchiveOrders = useSelector(selectors.partnerAplication.isArchiveOrders);
    const isNewOrders = useSelector(selectors.partnerAplication.isNewOrders);
    const currentPage = useSelector(selectors.partnerAplication.currentPage);
    //rerender when scroll
    const prevScrollY = useSelector(selectors.partnerAplication.scrollY);

    const [leadsList, setLeadsList] = useState<PartnerLeadType[]>();
    const [productsList, setProductsList] = useState<string[]>();
    const [noLeadsMessage, setNoLeadsMessage] = useState<string>("");
    const [statusList, setStatusList] = useState<string[]>([]);
    const [queryType, setQueryType] = useState<boolean | number>(6);
    const Q: partnerApplicationsListQuery = {
        isnew: isNewOrders,
        isarchive: isArchiveOrders,
        inn: undefined,
        period: undefined,
        product: undefined,
        Status: undefined,
        PageSize: itemsPerPage,
        PageNumber: 0,
    };
    // const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, setTotalCount] = useState(20);
    const [sort, setSort] = useState<SortEnam>(SortEnam.AgeDesc);

    //TODO: Отчистка костыля
    React.useEffect(() => () => window.clearTimeout(timeout.current), []);

    const { items } = usePagination({
        count: Math.ceil(totalCount / itemsPerPage),
        page: currentPage,
        onChange: (event: React.ChangeEvent<unknown>, page: number) => {
            actions.setCurrentPage(page);
            window.scrollTo(0, 0);
            // setCurrentPage(page);
        },
        // hidePrevButton: true,
        // boundaryCount: 1,
        // siblingCount: 1,
        // showLastButton: false,
        // showFirstButton: false,
    });

    const currentAmount = useText(currentPage * itemsPerPage, "заявка", "заявки", "заявок");

    // useEffect(() => {
    //     actions.setCurrentPage(1);
    //     // setCurrentPage(1);
    // }, [isNewOrders, isArchiveOrders]);

    // useEffect(() => {
    //   const page = getCurrentPage(items);
    //   if (page !== currentPage) {
    //     setCurrentPage(page);
    //   }
    // }, [items]);

    useEffect(() => {
        if (typeof queryType === "boolean") {
            if (filters.inn.length > 0 && DigitRegex.test(filters.inn)) {
                Q.inn = filters.inn;
                Q.CompanyName = undefined;
            } else if (filters.inn.length > 0) {
                Q.CompanyName = filters.inn;
                Q.inn = undefined;
            } else {
                Q.inn = undefined;
                Q.CompanyName = undefined;
            }
            // Q.inn =
            //   searchInn.length > 0 && isFinite(+searchInn) ? searchInn : undefined;
            Q.period = filters.period === "all" ? undefined : filters.period;
            Q.product = typeof filters.product === "string" ? undefined : filters.product;
            Q.Status = filters.status === "all" ? undefined : filters.status;
        } else {
            Q.inn = undefined;
            Q.CompanyName = undefined;
            Q.period = undefined;
            Q.product = undefined;
            Q.Status = undefined;
        }
        Q.PageNumber = currentPage;
        Q.OrderByType = sort;

        partnerService
            .getLeadsService(Q)
            .then((res: PartnerLeadsResType) => {
                res?.leads?.length === 0 &&
                typeof queryType === "number" &&
                isNewOrders &&
                !isArchiveOrders
                    ? setNoLeadsMessage("У вас нет новых заявок")
                    : setNoLeadsMessage("");
                if (typeof queryType === "number") {
                    let tmpProducts: string[] = [];
                    res.leads?.forEach((lead) => {
                        tmpProducts[lead.product.id] = lead.product.name;
                    });
                    setProductsList(tmpProducts);
                    setStatusList(res.statuses);
                    setLeadsList(res.leads?.filter((lead) => lead.isNew));
                    props.setAllLeads?.(res.leads?.length);
                    props.setNewLeads?.(res.leads?.filter((lead) => lead.isNew).length);
                    setQueryType(true);
                    setTotalCount(res.totalCount);
                    if (prevScrollY) {
                        console.log("prevScrollY -->", prevScrollY);
                        //TODO: Костыль, исправить
                        timeout.current = window.setTimeout(() => {
                            window.scrollTo(0, prevScrollY);
                        }, 50);
                    }
                } else if (typeof queryType === "boolean") {
                    setStatusList(res.statuses);
                    setLeadsList(res.leads);
                    setTotalCount(res.totalCount);
                }
            })
            .catch((error: any) => {
                console.log(error);
            });
    }, [
        filters.inn,
        filters.period,
        filters.product,
        filters.status,
        props.index,
        sort,
        queryType,
        currentPage,
    ]);

    useEffect(() => {
        typeof queryType === "boolean" && !queryType && setQueryType(true);
    }, [filters.inn, filters.period, filters.product, filters.status]);

    const resetFilters = () => {
        actions.setFilterValue({
            field: "inn",
            value: "",
        });
        actions.setFilterValue({
            field: "period",
            value: "all",
        });
        actions.setFilterValue({
            field: "status",
            value: "all",
        });
        actions.setFilterValue({
            field: "product",
            value: "all",
        });
        setSort(SortEnam.AgeDesc);
        actions.setCurrentPage(1);
    };

    return noLeadsMessage?.length > 0 ? (
        <div>
            <b>{noLeadsMessage}</b>
        </div>
    ) : (
        <>
            <Search statusList={statusList} productsList={productsList} />
            {/* {currentPage} */}
            <div className={classes.totalcount_sort}>
                <div>
                    {isLagerThan760 && <>{"Показано "}</>}
                    {getCurrentPage(items) * itemsPerPage < totalCount
                        ? getCurrentPage(items) * itemsPerPage
                        : totalCount}{" "}
                    {isLagerThan760 && <>{currentAmount} &nbsp;</>}из {totalCount}
                </div>

                <div className="accept_button" onClick={resetFilters}>
                    Сбросить {isLagerThan760 && <>{" фильтры"}</>}
                </div>

                <div className="spacer" />

                <div>
                    <TextFieldTransparent
                        value={sort}
                        onChange={(e) => setSort(e.target.value as SortEnam)}
                        variant="filled"
                        select
                        SelectProps={{
                            IconComponent: KeyboardArrowDownIcon,
                        }}
                        className={classes.blue_select}
                        style={{ fontWeight: "bold" }}
                    >
                        {Object.entries(SortEnam).map((elem) => (
                            <MenuItem key={elem[1]} value={elem[1]}>
                                {Sort[elem[1]]}
                            </MenuItem>
                        ))}
                    </TextFieldTransparent>
                </div>
            </div>

            {leadsList?.length === 0 ? (
                <div className={classes.no_leads}>
                    <div>С выбранными фильтрами не найдено ни одной заявки</div>
                    <button
                        className="button_outlined_s"
                        style={{ height: "40px" }}
                        onClick={resetFilters}
                    >
                        Сбросить фильтры
                    </button>
                </div>
            ) : (
                <div className="table">
                    {filters.status === "all"
                        ? leadsList?.map((lead) => {
                              return <LeadCard key={lead.id} lead={lead} searchInn={filters.inn} />;
                          })
                        : leadsList?.map((lead) => {
                              return lead.status === filters.status ? (
                                  <LeadCard key={lead.id} lead={lead} searchInn={filters.inn} />
                              ) : null;
                          })}
                </div>
            )}

            {totalCount > itemsPerPage && <PaginationMain items={items} />}
        </>
    );
};

export default memo(LeadsList);
