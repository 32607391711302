// import { decode } from 'querystring';
import queryString from 'query-string';

export type QueryParser<T> = (value?: string | string[]) => T;
export type QueryParsers<T> = { [K in keyof T]: QueryParser<T[K]> };

export const getQueryFromUrl = <T extends Record<string, unknown>>(queryParsers: QueryParsers<T>, search?: string) => {
    // const query1 = decode((search || window.location.search).slice(1));
    // console.log("1 getQueryFromUrl queryParsers", queryParsers)//{formType: ƒ } или {formType: ƒ, user: ƒ}
    // console.log("2 getQueryFromUrl search", search)// ?formType=login-first-phone
    const query = queryString.parse((search || window.location.search).slice(1));

    // console.log("3 getQueryFromUrl query", query) // {formType: 'login-first-phone'}

    return Object.keys(queryParsers).reduce<T>((memo, key) => {
        if (key in queryParsers) {
            // console.log("memo", memo)
            // console.log("key", key)
            // const parser = queryParsers[key];
            // console.log("parser", parser)
            // console.log("query[key]", query[key])
            if (query?.[key])
                return {
                    ...memo,
                    // [key]: parser?.(query[key]),
                    [key]: query[key],
                };
        }
        return memo;
    }, {} as T);
    // return query;
};
