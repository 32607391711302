import { TOKEN_NAME } from '../consts/common';

class TokenService {
    //Устанавливает куки с именем name и значением value, с настройкой path=/ по умолчанию
    // _setCookie(name: string, value: string, options: any) {
    //     options = {
    //         path: '/',
    //         ...options
    //     };

    //     // if (options.expires instanceof Date) {
    //     //     options.expires = options.expires.toUTCString();
    //     // }

    //     let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

    //     for (let optionKey in options) {
    //         updatedCookie += "; " + optionKey;
    //         let optionValue = options[optionKey];
    //         if (optionValue !== true) {
    //             updatedCookie += "=" + optionValue;
    //         }
    //     }

    //     document.cookie = updatedCookie;
    // }

    _setCookie = (name: string, value: string, days: number, path: string) => {
        let cookie = `${name}=${encodeURIComponent(value)}`;
        // Add expiry date
        if (days) {
            const expiry = new Date();
            expiry.setDate(expiry.getDate() + days);
            cookie += `; expires=${expiry.toUTCString()}`;
        }
        if (path) cookie += `; path=${path}`;
        // Set an HTTP cookie
        document.cookie = cookie;
    };

    // возвращает куки с указанным name,
    // или undefined, если ничего не найдено
    _getCookie(name: string) {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            let c = cookies[i].trim().split('=');
            if (c[0] === name) {
                return c[1];
            }
        }
        return undefined;
    }

    _deleteCookie(name: string) {
        document.cookie = `COOKIE_NAME=${name}; Max-Age=-1; path=/`;
    }

    setAccessToken(token: string) {
        this._setCookie(TOKEN_NAME, token, 3600, '/');
    }

    getAccessToken(): string | undefined {
        return this._getCookie(TOKEN_NAME) ?? undefined;
    }

    removeAccessToken() {
        this._deleteCookie(TOKEN_NAME);
    }

    setRefreshToken(token: string) {
        localStorage.setItem(TOKEN_NAME, token);
    }
    getRefreshToken() {
        return localStorage.getItem(TOKEN_NAME);
    }
    removeRefreshToken() {
        localStorage.removeItem(TOKEN_NAME);
    }
    parseJwt = (token: string) => {
        try {
            return JSON.parse(atob(token.split('.')[1]));
        } catch (e) {
            return null;
        }
    };


}

const tokenService = new TokenService();

export default tokenService;


