import { ORGANIZATION_TYPE } from "../consts/common";
import { http } from "../infrastructure/Https";
import tokenService from "../services/TokenService";

const ROOT_URL = "/api/v1/notification";
const ROOT_URL_SHOT = "/api/v1";

export type notificationType = {
  id: number;
  dateAdd: string;
  isRead: boolean;
  action: string | null;
  actionId: string | null;
  leadId: number;
  productId: number;
  text: string;
  proposal: {
    id: number;
    bank: {
      id: number;
      name: string;
      organizationType: ORGANIZATION_TYPE;
    };
    name: string;
    description: string;
    cardInfo: {
      title: string;
      description: string;
    }[];
  };
};

export type notificationsListType = {
  result: notificationType[];
  pagination: {
    offset: number;
    count: number;
    total: number;
  };
};

class NotifcationsApi {
  getNotificationsCount(): Promise<{ count: number }> {
    const accessToken = tokenService.getAccessToken();
    return http.get(`${ROOT_URL}/count`, null, accessToken);
  }

  getNotificationsList(Q: {
    count?: number;
    offset?: number;
    onlyUnread?: boolean;
  }): Promise<notificationsListType | any> {
    const accessToken = tokenService.getAccessToken();
    return http.getWithParams(`${ROOT_URL}`, Q, null, accessToken);
  }

  readNotification(id: string) {
    const accessToken = tokenService.getAccessToken();
    return http.get(`${ROOT_URL}/${id}`, null, accessToken);
  }

  deleteNotification(id: string) {
    const accessToken = tokenService.getAccessToken();
    return http.delete(
      `${ROOT_URL_SHOT}/deletenotification/${id}`,
      null,
      undefined,
      accessToken
    );
  }
}

const notifcationsApi = new NotifcationsApi();
export default notifcationsApi;
