export type PartnerAplicationState = {
    filters: {
        inn: string;
        period: string;
        status: string;
        product: string;
    };
    activeTab: number;
    isNewOrders?: boolean;
    currentPage: number;
    isArchiveOrders?: boolean;
    tabs?: Tab[];
    scrollY?: number | null;
};

export type Tab = { name: string; count: number };

export type FilterField = "inn" | "period" | "status" | "product";

export enum partnerAplicationActionTypes {
    SET_FILTER_VALUE = "SET_FILTER_VALUE",
    SET_TABS = "SET_TABS",
    SET_SCROLL_Y = "SET_SCROLL_Y",
    SET_ACTIVE_TAB = "SET_ACTIVE_TAB",
    SET_CURRENT_PAGE = "SET_CURRENT_PAGE",
    SET_IS_NEW_ORDERS = "SET_IS_NEW_ORDERS",
    SET_IS_ARCHIVE_ORDERS = "SET_IS_ARCHIVE_ORDERS",
}

export type SetFilterValuePayload = {
    field: FilterField;
    value: string;
};

interface SetFilterValue {
    type: partnerAplicationActionTypes.SET_FILTER_VALUE;
    payload: SetFilterValuePayload;
}

interface SetActiveTab {
    type: partnerAplicationActionTypes.SET_ACTIVE_TAB;
    payload: number;
}

interface SetCurrentPage {
    type: partnerAplicationActionTypes.SET_CURRENT_PAGE;
    payload: number;
}

interface SetIsNewOrders {
    type: partnerAplicationActionTypes.SET_IS_NEW_ORDERS;
    payload: boolean | undefined;
}

interface SetIsArchiveOrders {
    type: partnerAplicationActionTypes.SET_IS_ARCHIVE_ORDERS;
    payload: boolean | undefined;
}

interface SetTabs {
    type: partnerAplicationActionTypes.SET_TABS;
    payload: Tab[] | undefined;
}

interface SetScrollY {
    type: partnerAplicationActionTypes.SET_SCROLL_Y;
    payload: number | null;
}

export type PartnerAplicationAction =
    | SetFilterValue
    | SetTabs
    | SetActiveTab
    | SetScrollY
    | SetIsNewOrders
    | SetCurrentPage
    | SetIsArchiveOrders;
