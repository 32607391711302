export type dataForUserChat = {
    nameChat?: string,
    chatId?: number,
    bankName?: string,
    roleChat?: string,
    productName?: string,
    avatar_url?: string,
    isMyMessage?: boolean,
    lastMessage?: string,
    lastTimeMessage?: string,
    countUnreadMessage?: number,
    isMyMessageBank?: boolean,
    lastMessageBank?: string,
    lastTimeMessageBank?: string,
    countUnreadMessageBank?: number,
    chatMessagesLength?: number,
}

export enum currentChatType {
    SET_USER_CHAT = 'SET_USER_CHAT',
}

export interface SetCurrentChatAction {
    type: currentChatType.SET_USER_CHAT;
    payload?: dataForUserChat;
}
