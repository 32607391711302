import React from "react";
import { Button } from "../Button";
import { Checkbox } from "../Checkbox/Checkbox";
// import { Input } from "../Input/Input";
import styles from "./Order.module.css";
import { PersonalData } from "../../modals/PersonalData";
import { http } from "../../../core/infrastructure/Https";
import { TextFieldMainForPhone } from "../../../UIcomponents/TextFieldMain";
import { PatternFormat } from "react-number-format";
import { ConfirmModal } from "../../modals/ConfirmModal";

const INITIAL_FORM = {
    Phone: "",
    credit: true,
    factoring: false,
};

export function Order() {
    const [isOpenPersonalData, setIsOpenPersonalData] = React.useState(false);
    const [isOpenConfirmModal, setIsOpenConfirmModal] = React.useState(false);
    const [formValues, setFormValue] = React.useState(INITIAL_FORM);

    function handleChangeFormValue(field: string, value: string | boolean) {
        setFormValue((prev) => ({ ...prev, [field]: value }));
    }

    const handleChangePhone = (event: React.ChangeEvent<HTMLInputElement>) => {
        let localPhoneNumber = event.target.value;
        const phoneNumberLength = localPhoneNumber.replaceAll(" ", "").length;
        if (phoneNumberLength === 16 && localPhoneNumber[1] !== "7") {
            localPhoneNumber = "+7" + localPhoneNumber.substring(2, localPhoneNumber.length);
        } else if (localPhoneNumber[1] !== "7" && localPhoneNumber !== "") {
            if (localPhoneNumber[1] === "8") {
                localPhoneNumber = "+7";
            } else {
                localPhoneNumber = "+7" + localPhoneNumber.substring(1, localPhoneNumber.length);
            }
        } else if (localPhoneNumber === "") {
            localPhoneNumber = "";
        }
        handleChangeFormValue("Phone", localPhoneNumber);
    };

    function handleSendForm() {
        if (!formValues.Phone) return;
        let productTypes = [];
        if (formValues.credit) {
            productTypes.push("Кредит или заём");
        }
        if (formValues.factoring) {
            productTypes.push("Факторинг");
        }
        http.post<any, any, any>("api/v1/lead/createAnonymousLead", null, {
            AnonymousLeadType: "minimumdata",
            Request: { Phone: formValues.Phone, ProductTypes: productTypes },
        })
            .then((res) => {
                setFormValue(INITIAL_FORM);
                setIsOpenConfirmModal(true);
            })
            .catch((err) => {});
    }

    return (
        <div className={styles.base}>
            <div className={styles.header}>
                <div className={styles.title}>
                    Оставьте заявку у нас <br />и получите ответ <br />
                    <span className={styles.acceptText}>в течении 24 часов</span>
                </div>
                <div className={styles.text}>
                    Введите ваш номер телефона и выберите интересующий вас продукт
                </div>
            </div>
            <div className={styles.form}>
                <PatternFormat
                    id="phone"
                    className={styles.input}
                    label="Ваш номер"
                    customInput={TextFieldMainForPhone}
                    type="tel"
                    format="+# (###) ###-##-##"
                    placeholder="+7 (800) 000-00-00"
                    value={formValues.Phone}
                    onChange={handleChangePhone}
                    inputMode="numeric"
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <div className={styles.control}>
                    <Button onClick={handleSendForm} className={styles.button}>
                        Продолжить
                    </Button>
                    <div className={styles.checkboxWrapper}>
                        <Checkbox
                            onChange={() => handleChangeFormValue("credit", !formValues.credit)}
                            checked={formValues.credit}
                            labelText="Кредиты или заём"
                        />
                        <Checkbox
                            onChange={() =>
                                handleChangeFormValue("factoring", !formValues.factoring)
                            }
                            checked={formValues.factoring}
                            labelText="Факторинг"
                        />
                    </div>
                </div>
            </div>
            <div className={styles.info}>
                Нажимая "Продолжить" я даю согласие на обработку{" "}
                <span onClick={() => setIsOpenPersonalData(true)} className={styles.acceptText}>
                    персональных данных
                </span>{" "}
                и подтверждаю что являюсь владельцем указанного номера телефона
            </div>
            <PersonalData
                isOpen={isOpenPersonalData}
                handleClose={() => setIsOpenPersonalData(false)}
            />
            <ConfirmModal
                title="Заявка отправлена"
                confirmButtonText="Отлично"
                text="Как только заявка будет обработана наш специалист свяжется с вами"
                handleCancel={() => setIsOpenConfirmModal(false)}
                isOpen={isOpenConfirmModal}
            />
        </div>
    );
}
