import "../../App.scss";
import styles from "./LayoutUser.module.scss";
import { PersonalData } from "../modals/PersonalData";
import React from "react";

const FooterUser = () => {
    const [isOpenPersonalData, setIsOpenPersonalData] = React.useState(false);
    const currentYear = new Date().getFullYear();

    return (
        <div className={styles.footer}>
            {/* <img className={styles.logo} src={whiteLogo} alt="Логотип" /> */}
            <div className={styles.textWrapper}>
                <div className={styles.left}>d
                    <div className={styles.text}>{`© ${currentYear}. PAYRIX`}</div>
                </div>
            </div>
            <PersonalData
                isOpen={isOpenPersonalData}
                handleClose={() => setIsOpenPersonalData(false)}
            />
        </div>
    );
};

export default FooterUser;
