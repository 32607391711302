import { dataForUserChat, SetCurrentChatAction, currentChatType } from "../../types/currentChatsTypes"

const initialChatState: dataForUserChat = {
    nameChat: '',
    bankName: '',
    roleChat: '',
    isMyMessage: undefined,
    lastMessage: '',
    lastTimeMessage: '',
    productName: '',
    avatar_url: '',
    countUnreadMessage: 0,
    isMyMessageBank: undefined,
    lastMessageBank: '',
    lastTimeMessageBank: '',
    countUnreadMessageBank: 0,
    chatMessagesLength: 0,
}

export const chatReducer = (state = initialChatState, action: SetCurrentChatAction) => {
    switch (action.type) {
        case currentChatType.SET_USER_CHAT:
            return {
                ...state,
                ...action.payload,
            }
        default:
            return state
    }
}