import authApi from "../api/authApi";
import { ROUTES } from "../consts/common";
import { actions } from "../store";
import { UserInfoType } from "../types/userTypes";
import { routeService } from "./RouteService";
import tokenService from "./TokenService";
import userService from "./UserService";

class IntrospectService {
  checkToken(errorStatus: any) {
    if (errorStatus?.toString() === "401") {
      authApi.introspect().then(() => {
        authApi
          .refreshAuth()
          .then((data: any) => {
            tokenService.removeAccessToken();
            tokenService.removeRefreshToken();
            tokenService.setRefreshToken(data.refresh_token);
            tokenService.setAccessToken(data.access_token);
            actions.fetchAuth({ ...data });
            return userService
              .getUserInfoService(
                tokenService.parseJwt(data.access_token).nameid
              )
              .then((res: UserInfoType) => {
                actions.setCurrentUser({
                  isAuth: true,
                  inn: res.inn,
                  userId: tokenService.parseJwt(data.access_token).nameid,
                  phone: res.phone,
                  role: res.roles.role_name,
                  manager: res.manager ? res.manager : null,
                  email: res.email,
                  nameFull: `${res.name} ${res.last_name}`,
                  is_manager_approval: res.is_manager_approval,
                  status: res?.status,
                });
                return res.roles.role_name;
              })
              .catch(() => {
                routeService.push(ROUTES.MAIN);
                actions.setCurrentUser({
                  userId: "",
                  isAuth: false,
                  inn: "",
                  phone: "",
                  role: "",
                  manager: null,
                  nameFull: "",
                  email: "",
                  is_manager_approval: undefined,
                  status: undefined,
                });
              });
          })
          .catch((error) => {
            if (
              error?.response?.status?.toString() === "400" ||
              error?.response?.status?.toString() === "404"
            ) {
              console.log(error);
            }
            routeService.push(ROUTES.MAIN);
            actions.setCurrentUser({
              userId: "",
              isAuth: false,
              inn: "",
              phone: "",
              role: "",
              manager: null,
              nameFull: "",
              email: "",
              is_manager_approval: undefined,
              status: undefined,
            });
          });
      });
    }
    actions.setCurrentUser({
      userId: "",
      isAuth: false,
      inn: "",
      phone: "",
      role: "",
      manager: null,
      nameFull: "",
      email: "",
      is_manager_approval: undefined,
      status: undefined,
    });
    tokenService.removeAccessToken();
    tokenService.removeRefreshToken();
    return
  }
}

const introspectService = new IntrospectService();
export default introspectService;
