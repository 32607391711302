
import { http } from "../infrastructure/Https";
import tokenService from "../services/TokenService";
import { PropsalData, SendNewOrderResponse, ValidateForm, ValidateFormModal } from "../types/bigForm";
import {
  leadFullInfo,
  leadsListType,
  leadStatusType,
  LeadDocType,
} from "../types/leadsApiTypes";
import { individualIpotekaType } from "../types/productTypes";
const ROOT_URL = "/api/v1/lead";

class LeadsApi {
  //-----------documents-----------

  //DELETE ​/api​/v1​/leads​/{leadid}​/documents​/{documentid} Удалить документ из заявки
  deleteLeadDoc(leadId: number, documentId: number): Promise<any> {
    const accessToken = tokenService.getAccessToken();
    return http.delete(
      `${ROOT_URL}s/${leadId}/documents/${documentId}`,
      null,
      null,
      accessToken
    );
  }
  //PUT ​/api​/v1​/leads​/{leadid}​/documents​/{documentid} Заменить файл документа заявки
  replaceLeadDoc(
    leadId: number,
    documentId: number,
    document: any,
    IsAttachedSignature: boolean
  ): Promise<any> {
    const formData = new FormData();
    formData.append("document", document);
    formData.append("IsAttachedSignature", IsAttachedSignature.toString());

    const accessToken = tokenService.getAccessToken();
    return http.put(
      `${ROOT_URL}s/${leadId.toString()}/documents/${documentId.toString()}`,
      null,
      formData,
      accessToken
    );
  }
  //GET ​/api​/v1​/leads​/documents​/{id} Получение документа
  //параметр partner_example - если true то скачается пример документа от партнера
  getLeadDoc(
    documentId: number,
    Q?: { partner_example: boolean }
  ): Promise<any> {
    const accessToken = tokenService.getAccessToken();
    return http.getWithBlob(
      `${ROOT_URL}s/documents/${documentId}`,
      Q,
      accessToken
    );
  }

  getLeadDocAct(
    documentId: number,
    Q?: { partner_example: boolean }
  ): Promise<any> {
    const accessToken = tokenService.getAccessToken();
    return http.getWithBlob(
      `/api/v1/agent/act/${documentId}`,
      Q,
      accessToken
    );
  }

  // GET ​/api​/v1​/leads​/{leadid}​/documents​/send Состояние загрузки документов по запросам
  getLeadDocsSendStatus(leadId: number): Promise<{ sendDocsState: string }> {
    const accessToken = tokenService.getAccessToken();
    return http.get(`${ROOT_URL}s/${leadId}/documents/send`, null, accessToken);
  }

  //GET ​/api​/v1​/leads​/{id}​/documents Получение документов заявкиv
  getLeadDocs(leadId: number): Promise<LeadDocType[]> {
    const accessToken = tokenService.getAccessToken();
    return http.get(`${ROOT_URL}s/${leadId}/documents`, null, accessToken);
  }
  // POST     ​/api​/v1​/leads​/{id}​/documents    Приложить документ к заявке
  sendDocToBank(leadId: number, docType: number, document: any): Promise<any> {
    const formData = new FormData();
    formData.append("Document.DocumentType", docType.toString());
    formData.append("Document.Document", document);
    const accessToken = tokenService.getAccessToken();
    return http.post(
      `${ROOT_URL}s/${leadId}/documents`,
      null,
      formData,
      accessToken
    );
  }

  sendIpotekaApplication(Q: individualIpotekaType | any, alias: string): Promise<any> {
    const accessToken = tokenService.getAccessToken();
    return http.post(
      `${ROOT_URL}/createAnonymousLead/${alias}`,
      null,
      Q,
      accessToken
    );
  }
  //PUT ​/api​/v1​/leads​/{leadid}​/documents​/{documentid}​/signature Прикрепить/заменить открепленную эцп заявителя у документа
  attachDocSignature(
    leadId: number,
    documentid: number,
    document: any
  ): Promise<any> {
    const formData = new FormData();
    formData.append("DocumentSignature", document);
    const accessToken = tokenService.getAccessToken();
    return http.put(
      `${ROOT_URL}s/${leadId}/documents/${documentid}/signature`,
      null,
      formData,
      accessToken
    );
  }
  //POST ​/api​/v1​/leads​/{leadid}​/documents​/send Разрешить отправку последних приложенных документов заявки в банк
  sendDocsToBank(leadid: string): Promise<any> {
    const accessToken = tokenService.getAccessToken();
    return http.post(
      `${ROOT_URL}s/${leadid}/documents/send`,
      null,
      null,
      accessToken
    );
  }

  //GET ​/api​/v1​/leads​/documents​/{id}​/signature​/{sid} Получение подписи Документа
  getDocSign(id: number, sid: number): Promise<any> {
    const accessToken = tokenService.getAccessToken();
    return http.get(
      `${ROOT_URL}s/documents/${id}/signature/${sid}`,
      null,
      accessToken
    );
  }

  //---------------заявки-----------
  //DELETE ​/api​/v1​/lead​/{leadId} Отзыв заявки по идентификатору.
  deleteLeadById(leadId: string): Promise<leadFullInfo> {
    const accessToken = tokenService.getAccessToken();
    return http.delete(`${ROOT_URL}/${leadId}`, null, null, accessToken);
  }

  //GET ​/api​/v1​/lead​/{leadId} Получение заявки по идентификатору.
  getLeadById(leadId: string): Promise<leadFullInfo> {
    const accessToken = tokenService.getAccessToken();
    return http.get(`${ROOT_URL}/${leadId}`, null, accessToken);
  }

  getDeleteInfoById(leadId: string): Promise<any> {
    const accessToken = tokenService.getAccessToken();
    return http.get(`${ROOT_URL}/${leadId}/delete_state`, null, accessToken);
  }

  //GET ​/api​/v1​/leads​/{productId} Получение списка заявок по продуктам
  getLeadsListByProductId(
    productId: string,
    archive?: boolean
  ): Promise<leadsListType> {
    const params = new URLSearchParams();
    if (archive) {
      params.append("isarchive", `true`);
    } else {
      params.append("isarchive", `false`);
    }
    const accessToken = tokenService.getAccessToken();
    return http.getWithParams(
      `${ROOT_URL}s/${productId}`,
      params,
      null,
      accessToken
    );
  }

  // GET ​/api​/v1​/lead​/status​/{leadId} Получение статуса заявки по идентификатору.

  getLeadStatusById(leadId: number): Promise<leadStatusType> {
    const accessToken = tokenService.getAccessToken();
    return http.get(`${ROOT_URL}/status/${leadId}`, null, accessToken);
  }

  getLeadStatuses(): Promise<any> {
    const accessToken = tokenService.getAccessToken();
    return http.get(`/api/v1/lead/statuseslist`, null, accessToken);
  }

  //---------------созадние/валидирование заявки-----------
  //GET ​/api​/v1​/leads​/fields Получение списка полей для заполнения заявки.
  getLeadsFields(Q: URLSearchParams | undefined) {
    const accessToken = tokenService.getAccessToken();
    return http.getWithParams(`${ROOT_URL}s/fields`, Q, null, accessToken);
  }
  //GET ​/api​/v1​/leads​/fieldsv3 Получение списка полей для заполнения заявки для большой формы.
  getLeadsBigFields(Q: URLSearchParams | undefined) {
    const accessToken = tokenService.getAccessToken();
    return http.getWithParams<null, PropsalData[]>(
      `${ROOT_URL}s/fieldsv4`,
      Q,
      null,
      accessToken
    );
  }

  //POST ​/api​/v1​/leads Создание новых заявок
  sendNewOrder<B>(body: B) {
    const accessToken = tokenService.getAccessToken();
    return http.post<null, B, SendNewOrderResponse>(
      `${ROOT_URL}sv3`,
      null,
      body,
      accessToken
    );
  }

  // PUT​/api​/v1​/leadsv2  Редактирование заявки
  saveDraft<B>(body: B) {
    const accessToken = tokenService.getAccessToken();
    return http.put<null, B, SendNewOrderResponse>(
      `${ROOT_URL}sv3`,
      null,
      body,
      accessToken
    );
  }

  //POST /api/v1/leads/validate Валидация заявки
  sendDataForValidate<R>(body: ValidateForm | ValidateFormModal) {
    const accessToken = tokenService.getAccessToken();
    return http.post<null, ValidateForm | ValidateFormModal, R>(
      `${ROOT_URL}s/validatev4`,
      null,
      body,
      accessToken
    );
  }

  //Запросы по ссылкам из филдов формы
  sendRequestFromField<R>(url: string) {
    const accessToken = tokenService.getAccessToken();
    return http.get<any, R>(url, undefined, accessToken);
  }

  //PUT ​/api​/v1​/leads​/{leadid}​/archive Архивирование заявки
  putLeadArchive(leadid: string) {
    const accessToken = tokenService.getAccessToken();
    return http.putWithParams(
      `${ROOT_URL}s/${leadid}/archive`,
      undefined,
      accessToken
    );
  }
}

const leadsApi = new LeadsApi();
export default leadsApi;
