import { SVGProps } from "react";

type Props = SVGProps<SVGSVGElement>;

export const Checked = (props: Props) => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <rect width="24" height="24" rx="8" fill="#242424" />
            <path
                d="M7 13L10 16L17 9"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
