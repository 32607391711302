import { AuthAction, AuthActionTypes, AuthState } from '../../types/auth';

const initialState: AuthState = {
    access_token: '',
    expires_in: 0,
    scope: '',
    refresh_token: '',
    token_type: '',
    addInfo: {
        hasInn: false,
        code: '',
        isPhoneCheck: true,
        role: '',
        userType: '',
    },
    isOpenAddInnModal: false,
}

export const authReducer = (state = initialState, action: AuthAction) => {
    switch (action.type) {
        case AuthActionTypes.SIGN_IN:
            return {
                ...state,
                ...action.payload,
                addInfo: { ...action.payload.addInfo },
            }
        case AuthActionTypes.SET_AUTH_INFO:
            return {
                ...state,
                addInfo: { ...action.payload },
            }
        case AuthActionTypes.SET_IS_OPEN_ADDINN_MODAL:
            return {
                ...state,
                isOpenAddInnModal: action.payload
            }
        default:
            return state
    }
}