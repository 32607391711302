import React, { useState } from "react";
import styles from "./Note.module.scss";
import { FrequencyUnitEnum, NoteType } from "../../../core/api/noteApi";
import {
  checkIsToday,
  dateConverterLong,
  dateConverterLongNoTime,
} from "../../../core/helpers/dateConverter";
import ModalMain from "../../../UIcomponents/MainModal/ModalMain";
import noteService from "../../../core/services/NoteService";
import CustomSnackBar from "../../../pages/user/partner-current-application/components/CustomSnackBar";

type Props = {
  note: NoteType;
  leadId?: number;
  setChosenNote: React.Dispatch<React.SetStateAction<NoteType | null>>;
  setIsEditNew: React.Dispatch<React.SetStateAction<boolean>>;
  getNotes: () => Promise<void>;
};

const NoteCard: React.FC<Props> = ({
  note,
  leadId,
  setChosenNote,
  setIsEditNew,
  getNotes,
}) => {
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [isDeleteSuccess, setIsDeleteSuccess] = useState(false);
  const [isDeleteError, setIsDeleteError] = useState(false);

  const handleDeleteNote = async () => {
    if (!note?.id) return;
    // setIsloading(true);
    const res = await noteService.deleteNote(note?.id);
    // setIsloading(false);
    if (res) {
      setIsDeleteSuccess(true);
      setIsOpenDeleteModal(false);
      setTimeout(() => {
        getNotes();
      }, 1000);
    } else {
      setIsDeleteError(true);
      setTimeout(() => {
        setIsDeleteError(false);
      }, 1000);
    }
  };

  // const insertT = (data: string) => {
  //   console.log("insertT data>>>", data);
  //   console.log("insertT new date>>>", new Date(data).toLocaleString());
  //   const temp1 = data.split(".").join("-");
  //   const temp2 = temp1.split(" ").join("T");
  //   console.log("insertT new date>>>", new Date().toLocaleString());
  //   console.log("insertT temp2>>>", temp2);
  //   // return temp2;
  //   return data;
  // };
  return (
    <>
      <div className={styles.note_card}>
        {note?.creationDate && (
          <div className={styles.note_card_time}>
            Создано &nbsp;
            {/* {dateConverterLong(insertT(note?.creationDate))} */}
            {dateConverterLong(note?.creationDate)}
          </div>
        )}
        {note?.isNotificate === true && note.notificationStartDate && (
          <div className={styles.flex_gap12}>
            <div className={styles.note_card_create}>
              Напомнить &nbsp;
              {note.actualNotificatiionDateTime ? (
                dateConverterLong(note.actualNotificatiionDateTime)
              ) : (
                <>
                  {dateConverterLongNoTime(note.notificationStartDate)}
                  &nbsp; в &nbsp;
                  {note?.hours?.toString().length === 1 && "0"}
                  {note?.hours}:{note?.minutes?.toString().length === 1 && "0"}
                  {note?.minutes}
                </>
              )}
            </div>

            {note?.isNotificate &&
              note?.frequencyUnit === FrequencyUnitEnum.no && (
                <>
                  {note.actualNotificatiionDateTime ? (
                    <>
                      {+new Date(note.actualNotificatiionDateTime) <
                      +new Date() ? (
                        <div className={styles.status_overdue}>Просрочено</div>
                      ) : (
                        checkIsToday(note.actualNotificatiionDateTime) && (
                          <div className={styles.status_today}>Сегодня</div>
                        )
                      )}
                    </>
                  ) : (
                    <>
                      {note?.isExpired === true && (
                        <div className={styles.status_overdue}>Просрочено</div>
                      )}
                      {note?.isToday === true && (
                        <div className={styles.status_today}>Сегодня</div>
                      )}
                    </>
                  )}
                </>
              )}
          </div>
        )}
        <div className={styles.note_card_text}>{note.text}</div>

        <div className={styles.note_card_button_area}>
          <div className={styles.flex_gap20}>
            <div
              className={`button_black_s ${styles.butB}`}
              onClick={() => {
                setIsEditNew(false);
                setChosenNote(note);
              }}
            >
              Редактировать
            </div>

            <div
              className={`button_white_s ${styles.butB}`}
              onClick={() => setIsOpenDeleteModal(true)}
            >
              Удалить
            </div>
          </div>
        </div>
      </div>

      <ModalMain
        title="Удалить заметку"
        isOpen={isOpenDeleteModal}
        handleClose={() => setIsOpenDeleteModal(false)}
        handleSubmit={handleDeleteNote}
      >
        <div>
          Вы собираетесь удалить заметку:
          <p style={{ color: "#6AD5CB" }}>{note.text}</p>
          После удаления все напоминания привязанные к этой заметке станут
          недоступны
        </div>
      </ModalMain>

      {/* *****toast*************** */}
      {isDeleteSuccess && (
        <CustomSnackBar
          isOpen={isDeleteSuccess}
          handleClose={() => setIsDeleteSuccess(false)}
          title={"Удаление заметки"}
          subTitle={"Заметка успешно удалена"}
        />
      )}
      {isDeleteError && (
        <CustomSnackBar
          isOpen={isDeleteError}
          handleClose={() => setIsDeleteError(false)}
          title={"Удаление заметки"}
          subTitle={"Ошибка удаления заметки"}
        />
      )}
    </>
  );
};

export default NoteCard;
