import {
    SetFilterValuePayload,
    partnerAplicationActionTypes,
    Tab,
} from "../../types/partnerAplication";

export const setFilterValue = (payload: SetFilterValuePayload) => {
    return { type: partnerAplicationActionTypes.SET_FILTER_VALUE, payload };
};

export const setTabs = (payload: Tab[] | undefined) => {
    return { type: partnerAplicationActionTypes.SET_TABS, payload };
};

export const setActiveTab = (payload: number) => {
    return { type: partnerAplicationActionTypes.SET_ACTIVE_TAB, payload };
};

export const setIsArchiveOrders = (payload: boolean | undefined) => {
    return { type: partnerAplicationActionTypes.SET_IS_ARCHIVE_ORDERS, payload };
};

export const setIsNewOrders = (payload: boolean | undefined) => {
    return { type: partnerAplicationActionTypes.SET_IS_NEW_ORDERS, payload };
};

export const setCurrentPage = (payload: number) => {
    return { type: partnerAplicationActionTypes.SET_CURRENT_PAGE, payload };
};

export const setPartnerApplicationScroll = (payload: number | null) => {
    return { type: partnerAplicationActionTypes.SET_SCROLL_Y, payload };
};
