import React from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import "../../App.scss";
import styles from "./LayoutUser.module.scss";
import { Box, Drawer, IconButton } from "@mui/material";
import { Close, MenuIconMobile } from "../../core/consts/images/icons";
import authService from "../../core/services/AuthService";
import { COLORS, ROUTES } from "../../core/consts/common";
import Logo from "../../images/logo_user.svg";

const HeaderUser = () => {
  const [isMenuOpen, setIsMenuOpen] = React.useState<boolean>(false);
  const navigate = useNavigate();

  const handleLogout = async () => {
    await authService.logout();
    navigate(ROUTES.MAIN);
  };

  return (
    <div className={styles.header}>
      <Link to={ROUTES.MAIN} className={styles.home_link}>
        <img src={Logo} alt="" />
      </Link>
      <IconButton
        sx={{
          display: { xs: "flex", md: "none" },
          position: "fixed",
          right: "20px",
          zIndex: "1000",
          backgroundColor: COLORS.BACKGROUND,
          // boxShadow: "0px 4px 64px 0px #6ad5cb3d",
        }}
        className={styles.burger}
        onClick={() => setIsMenuOpen(true)}
      >
        <MenuIconMobile />
      </IconButton>
      <Drawer
        anchor="right"
        variant="temporary"
        open={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
      >
        <div className={styles.menuMobileContainer}>
          <div className={styles.menuMobileHeader}>
            <Link to={ROUTES.MAIN} className={styles.home_link}>
              <img src={Logo} alt="" />
            </Link>
            <IconButton onClick={() => setIsMenuOpen(false)}>
              <Close />
            </IconButton>
          </div>
          <div className={styles.menuMobile}>
            {[
              { name: "Заявки", to: "applications" },
              { name: "Уведомления", to: "notifications" },
              // { name: "Настройки", to: "settings" },
              { name: "divider", to: "" },
              // { name: "Выйти", to: "/" },
            ].map((elem) =>
              elem.name === "divider" ? (
                <div
                  style={{
                    borderBottom: "1px solid #ECECEC",
                    alignSelf: "stretch",
                  }}
                  key={elem.to}
                />
              ) : (
                <NavLink
                  key={elem.name}
                  to={elem.to}
                  className={({ isActive }) =>
                    isActive ? styles.menu_item_active : styles.menu_item
                  }
                  onClick={() => setIsMenuOpen(false)}
                >
                  {elem.name}
                </NavLink>
              )
            )}
            <div className={styles.menu_item} onClick={handleLogout}>
              Выйти
            </div>
          </div>
        </div>
      </Drawer>
      <Box
        sx={{ display: { xs: "none", md: "flex" } }}
        className={styles.menuDesktop}
      >
        {[
          { name: "Заявки", to: "applications" },
          { name: "Уведомления", to: "notifications" },
          // { name: "Настройки", to: "settings" },
          // { name: "Выйти", to: "/" },
        ].map((elem) => (
          <NavLink
            key={elem.name}
            to={elem.to}
            className={({ isActive }) =>
              isActive ? styles.menu_item_active : styles.menu_item
            }
          >
            {elem.name}
          </NavLink>
        ))}
        <div className={styles.menu_item} onClick={handleLogout}>
          Выйти
        </div>
      </Box>
    </div>
  );
};

export default HeaderUser;
