import {
  Tabs,
  Tab,
  styled,
} from "@mui/material";

export const MainTabs = styled(Tabs)({
  // borderBottom: '1px solid #e8e8e8',
  borderBottom: 'none',
  '& .MuiTabs-indicator': {
    // backgroundColor: '#242424',
    backgroundColor: 'transparent',
  },
});

export const MainTab = styled((props: any) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    minWidth: 0,
    [theme.breakpoints.up('sm')]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    // marginRight: theme.spacing(1),
    // color: '#636363',
    fontFamily: "Inter",
    fontSize: "16px",
    // padding: "16px 5px",
    padding: "0px 8px", 
    paddingLeft: "0px",
    // alignItems: "start",
    '&:hover': {
      color: '#636363',
      opacity: 1,
    },
    '&.Mui-selected': {
      color: '#242424',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&.Mui-focusVisible': {
      backgroundColor: '#d1eaff',
    },
  }),
);


