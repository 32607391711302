import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { getQueryFromUrl, QueryParsers } from '../utils/getQueryFromUrl';

export function useQuery<T extends Record<string, unknown>>(queryParsers: QueryParsers<T>): T {
    const { search } = useLocation();
    // console.log("useQuery queryParsers", queryParsers) // {formType: ƒ, user: ƒ} 
    // console.log("useQuery search", search) // ?formType=login-first-phone
    // console.log("useQuery getQueryFromUrl(queryParsers, search)", getQueryFromUrl(queryParsers, search))//{formType: 'login-first-phone'}
    return useMemo(() => getQueryFromUrl(queryParsers, search), [search, queryParsers]);
}
