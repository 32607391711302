import { http } from "../infrastructure/Https";
import tokenService from "../services/TokenService";
import { AuthState } from "../types/auth";

const ROOT_URL = "/api/v1";
// const ROOT_URL = `${API_URL}/api/v1`;

type IntrospectRequestType = { refresh_token: string };
type GetCodeRequestType = { id: string };
export type ConfirmInnRDType = {
  userId: string;
  inn: string;
  lastName: string;
  name: string;
  familyName: string;
  email: string;
  code: string;
  isPhoneCheck: boolean;
  agentType?: string;
  TaxType?: string;
};

export type ConfirmRoleRDType = {
  role: string;
  code: string;
  phone?: string;
  userId: string;
  CID?: string;
  C2MUserId?: string;
  affilatecode?: string;
};

export type KeyTextType = {
  key: string;
  text: string;
};

class AuthApi {
  authWidthEmail(login: string, password: string) {
    const body = { login, password };
    return http.post(`${ROOT_URL}/auth`, null, body);
  }

  checkPhoneCode(isPhoneCheck: boolean, phone?: string, email?: string) {
    const body = phone ? { phone, isPhoneCheck } : { email, isPhoneCheck };
    return http.post<any, any, GetCodeRequestType>(
      `${ROOT_URL}/authV3`,
      null,
      body
    );
  }

  refreshAuth() {
    const body = { refresh_token: tokenService.getRefreshToken() };
    const accessToken = tokenService.getAccessToken();
    return http.post(`${ROOT_URL}/auth/refresh`, null, body, accessToken);
  }

  logout() {
    const accessToken = tokenService.getAccessToken();
    return http.post(`${ROOT_URL}/auth/logout`, null, {}, accessToken);
  }

  introspect() {
    const accessToken = tokenService.getAccessToken();
    return http.post<undefined, undefined, IntrospectRequestType>(
      `${ROOT_URL}/auth/introspect`,
      undefined,
      undefined,
      accessToken
    );
  }

  confirmCode(isPhoneCheck: boolean, userId: string, code: string): Promise<AuthState> {
    const body = { isPhoneCheck, userId, code };
    return http.post(`${ROOT_URL}/auth/confirmV2`, null, body);
  }

  confirmRole(rd: ConfirmRoleRDType): Promise<AuthState> {
    return http.post(`${ROOT_URL}/registerV2`, null, rd);
  }


  confirmInn(rd: ConfirmInnRDType): Promise<AuthState> {
    const accessToken = tokenService.getAccessToken();
    return http.post(`${ROOT_URL}/auth/confirmInnV2`, null, rd, accessToken);
  }

  keyGenerate(B: { inn: string }): Promise<{
    userId: string;
    key: number;
  }> {
    return http.post(`${ROOT_URL}/createEDSKey`, null, B);
  }

  signatureCheck(B: { userId: string; sign: string }): Promise<AuthState> {
    return http.post(`${ROOT_URL}/CheckSign`, null, B);
  }

  getAuthInfo(): Promise<KeyTextType[]> {
    return http.get(`${ROOT_URL}/reference/registrationinfo`, null);
  }
}

const authApi = new AuthApi();
export default authApi;
