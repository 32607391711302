const converter = (date: string): Date => {
  let tmpDate = new Date(date);
  return new Date(
    Date.UTC(
      tmpDate.getFullYear(),
      tmpDate.getMonth(),
      tmpDate.getDate(),
      tmpDate.getHours(),
      tmpDate.getMinutes(),
      tmpDate.getSeconds()
    )
  );
};

export const dateWithTimeConverter = (date: string): string => {
  const utcDate = converter(date);
  return utcDate.toLocaleDateString("ru-RU", {
    hour: "2-digit",
    minute: "2-digit",
  });
};

export const dateConverter = (date: string): string => {
  const utcDate = converter(date);
  return utcDate.toLocaleDateString("ru-RU");
};

export const dateConverterLong = (date: string): string => {
  if (!date) return "";
  const monthNames = [
    "января",
    "февраля",
    "марта",
    "апреля",
    "мая",
    "Июня",
    "июля",
    "августа",
    "сентября",
    "октября",
    "ноября",
    "декабря",
  ];
  // console.log("1 dateConverterLong date", date)
  const utcDate = converter(date);
  // console.log("2 dateConverterLong utcDate", utcDate)
  // const utcDate1 = new Date(date);
  // console.log("3 dateConverterLong utcDate1", utcDate1)
  return `${utcDate.getDate()} ${
    monthNames[utcDate.getMonth()]
  } ${utcDate.getFullYear()} в 
  ${
    utcDate?.getUTCHours()?.toString()?.length === 1 ? "0" : ""
  }${utcDate.getUTCHours()}:${
    utcDate?.getMinutes()?.toString().length === 1 ? "0" : ""
  }${utcDate.getMinutes()}`;
};

export const dateConverterLongNoTime = (date: string): string => {
  if (!date) return "";
  const monthNames = [
    "января",
    "февраля",
    "марта",
    "апреля",
    "мая",
    "Июня",
    "июля",
    "августа",
    "сентября",
    "октября",
    "ноября",
    "декабря",
  ];
  const utcDate = converter(date);
  // const utcDate = new Date(date);
  return `${utcDate.getDate()} ${
    monthNames[utcDate.getMonth()]
  } ${utcDate.getFullYear()}`;
};

export const timeConverter = (date: string): string => {
  const utcDate = converter(date);
  return `${utcDate.getHours()}:${utcDate.getMinutes()}`;
};

export const checkIsToday = (date: string) => {
  // const inputDate = new Date(date);
  const inputDate = converter(date);

  const todaysDate = new Date();
  if (inputDate.setHours(0, 0, 0, 0) === todaysDate.setHours(0, 0, 0, 0)) {
    return true;
  }
};
