import React, { useEffect, memo } from "react";
import "./App.scss";

// import NotFoundPage from '../pages/not-found/routing';
// import ClientPageSubroutes from './routs/client-sub-routes'
// import AgentPageSubRoutes from './routs/agent-sub-routes';
// import ManagerPageSubRoutes from './routs/manager-sub-routes';
// import MainRoutes from './routs/main-routes';
// import PartnerSubRoutes from './routs/partner-sub-routes';
// import AdminPageSubRoutes from './routs/admin-sub-routes';
import { ThemeProvider } from "@mui/material";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import tokenService from "./core/services/TokenService";
import authService from "./core/services/AuthService";
import { theme } from "./core/consts/themeDefault";
import { HomeMain } from "./pages/home/HomeMain";
import LayoutHome from "./components/layoutHome/layoutHome";
import LayoutUser from "./components/layoutUser/LayoutUser";
import { PrivateRoute } from "./components/privetRoute/PrivetRoute";
import PartnerApplicationPage from "./pages/user/partner-application/PartnerApplicationPage";
import PartnerCurrentApplicationPage from "./pages/user/partner-current-application/PartnerCurrentApplicationPage";
import Notifications from "./pages/user/notifications/Notifications";

const App: React.FC = (props: any) => {
    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
        const token = tokenService.getRefreshToken();
        if (token) {
            authService.refreshLogin().then(() => {
                // navigate(history.location.pathname)
                navigate(location.pathname);
            });
        }
        // }, [location]);
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <Routes>
                {/* **************КАБИНЕТ************************* */}
                <Route path="/partner" element={<LayoutUser />}>
                    <Route path="/partner/applications" element={<PrivateRoute />}>
                        <Route index element={<PartnerApplicationPage />} />
                    </Route>
                    <Route path="/partner/application/:tab/:leadId" element={<PrivateRoute />}>
                        <Route index element={<PartnerCurrentApplicationPage />} />
                    </Route>
                    <Route path="/partner/notifications" element={<PrivateRoute />}>
                        <Route index element={<Notifications />} />
                    </Route>
                    <Route path="/partner/settings" element={<PrivateRoute />}>
                        <Route index element={<Notifications />} />
                    </Route>
                </Route>

                <Route path="/" element={<LayoutHome />}>
                    <Route index element={<HomeMain />} />
                </Route>
            </Routes>
            {/* <Suspense fallback={<div> </div>}> */}
            {/* <Routes>
                    <Route  */}
            {/* {role === ROLES.CLIENT && isAuth && {...ClientPageSubroutes}}
                {role === ROLES.AGENT && isAuth && {...AgentPageSubRoutes}} */}
            {/* {ClientPageSubroutes}
                    {AgentPageSubRoutes}
                    {PartnerSubRoutes}
                    {ManagerPageSubRoutes}
                    {AdminPageSubRoutes}
                    {MainRoutes} */}
            {/* {NotFoundPage} */}

            {/* </Routes> */}
            {/* </Suspense> */}
        </ThemeProvider>
    );
};

export default memo(App);
