import styles from "./ConfirmModal.module.scss";
import { WhiteModal } from "../WhiteModal";
import { Button } from "../../HomeMain/Button";

export type Props = {
    isOpen: boolean;
    handleCancel?: () => void;
    confirmButtonText?: string;
    title?: string;
    text?: string;
};

export function ConfirmModal(props: Props) {
    return (
        <WhiteModal isOpen={props.isOpen} title={props.title} handleClose={props.handleCancel}>
            <div className={styles.base}>
                <div className={styles.text}>{props.text}</div>
                <Button className={styles.button} onClick={() => props.handleCancel?.()}>
                    <div className={styles.content}>
                        <div className={styles.text}>{props.confirmButtonText}</div>
                    </div>
                </Button>
            </div>
        </WhiteModal>
    );
}
