import { rootState } from "../../types/rootState";

export const filtersFields = (state: rootState) => state.partnerAplication.filters;

export const activeTab = (state: rootState) => state.partnerAplication.activeTab;

export const tabs = (state: rootState) => state.partnerAplication.tabs;

export const isNewOrders = (state: rootState) => state.partnerAplication.isNewOrders;

export const isArchiveOrders = (state: rootState) => state.partnerAplication.isArchiveOrders;

export const currentPage = (state: rootState) => state.partnerAplication.currentPage;

export const scrollY = (state: rootState) => state.partnerAplication.scrollY;
