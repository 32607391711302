import { memo, useEffect, useState } from "react";
import classes from "../../Login.module.scss";
import authApi from "../../../../core/api/authApi";

// const useStyles = makeStyles(
//   (theme: Theme) =>
//     createStyles({
//       subtitle: {
//         marginBottom: "25px",
//         maxWidth: "500px",
//         [theme.breakpoints.down("sm")]: {
//           marginBottom: "16px",
//         },
//       },
//       linkFormChange: {
//         margin: "3% 0",
//       },
//       linkFormChangeActive: {
//         margin: "3% 0",
//         cursor: "pointer",
//         color: "#306F9A",
//         fontSize: "16px",
//         fontWeight: "normal",
//         marginTop: "45px",
//       },
//     }),
//   { index: 1 }
// );

type Props = {
  phone?: string;
  email?: string;
};

const Counter: React.FC<Props> = ({ phone, email }) => {
  // const classes = useStyles();
  const [count, setCount] = useState(30);
  const [codeMessage, setCodeMessage] = useState<string | null>(null);

  useEffect(() => {
    let timerId: NodeJS.Timeout;
    if (count > 0) {
      timerId = setTimeout(() => setCount(count - 1), 1000);
    }
    return () => clearTimeout(timerId);
  }, [count]);

  const handleRefreshCode = () => {
    setCodeMessage("");
    if (!count) {
      setCount(30);
      if (phone) {
        authApi
          .checkPhoneCode(true, phone)
          .then(() => {
            setCodeMessage("Код отправлен повторно");
            setTimeout(() => {
              setCodeMessage(null);
            }, 5000);
          })
          .catch(() => {
            setCodeMessage("Не удалось повторно отправить код");
            setTimeout(() => {
              setCodeMessage(null);
            }, 5000);
          });
      } else if (email) {
        authApi
          .checkPhoneCode(false, undefined, email)
          .then(() => {
            setCodeMessage("Код отправлен повторно");
            setTimeout(() => {
              setCodeMessage(null);
            }, 5000);
          })
          .catch(() => {
            setCodeMessage("Не удалось повторно отправить код");
            setTimeout(() => {
              setCodeMessage(null);
            }, 5000);
          });
      }
    }
  };

  return (
    <div style={{ alignSelf: "center" }}>
      <button
        // className={
        //   count !== 0 ? classes.linkFormChange : classes.linkFormChangeActive
        // }
        className="accept_button"
        disabled={count !== 0}
        onClick={handleRefreshCode}
        style={{ padding: "0" }}
      >
        Отправить код повторно {count !== 0 && `(через ${count} сек)`}
      </button>
      {codeMessage && <div className={classes.note_mes}>{codeMessage}</div>}
    </div>
  );
};

export default memo(Counter);
