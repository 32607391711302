import { useState, useEffect } from "react";
import {
  notificationType,
  notificationsListType,
} from "../../../core/api/notificationsApi";
// import { ORGANIZATION_TYPE } from "../../../core/consts/common";
import NotificationItem from "./NotificationItem";
import classes from "./Notifications.module.scss";
import usePagination from "@mui/material/usePagination/usePagination";
import PaginationMain, {
  getCurrentPage,
} from "../../../UIcomponents/PaginationMain/PaginationMain";
import notifcationsService from "../../../core/services/NotificationService";
import { useText } from "../../../core/hooks/useText";

// const test: notificationType = {
//   id: 187504,
//   dateAdd: "2023-05-02T14:07:38.728811",
//   isRead: true,
//   action: null,
//   actionId: null,
//   leadId: 2857,
//   productId: 1000,
//   text: "Запрос документов",
//   proposal: {
//     id: 1097,
//     bank: {
//       id: 1013,
//       name: "Промсвязьбанк Банк",
//       organizationType: ORGANIZATION_TYPE.bank,
//     },
//     name: 'Кредит "Без бумаг|Оборотный"',
//     description: "",
//     cardInfo: [
//       {
//         title: "Ставка",
//         description: "от 15 % до 230 %",
//       },
//       {
//         title: "Сумма",
//         description: "0,014 тыс — 10 млн ₽",
//       },
//       {
//         title: "Срок",
//         description: "от 1 до 12 мес",
//       },
//     ],
//   },
// };

const itemsPerPage = 16;

const Notifications = () => {
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const { items } = usePagination({
    count: Math.ceil(totalCount / itemsPerPage),
  });

  const [notifications, setNotifications] = useState<notificationType[]>([]);
  // const [status, setStatus] = useState("0");
  const [refresh, setRefresh] = useState(false);

  const currentAmount = useText(
    currentPage * itemsPerPage,
    "уведомление",
    "уведомления",
    "уведомлений"
  );

  useEffect(() => {
    const page = getCurrentPage(items);
    if (page !== currentPage) {
      setCurrentPage(page);
    }
  }, [items]);

  // const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
  //   const newStatus = event.target.value as string;
  //   if (newStatus !== status) {
  //     setNotifications((state) => state.reverse());
  //     setStatus(newStatus);
  //   }
  // };

  const getNotification = async () => {
    let Q = {
      onlyUnread: false,
      count: itemsPerPage,
      offset: (currentPage - 1) * itemsPerPage,
    };
    const res: notificationsListType =
      await notifcationsService.getNotificationsListService(Q);
    if (res) {
      setNotifications(res?.result);
      setTotalCount(res?.pagination.total);
      window.scrollTo(0, 0);
    }
  };

  useEffect(() => {
    getNotification();
  }, [refresh, currentPage]);

  return (
    <div className={classes.container}>
      <div className="page_title">Уведомления</div>

      <div className={classes.sub_title}>
        Показано &nbsp;
        {getCurrentPage(items) * itemsPerPage < totalCount
          ? getCurrentPage(items) * itemsPerPage
          : totalCount}
        &nbsp; {currentAmount}&nbsp; из {totalCount}
      </div>
      {/* <NotificationItem notification={test} />
      <NotificationItem notification={test} /> */}
      {notifications.map((elem) => (
        <NotificationItem
          notification={elem}
          key={elem.id}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      ))}

      {totalCount > itemsPerPage && <PaginationMain items={items} />}
    </div>
  );
};

export default Notifications;
