import partnerApi from "../api/partnerApi";
import {
  PartnerLeadsResType,
  partnerApplicationsListQuery,
  documentType,
} from "../types/partnerApiTypes";
import introspectService from "./IntropsectService";

class PartnerService {
  getFileAndSignatureService(B: {
    leadid: number;
    fileType: number;
    fileName: string;
    isSignatureRequired: boolean;
  }): Promise<string> {
    return partnerApi.getFileAndSignature(B);
  }

  deleteFileIfNotDownloadedService(B: {
    leadid: number;
    fileId: number;
  }): Promise<string> {
    return partnerApi.deleteFileIfNotDownloaded(B);
  }

  postLeadFileService(data: documentType, leadId: any): Promise<string> {
    return partnerApi.postLeadFile(data, leadId);
  }

  putLeadArchive(leadid: number) {
    return partnerApi
      .putLeadArchive(leadid)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        introspectService.checkToken(error.response.status.toString());
        console.log(error);
      });
  }

  attachFileExampleService(
    baseDocumentId: number,
    clear: boolean,
    data: documentType
  ): Promise<string> {
    return partnerApi.attachFileExample(baseDocumentId, clear, data);
  }

  putLeadStatus(leadid: number, newStatus: string, comment: string) {
    return partnerApi
      .postLeadStatus(leadid, newStatus, comment)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        introspectService.checkToken(error.response.status.toString());
        console.log(error);
      });
  }

  leadStatusChangeService(B: {
    leadid: number;
    newstatus: string;
  }): Promise<string> {
    return partnerApi.leadStatusChange(B);
  }

  getLeadsService(
    Q: partnerApplicationsListQuery
  ): Promise<PartnerLeadsResType> {
    return partnerApi.getLeads(Q);
  }

  takeOffLeadFlagNewService(leadid: number): Promise<string> {
    return partnerApi.takeOffLeadFlagNew(leadid);
  }

  getCount() {
    return partnerApi
      .getCount()
      .then((res: any) => {
        return res;
      })
      .catch((error) => {
        introspectService.checkToken(error.response.status.toString());
      });
  }
}

const partnerService = new PartnerService();
export default partnerService;
