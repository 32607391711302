import { combineReducers } from "redux";
import { authReducer } from "./authRerucer";
import { currentUserReducer } from "./currentUserReducer";
import { rootState } from "../../types/rootState";
import { routeReducer } from "./routeReducer";
import { tabsErrorsReducer } from "./tabsErrorsReducer";
import { chatReducer } from "./chatReducer";
import { bigFormReducer } from "./bigFormReducer";
import { dataForOrderReducer } from "./dataForOrderReducer";
import { partnerAplicationReducer } from "./partnerAplication";

export const rootReducer = combineReducers<rootState>({
    auth: authReducer,
    bigForm: bigFormReducer,
    currentUser: currentUserReducer,
    currentChat: chatReducer,
    route: routeReducer,
    dataForOrder: dataForOrderReducer,
    tabsErrors: tabsErrorsReducer,
    partnerAplication: partnerAplicationReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
