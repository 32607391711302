import { useState } from "react";
import styles from "./ClientDetail.module.scss";
import { fieldsProposalItemsType } from "../../../core/types/leadsApiTypes";
import { CopyButton } from '../../../core/consts/images/icons';
import { Snackbar } from "@mui/material";

type Props = {
  fieldsArr: any;
  errorMessage?: string;
  proposalsItems: any;
  setCopyStatus?: Function;
};

const ClientDetail: React.FC<Props> = ({
  fieldsArr,
  errorMessage,
  proposalsItems,
}) => {
  const [copyStatus, setCopyStatus] = useState<boolean>(false);
  // const [x, setX] = useState('');
  // const [y, setY] = useState('');

  const NotSecureCopy = (text: string) => {
    const textElem = document.createElement("textarea");
    textElem.value = text;
    document.body.appendChild(textElem);
    textElem.select()
    try {
      document.execCommand('copy');
    } catch (err) {
      console.error(err);
    }
    document.body.removeChild(textElem);
    setCopyStatus(true);
  }

  // function handleClickPosition(event: any) {
  //   setX(event.pageX);
  //   setY(event.pageY);
  // }

  return (
    <>
      <div>
        {/* {fieldsArr ?
        fieldsArr.map((field: fieldsProposalItemsType) => {
          if (
            field.dataType !== "group" &&
            field.dataType !== "bool" &&
            field.value
          ) {
            let tmpVar: string[] | string = field.value;
            if (field.isSum) {
              tmpVar = new Intl.NumberFormat("ru", {
                style: "currency",
                currency: "RUB",
                minimumFractionDigits: 2,
              }).format(parseInt(field.value));
            }
            if (field.dataType === "date") {
              let tmpArr = field.value.split("-");
              tmpVar = `${tmpArr[1]}.${tmpArr[2]}.${tmpArr[0]}`;
            }
            if (field.isMultiple) {
              tmpVar = field.value
                .replace(/[[\]"]+/g, "")
                .split(",")
                .join(", ");
            }
            return (
              tmpVar.length > 0 && (
                <div key={field.name}>
                  <p>
                    {field.textName}
                  </p>
                  <p>
                    {tmpVar.trim()}
                  </p>
                </div>
              )
            );
          } else return null;
        })
        : errorMessage && (
          <p>
            {errorMessage}
          </p>
        )} */}
        {proposalsItems && proposalsItems?.map((el: any, index: number) => {
          return (
            <div key={index}>
              <h3 className={styles.infoTitle}>{el.nameGroup}</h3>
              <div>
                {el.childs.length
                  ? el.childs.map((child: any, key: number) => {
                    return (
                      <div key={key}>
                        {child.map((field: fieldsProposalItemsType) => {
                          if (
                            field.dataType !== "group" &&
                            field.dataType !== "bool" &&
                            field.value
                          ) {
                            let tmpVar: string[] | string = field.value;
                            if (field.isSum) {
                              tmpVar = new Intl.NumberFormat("ru", {
                                style: "currency",
                                currency: "RUB",
                                minimumFractionDigits: 2,
                              }).format(parseInt(field.value));
                            }
                            if (field.dataType === "date") {
                              let tmpArr = field.value.split("-");
                              tmpVar = `${tmpArr[1]}.${tmpArr[2]}.${tmpArr[0]}`;
                            }
                            if (field.isMultiple) {
                              tmpVar = field.value
                                .replace(/[[\]"]+/g, "")
                                .split(",")
                                .join(", ");
                            }
                            return (
                              tmpVar.length > 0 && (
                                <div className={styles.fieldWrap} key={field.name}>
                                  <p className={styles.fieldItem}>
                                    {field.textName}
                                  </p>
                                  <div className={styles.fieldItemValue}>
                                    {tmpVar.trim()}
                                    {field.hasCopyMark && <div
                                      onClick={(e) => {
                                        // handleClickPosition(e)
                                        if (window.isSecureContext) {
                                          navigator.clipboard.writeText(tmpVar as string)
                                            .then(() => setCopyStatus(true))
                                        } else {
                                          NotSecureCopy(tmpVar as string)
                                        }
                                      }}
                                      className={styles.buttonHover}
                                    >
                                      <CopyButton />
                                      {copyStatus &&
                                        <Snackbar
                                          open={copyStatus}
                                          onClose={() => setCopyStatus(false)}
                                          autoHideDuration={3000}
                                          message={'Скопировано'}
                                          anchorOrigin={{ vertical: "bottom", horizontal: 'right' }}
                                        />}
                                    </div>
                                    }
                                  </div>
                                </div>
                              )
                            );
                          } else return null;
                        })}
                        {el.childs.length - 1 !== key && <div style={{ margin: '14px 0' }} />}
                      </div>
                    )
                  })
                  : errorMessage && (
                    <p style={{ paddingTop: "25px" }}>
                      {errorMessage}
                    </p>
                  )}
              </div>
            </div>
          );
        })}
      </div>
      {/* <div id='pip' style={{
        opacity: `${copyStatus ? 1 : 0}`,
        position: 'absolute',
        backgroundColor: '#99D600',
        borderRadius: '4px',
        left: `${x}px`,
        top: `${y}px`,
        transition: 'opacity 0.5s linear',
        padding: '10px',
      }}>
        Скопировано
      </div> */}
    </>
  );
};

export default ClientDetail;
