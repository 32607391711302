export interface DataForOrderState {
    banksList: { name: string, id: number, bankName: string }[];
    inn: string;
    draftIds: number[];
    proposalIdForDeleteAccordion: number | undefined
    requiredFields: string[],
    isNewBasedOnOld: boolean,
}

export enum DataForOrderActionTypes {
    DATA_FOR_ORDER_PUSH = 'DATA_FOR_ORDER_PUSH',
    DATA_FOR_DRAFT_ORDER_PUSH = 'DATA_FOR_DRAFT_ORDER_PUSH',
    INN_FOR_ORDER_PUSH = 'INN_FOR_ORDER_PUSH',
    PROPOSAL_ID_FOR_DELETE = 'PROPOSAL_ID_FOR_DELETE',
    DELETE_BANK_FROM_BANK_LIST = 'DELETE_BANK_FROM_BANK_LIST',
    SET_REQUIRED_FIELDS = 'SET_REQUIRED_FIELDS',
    SET_IS_NEW_BASED_ON_OLD = 'SET_IS_NEW_BASED_ON_OLD'
}

interface SetRequiredFields {
    type: DataForOrderActionTypes.SET_REQUIRED_FIELDS;
    requiredFields: string[];
}

interface DeleteBankFromBankList {
    type: DataForOrderActionTypes.DELETE_BANK_FROM_BANK_LIST;
    proposalId: number;
}

interface SetProposalIdForDeleteAccordion {
    type: DataForOrderActionTypes.PROPOSAL_ID_FOR_DELETE;
    payload: DataForOrderState;
}

interface ChangeDataForOrder {
    type: DataForOrderActionTypes.DATA_FOR_ORDER_PUSH;
    payload: DataForOrderState;
}

interface ChangeDraftDataForOrder {
    type: DataForOrderActionTypes.DATA_FOR_DRAFT_ORDER_PUSH;
    payload: number[];
}

export type InnForOrderState = {
    inn: string
}

export interface PushDataForOrderAction {
    banksList: { name: string, id: number, bankName: string }[];
    inn: string;
}

export interface SetProposalIdForDeletePayload {
    proposalIdForDeleteAccordion: number | undefined;
}

interface ChangeInnForOrder {
    type: DataForOrderActionTypes.INN_FOR_ORDER_PUSH;
    payload: InnForOrderState;
}

interface ChangeIsNewBaseOnOld {
    type: DataForOrderActionTypes.SET_IS_NEW_BASED_ON_OLD;
    payload: boolean;
}

export type ChangeDataForOrderAction = SetProposalIdForDeleteAccordion | ChangeDataForOrder |
    ChangeInnForOrder | ChangeDraftDataForOrder |
    DeleteBankFromBankList | SetRequiredFields | ChangeIsNewBaseOnOld
