import React, { memo, useState, useEffect } from "react";
import { MenuItem, Typography, Collapse } from "@mui/material";
import DocumentModal from "./components/DocumentModal";
import CustomSnackBar from "./components/CustomSnackBar";
import classes from "./PartnerCurrentApplicationPage.module.scss";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import leadsService from "../../../core/services/LeadsService";
import introspectService from "../../../core/services/IntropsectService";
import { leadFullInfo } from "../../../core/types/leadsApiTypes";
import partnerService from "../../../core/services/PartnerService";
import { ROUTES } from "../../../core/consts/common";
import Status from "../../../components/components-product-page/status/Status";
import ConfirmModal from "../../../components/confirm-modal/ConfirmModal";
import ModalMain from "../../../UIcomponents/MainModal/ModalMain";
import ClientDetail from "../../../components/components-product-page/client-detail/ClientDetail";
import Notes from "../../../components/components-product-page/notes/Notes";
import noteService from "../../../core/services/NoteService";
import { CommonNotesInfoType, FrequencyUnitEnum } from "../../../core/api/noteApi";
import { MainTab, MainTabs } from "../../../UIcomponents/TabsMain";
import { dateConverterLongNoTime } from "../../../core/helpers/dateConverter";
import { TextFieldMain } from "../../../UIcomponents/TextFieldMain";
import { RollArrowIcon } from "../../../core/consts/images/icons";

const PartnerCurrentApplicationPage: React.FC = () => {
    const push = useNavigate();
    const [currentApplication, setCurrentApplication] = useState<null | leadFullInfo>(null);
    const { tab, leadId } = useParams();
    const [currentDocs] = useState<null | any>(null);
    const [requstStatus, setRequstStatus] = useState<boolean>(false);
    const [documentRequestStatus, setDocumentRequestStatus] = useState(false);
    const [archiveRequestStatus, setArchiveRequestStatus] = useState(false);
    const [currentTab, setTab] = useState(parseInt(tab?.toString() ?? "1"));
    const [messagesCount, setMessagesCount] = useState("0");
    const [isChangeStatusModal, setChangeStatusModal] = useState(false);
    const [isDocumentModal, setDocumentModal] = useState(false);
    const [isArchiveModal, setArchiveModal] = useState(false);
    const [showAllStatuses, setShowAllStatuses] = useState(false);
    const [, setShowButton] = useState(false);
    const [isErrorModal, setErrorModal] = useState(false);

    const [commonNotesInfo, setCommonNotesInfo] = useState<CommonNotesInfoType | null>(null);

    const getNotes = async () => {
        if (!leadId) return;
        const res = await noteService.getNotes(leadId);
        if (!res) {
            const commonNotesInfo: CommonNotesInfoType = {
                notes: [],
                notesCount: 0,
                isExpired: false,
                isToday: false,
            };
            setCommonNotesInfo(commonNotesInfo);
            return;
        }
        // const today = new Date();
        let isExpired = false;
        let isToday = false;
        res.forEach((elem) => {
            if (
                elem?.isNotificate &&
                elem?.frequencyUnit === FrequencyUnitEnum.no &&
                // && elem?.notificationStartDate && +(new Date(elem.notificationStartDate)) < +today) {
                elem?.isExpired === true
            ) {
                isExpired = true;
            }
            if (
                elem?.isNotificate &&
                elem?.frequencyUnit === FrequencyUnitEnum.no &&
                // && elem.notificationStartDate && !(elem?.notificationStartDate && +(new Date(elem.notificationStartDate)) < +today) && checkIsToday(elem.notificationStartDate)) {
                elem?.isToday === true
            ) {
                isToday = true;
            }
        });
        const commonNotesInfo: CommonNotesInfoType = {
            notes: res,
            notesCount: res.length,
            isExpired: isExpired,
            isToday: isToday,
        };
        setCommonNotesInfo(commonNotesInfo);
    };

    useEffect(() => {
        leadsService
            .getLeadByIdService(leadId!)
            .then((res) => {
                setCurrentApplication(res);
                setStatus(res.status);
            })
            .catch((error: any) => {
                introspectService.checkToken(error.response.status.toString());
                if (error.response.status === 403) {
                    setErrorModal(true);
                }
                console.log(error);
            });
    }, [requstStatus, documentRequestStatus, archiveRequestStatus, leadId]);

    useEffect(() => {
        if (leadId && +leadId !== 0) {
            getNotes();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [leadId]);

    const handleClickArchive = () => {
        partnerService
            .putLeadArchive(+leadId!)
            .then(() => {
                setArchiveModal(false);
                setArchiveRequestStatus(true);
            })
            .catch((err) => {
                setArchiveRequestStatus(false);
                console.log(err);
            });
    };

    const a11yProps = (index: number) => {
        return {
            id: `tab-${index}`,
            "aria-controls": `tabpanel-${index}`,
        };
    };

    const onChangeTab = (e: any, newValue: string) => {
        if (newValue === "1") {
            currentApplication ? setTab(parseInt(newValue)) : setDocumentModal(true);
        } else setTab(parseInt(newValue));
    };

    const handleClickTab = (value: number) => {
        value === 1 && setMessagesCount("0");
        setTab(value);
    };

    useEffect(() => {
        if (currentDocs) {
            const divShowMore = window.document.getElementById("showMore");
            if (divShowMore) {
                if (divShowMore.scrollHeight > divShowMore.clientHeight + 1) {
                    setShowButton(true);
                }
            }
        }
    }, [currentDocs, currentTab]);

    const routeToSupport = () => {
        push(`${ROUTES.PARTNER}/support`);
    };

    const routeToApplications = () => {
        push(`${ROUTES.PARTNER}/applications`);
    };
    const [status, setStatus] = useState("");
    const [submitDisable, setSubmitDisable] = useState(true);
    const handleChangeStatus = (event: any) => {
        setStatus(event.target.value as string);
        (event.target.value as string) === currentApplication?.status
            ? setSubmitDisable(true)
            : setSubmitDisable(false);
    };
    const [comment, setComment] = useState("");
    const handleChangeComment = (event: any) => setComment(event.target.value as string);

    const location = useLocation();
    const [statuses, setStatuses] = useState<null | any>(null);
    useEffect(() => {
        window.scrollTo(0, 0);
        leadsService.getStatuses().then((data) => {
            setStatuses(data);
        });
    }, []);

    const applicationId = location.pathname.split("/").pop();

    const hanleClickChangeStatus = (event: any) => {
        event.preventDefault();
        if (status) {
            partnerService
                .putLeadStatus(+applicationId!, status, comment)
                .then((res: any) => {
                    setRequstStatus(true);
                    setChangeStatusModal(false);
                })
                .catch((error: any) => {
                    setRequstStatus(false);
                });
        }
    };

    return (
        <>
            <div className={classes.container}>
                <Link to={`${ROUTES.PARTNER}/applications`} className={classes.link_back}>
                    <div>Назад к списку</div>
                </Link>
                <div className="page_title">Заявка #{currentApplication?.id}</div>
                {
                    <div>
                        {!showAllStatuses && (
                            <div className={classes.currentStatus__container}>
                                <div className={classes.data}>
                                    От &nbsp;
                                    {currentApplication?.dateAdd &&
                                        dateConverterLongNoTime(currentApplication?.dateAdd)}
                                    &nbsp; года
                                </div>
                                <div>{`[${currentApplication?.status}]`}</div>
                                <div
                                    className={classes.buttonCollapse}
                                    onClick={() => setShowAllStatuses((state) => !state)}
                                >
                                    <RollArrowIcon />
                                </div>
                            </div>
                        )}
                        <Collapse in={showAllStatuses} timeout="auto" unmountOnExit>
                            <div className={classes.btn}>
                                {currentApplication && (
                                    <Status stages={currentApplication.leadStatusHistory} />
                                )}
                            </div>
                            <div
                                className={classes.buttonCollapse}
                                onClick={() => setShowAllStatuses((state) => !state)}
                            >
                                Свернуть
                            </div>
                        </Collapse>
                    </div>
                }
                <div className={classes.btnsContainer}>
                    <div>
                        <MainTabs
                            value={currentTab}
                            aria-label="tabs"
                            onChange={onChangeTab}
                            className={classes.tabs}
                        >
                            {["Общая информация", "Заметки"].map((item, index) => (
                                <MainTab
                                    className={classes.tab}
                                    label={
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "4px",
                                            }}
                                            className="tab_main"
                                        >
                                            <div>{item}</div>
                                            {item === "Заметки" &&
                                            commonNotesInfo?.notesCount &&
                                            commonNotesInfo?.notesCount > 0 ? (
                                                <>
                                                    {commonNotesInfo?.isExpired ? (
                                                        <div className="redRound">
                                                            {commonNotesInfo?.notesCount}
                                                        </div>
                                                    ) : commonNotesInfo?.isToday ? (
                                                        <div className="yellowRound">
                                                            {commonNotesInfo?.notesCount}
                                                        </div>
                                                    ) : (
                                                        <div className="grayRound">
                                                            {commonNotesInfo?.notesCount}
                                                        </div>
                                                    )}
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                    }
                                    {...a11yProps(index)}
                                    onClick={() => handleClickTab(index)}
                                    key={item}
                                    {...a11yProps(index)}
                                />
                            ))}
                            {messagesCount && messagesCount !== "0" && (
                                <Typography
                                    variant="body2"
                                    component="span"
                                    className={classes.messagesCount}
                                >
                                    {messagesCount}
                                </Typography>
                            )}
                        </MainTabs>
                    </div>
                </div>
                {currentTab === 0 ? (
                    <div className={classes.mainButtonsContainer}>
                        <div>
                            {!currentApplication?.isArchive && (
                                <div className={classes.button_flex}>
                                    <button
                                        className={`button_black ${classes.buttonHover} ${classes.button_leads_act}`}
                                        onClick={() => setChangeStatusModal(true)}
                                    >
                                        Изменить статус
                                    </button>
                                    <button
                                        className={`button_outlined ${classes.buttonHover} ${classes.button_leads_act}`}
                                        onClick={() => setArchiveModal(true)}
                                    >
                                        Отправить в архив
                                    </button>
                                </div>
                            )}
                        </div>

                        <div className={classes.leftInfo}>
                            {currentApplication && (
                                <ClientDetail
                                    proposalsItems={currentApplication.groupFieldsProposalItems}
                                    fieldsArr={currentApplication.fieldsProposalItems}
                                    errorMessage={"Ошибка"}
                                    // setCopyStatus={setCopyStatus}
                                />
                            )}
                        </div>
                    </div>
                ) : (
                    leadId && (
                        <Notes
                            commonNotesInfo={commonNotesInfo}
                            getNotes={getNotes}
                            leadId={leadId}
                        />
                    )
                )}
            </div>
            {isChangeStatusModal && currentApplication && (
                <ModalMain
                    isOpen={isChangeStatusModal}
                    title="Изменение статуса"
                    nameSubmitButton={"Изменить"}
                    handleClose={() => {
                        setChangeStatusModal(false);
                        setStatus(currentApplication.status);
                    }}
                    formId="change-status-form"
                    submitDisable={submitDisable}
                >
                    <div className={classes.subTitle}>
                        Клиент увидит измененный статус в своем личном кабинете
                    </div>
                    <form action="" id="change-status-form" onSubmit={hanleClickChangeStatus}>
                        <fieldset style={{ border: "none", margin: "16px 0 0", padding: "0" }}>
                            <TextFieldMain
                                id="selectStatus"
                                select
                                onChange={handleChangeStatus}
                                required
                                value={status}
                                className={classes.dropdownModal}
                            >
                                {statuses &&
                                    statuses.map((el: any) => {
                                        return (
                                            <MenuItem key={el.key} value={el.text}>
                                                {el.text}
                                            </MenuItem>
                                        );
                                    })}
                            </TextFieldMain>
                            {status === "Отклонена" ? (
                                <TextFieldMain
                                    id="statusComment"
                                    placeholder="Комментарий"
                                    required
                                    onChange={handleChangeComment}
                                />
                            ) : null}
                        </fieldset>
                    </form>
                </ModalMain>
            )}
            {isDocumentModal && currentApplication ? (
                <DocumentModal
                    setDocumentModal={setDocumentModal}
                    isOpen={isDocumentModal}
                    leadId={currentApplication.id}
                    setDocumentRequestStatus={setDocumentRequestStatus}
                />
            ) : (
                <ConfirmModal
                    handleCancel={() => setDocumentModal(false)}
                    setModalProps={setDocumentModal}
                    title="Упс, что-то пошло не так"
                    cancelBtnText="Закрыть"
                    open={isDocumentModal}
                />
            )}
            {isArchiveModal && (
                <ModalMain
                    isOpen={isArchiveModal}
                    title="Отправить в архив"
                    handleClose={() => setArchiveModal(false)}
                    handleSubmit={handleClickArchive}
                    nameSubmitButton="В архив"
                >
                    <p>После архивирования заявки она будет недоступна для дальнейшей работы</p>
                </ModalMain>
            )}
            {requstStatus && (
                <CustomSnackBar
                    isOpen={requstStatus}
                    handleClose={() => setRequstStatus(false)}
                    title="Статус успешно изменен"
                    subTitle="Клиент увидит измененный статус в своем личном кабинете"
                />
            )}
            {documentRequestStatus && (
                <CustomSnackBar
                    isOpen={documentRequestStatus}
                    handleClose={() => setDocumentRequestStatus(false)}
                    title="Запрос успешно отправлен"
                    subTitle="Клиент получит уведомление в своем личном кабинете"
                />
            )}
            {archiveRequestStatus && (
                <CustomSnackBar
                    isOpen={archiveRequestStatus}
                    handleClose={() => setArchiveRequestStatus(false)}
                    title="Заявка архивирована"
                    subTitle="Теперь данная заявка находится в разделе «Архив»"
                />
            )}
            {isErrorModal && (
                <ConfirmModal
                    handleConfirm={routeToApplications}
                    title="Недостаточно прав для просмотра этой страницы"
                    handleCancel={routeToSupport}
                    confBtnText="К заявкам"
                    cancelBtnText="В техподдержку"
                    isExtraCloseModal
                    open={isErrorModal}
                />
            )}
        </>
    );
};

export default memo(PartnerCurrentApplicationPage);
