import React from "react";
import { useState } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import styles from "./Note.module.scss";
import NoteView from "./NoteView";
import NoteCard from "./NoteCard";
import {
  CommonNotesInfoType,
  // DaysOfWeekEnum,
  // FrequencyUnitEnum,
  NoteType,
  // NotificationTypeEnum,
} from "../../../core/api/noteApi";
import { Collapse } from "@mui/material";
import { COLORS } from "../../../core/consts/common";

// const test: NoteType = {
//   id: 178,
//   leadId: 2946,
//   text: "eeeeee",
//   creationDate: "2023-07-18 16:51:29Z",
//   isNotificate: true,
//   notificationStartDate: "2023-07-19",
//   actualNotificatiionDateTime: "2023-07-19 10:00:00Z",
//   frequencyUnit: FrequencyUnitEnum.week,
//   frequencyCount: 1,
//   minutes: 0,
//   hours: 10,
//   days: [DaysOfWeekEnum.Mo],
//   notificateType: NotificationTypeEnum.emailPortal,
//   isExpired: false,
//   isToday: false,
// };

type Props = {
  commonNotesInfo: CommonNotesInfoType | null;
  getNotes: () => Promise<void>;
  leadId: string;
};

const Notes: React.FC<Props> = ({ commonNotesInfo, getNotes, leadId }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [chosenNote, setChosenNote] = useState<NoteType | null>(null);
  return (
    <>
      {commonNotesInfo && commonNotesInfo?.notesCount < 10 && (
        <>
          <Collapse in={!isEdit}>
            <button
              className={styles.button_create_note}
              onClick={() => {
                setChosenNote(null);
                setIsEdit(true);
              }}
            >
              Добавить напоминание
              <ArrowForwardIosIcon
                style={{ color: COLORS.MAIN_TEXT, width: "15px" }}
              />
            </button>
          </Collapse>
          <Collapse in={isEdit} style={{ marginTop: "-24px" }}>
            <NoteView
              setIsEdit={setIsEdit}
              leadId={leadId}
              getNotes={getNotes}
            />
          </Collapse>
          <div className="divider" />
        </>
      )}

      {commonNotesInfo?.notes.map((elem, index) => (
        <div key={elem?.id}>
          <Collapse in={Boolean(chosenNote && chosenNote.id === elem.id)}>
            <NoteView
              note={elem}
              setChosenNote={setChosenNote}
              setIsEdit={setIsEdit}
              getNotes={getNotes}
            />
          </Collapse>

          <Collapse in={Boolean(!(chosenNote && chosenNote.id === elem.id))}>
            <NoteCard
              note={elem}
              setChosenNote={setChosenNote}
              setIsEditNew={setIsEdit}
              getNotes={getNotes}
            />
          </Collapse>
        </div>
      ))}

      {/* <Collapse in={true}>
        <NoteCard
          note={test}
          setChosenNote={setChosenNote}
          setIsEditNew={setIsEdit}
          getNotes={getNotes}
        />
      </Collapse> */}
    </>
  );
};

export default Notes;
