import { styled } from "@mui/material/styles";
import TextField, {
  TextFieldProps,
} from "@mui/material/TextField";
import { OutlinedInputProps } from "@mui/material/OutlinedInput";
import { COLORS } from "../core/consts/common";
import { DatePicker } from "@mui/x-date-pickers";
import EventNoteIcon from "@mui/icons-material/EventNote";

export const TextFieldMainForPhone = (props: TextFieldProps) => {
  return (
    <TextFieldMain
      InputProps={{ disableUnderline: true } as Partial<OutlinedInputProps>}
      {...props}
      variant="filled"
    />
  );
};

export const TextFieldMain = styled((props: TextFieldProps) => (
  <TextField
    InputProps={{ disableUnderline: true } as Partial<OutlinedInputProps>}
    {...props}
  />
))(({ theme, value }) => ({
  width: "100%",
  "& label": {
    fontFamily: "Inter",
    color: COLORS.SUB_TEXT,
    marginTop: "3px",

    span: {
      color: value ? COLORS.SUB_TEXT : "red",
      // color: 'red',
    },
    svg: {
      color: "red",
    },
  },

  "& label.Mui-focused": {
    color: COLORS.SUB_TEXT,
    span: {
      color: COLORS.SUB_TEXT,
    },
  },
  "& label.": {
    color: COLORS.SUB_TEXT,
    span: {
      color: COLORS.SUB_TEXT,
    },
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "12px",
    height: "64px",
    fontFamily: "Inter",
    backgroundColor: "#FFF",
    // border: '1px solid #CCCCCC',
    border: "none",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    "&:hover": {
      // border: `1px solid ${COLORS.SUB_TEXT}`,
      border: "none",
    },
    "&.Mui-focused": {
      // border: `1px solid ${COLORS.MAIN_TEXT}`,
      border: "none",
    },

    "& fieldset": {
      border: `1px solid ${COLORS.BORDER}`,
    },
    "&:hover fieldset": {
      border: `1px solid ${COLORS.SUB_TEXT}`,
    },
    "&.Mui-focused fieldset": {
      border: `1px solid ${COLORS.MAIN_TEXT}`,
    },
    "&:disabled fieldset": {
      border: `1px solid ${COLORS.DIVIDER}`,
      backgroundColor: COLORS.BORDER,
    },
    "& input:invalid + fieldset": {
      borderColor: "red",
      borderWidth: 1,
    },
    "&.Mui-disabled": {
      backgroundColor: COLORS.DIVIDER,
      border: `1px solid ${COLORS.BORDER}`,
    },
    "&.Mui-error": {
      border: `1px solid ${COLORS.ERROR}`,
    },
  },
  "& .MuiFilledInput-root": {
    // '& .MuiInputBase-input': {
    overflow: "hidden",
    borderRadius: "12px",
    height: "64px",
    fontFamily: "Inter",
    backgroundColor: "#FFF",
    border: "1px solid #CCCCCC",
    // svg: {
    //   fill: "red",
    // },
    // button: {
    //   color: "red",
    // },

    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    "&:hover": {
      backgroundColor: "transparent",
      border: `1px solid ${COLORS.SUB_TEXT}`,
    },
    "&.Mui-focused": {
      backgroundColor: "transparent",
      borderColor: COLORS.MAIN_TEXT,
    },
    "&.Mui-disabled": {
      backgroundColor: COLORS.DIVIDER,
      border: `1px solid ${COLORS.BORDER}`,
    },
    "&.Mui-error": {
      border: `1px solid ${COLORS.ERROR}`,
    },
    "&::placeholder": {
      color: "red",
    },
    "&::label": {
      color: "red",
    },
  },
  "&::placeholder": {
    // textOverflow: 'ellipsis !important',
    color: "red",
  },
}));

export const TextFieldDate = styled((props: any) => (
  <DatePicker
    InputProps={{ disableUnderline: true } as Partial<OutlinedInputProps>}
    slotProps={{
      textField: {
        // helperText: 'MM/DD/YYYY',
        disableUnderline: true,
      },
    }}
    IconComponent={EventNoteIcon}
    keyboardIcon={EventNoteIcon}
    openPickerIcon={EventNoteIcon}
    disableUnderline={true}
    outline="none"
    {...props}
  />
))(({ theme, value }) => ({
  width: "100%",
  "& .MuiInputBase-input": {
    border: "none",
  },
  "& label": {
    fontFamily: "Inter",
    color: COLORS.SUB_TEXT,
    marginTop: "3px",

    span: {
      color: value ? COLORS.SUB_TEXT : "red",
      // color: 'red',
    },
  },

  "& label.Mui-focused": {
    color: COLORS.SUB_TEXT,
    span: {
      color: COLORS.SUB_TEXT,
    },
  },
  "& label.": {
    color: COLORS.SUB_TEXT,
    span: {
      color: COLORS.SUB_TEXT,
    },
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "12px",
    height: "64px",
    fontFamily: "Inter",
    backgroundColor: "#FFF",
    // border: '1px solid #CCCCCC',
    border: "none",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    "&:hover": {
      // border: `1px solid ${COLORS.SUB_TEXT}`,
      // border: "none",
    },
    "&.Mui-focused": {
      // border: `1px solid ${COLORS.MAIN_TEXT}`,
      border: "none",
    },

    "& fieldset": {
      border: `1px solid ${COLORS.BORDER}`,
      // border: `none`,
    },
    "&:hover fieldset": {
      border: `1px solid ${COLORS.SUB_TEXT}`,
    },
    "&.Mui-focused fieldset": {
      border: `1px solid ${COLORS.MAIN_TEXT}`,
    },
    "&:disabled fieldset": {
      border: `1px solid ${COLORS.DIVIDER}`,
      backgroundColor: COLORS.BORDER,
    },
    "& input:invalid + fieldset": {
      borderColor: "red",
      borderWidth: 1,
    },
    "&.Mui-disabled": {
      backgroundColor: COLORS.DIVIDER,
      border: `1px solid ${COLORS.BORDER}`,
    },
    "&.Mui-error": {
      border: `1px solid ${COLORS.ERROR}`,
    },
    "&MuiFilledInput-underline": {
      border: "none",
    },
  },

  "& .MuiFilledInput-root": {
    // '& .MuiInputBase-input': {
    overflow: "hidden",
    borderRadius: "12px",
    height: "64px",
    fontFamily: "Inter",
    backgroundColor: "#FFF",
    border: "1px solid #CCCCCC",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    "&::before": { borderBottom: "none" },
    "&::after": { borderBottom: "none" },
    "&:hover": {
      // backgroundColor: "transparent",
      // border: `1px solid ${COLORS.SUB_TEXT}`,
      // borderBottom: "none",
      // borderBottom: "1px solid #CCCCCC",
      // "&::before": { borderBottom: "1px solid #CCCCCC" },
      // "&::after": { borderBottom: "1px solid #CCCCCC" },
      // "&::before": { borderBottom: "none" },
      // "&::after": { borderBottom: "none" },
      borderColor: COLORS.SUB_TEXT,
      borderWidth: "1px",
      backgroundColor: COLORS.BACKGROUND,
    },
    "&.Mui-focused": {
      backgroundColor: "transparent",
      borderColor: COLORS.MAIN_TEXT,
      border: `1px solid ${COLORS.MAIN_TEXT}`,
    },
    "&.Mui-disabled": {
      backgroundColor: COLORS.DIVIDER,
      border: `1px solid ${COLORS.BORDER}`,
    },
    "&.Mui-error": {
      border: `1px solid ${COLORS.ERROR}`,
    },
    "&::placeholder": {
      color: "red",
    },
    "&::label": {
      color: "red",
    },
    "&:hover fieldset": {
      //undeline delete
      // border: `1px solid ${COLORS.SUB_TEXT}`,
    },
  },
  "& .MuiFilledInput-underline": {
    backgroundColor: "#FFF",
    //undeline delete
    "&:before": {
      borderBottomColor: "transparent",
    },
    "&:hover:not(.Mui-disabled)::before": {
      borderBottomColor: "transparent",
    },
  },
  "&::placeholder": {
    // textOverflow: 'ellipsis !important',
    color: "red",
  },
}));
