import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { notificationType } from "../../../core/api/notificationsApi";
import { dateConverterLong } from "../../../core/helpers/dateConverter";
import classes from "./Notifications.module.scss";
import ModalMain from "../../../UIcomponents/MainModal/ModalMain";
import notifcationsService from "../../../core/services/NotificationService";
import CustomSnackBar from "../partner-current-application/components/CustomSnackBar";

type PropsType = {
  notification: notificationType;
  refresh: boolean;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
};

const NotificationItem = ({ notification, refresh, setRefresh }: PropsType) => {
  const navigate = useNavigate();
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [isDeleteSuccess, setIsDeleteSuccess] = useState(false);
  const [isDeleteError, setIsDeleteError] = useState(false);

  const handleDelete = async () => {
    // try {
    const res = await notifcationsService.deleteNotification(
      notification.id.toString()
    );
    if (res) {
      setIsDeleteSuccess(true);
      setTimeout(() => {
        setRefresh(!refresh);
      }, 1000);
    } else {
      setIsDeleteError(true);
    }
    setIsOpenDeleteModal(false);
    // } catch (e) {
    //   console.error(e);
    //   setIsDeleteError(true);
    // } finally {
    //   setIsOpenDeleteModal(false);
    // }
  };

  return (
    <div className={classes.note_item_box}>
      <div className={classes.date}>
        {dateConverterLong(notification.dateAdd)}
      </div>

      <div className={classes.flex}>
        <div className="yellowRoundSmall" />
        <div className={classes.note_title}>
          Заявка #{notification.leadId} | Напомнить{" "}
          {dateConverterLong(notification.dateAdd)}
        </div>
      </div>

      <div>{notification.text}</div>

      <div className={classes.flex} style={{ marginTop: "4px" }}>
        <button
          onClick={() =>
            navigate(`/partner/application/0/${notification.leadId}`)
          }
          className={`button_black_s ${classes.butB}`}
        >
          Перейти к заявке
        </button>
        <button
          onClick={() => setIsOpenDeleteModal(true)}
          className={`button_white_s ${classes.butB}`}
        >
          Удалить
        </button>
      </div>

      {/* ***********delete********* */}
      <ModalMain
        title={`Подтвердите удаление уведомления:`}
        isOpen={isOpenDeleteModal}
        handleClose={() => {
          setIsOpenDeleteModal(false);
        }}
        handleSubmit={handleDelete}
      >
        <div>{notification.text}</div>
      </ModalMain>

      {/* *****toast*************** */}
      {isDeleteSuccess && (
        <CustomSnackBar
          isOpen={isDeleteSuccess}
          handleClose={() => setIsDeleteSuccess(false)}
          title={"Удаление уведомления"}
          subTitle={"Уведомление успешно удалено"}
        />
      )}
      {isDeleteError && (
        <CustomSnackBar
          isOpen={isDeleteError}
          handleClose={() => setIsDeleteError(false)}
          title={"Удаление уведомления"}
          subTitle={"Ошибка удаления уведомления"}
        />
      )}
    </div>
  );
};

export default NotificationItem;
