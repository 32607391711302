import notificationsApi from "../api/notificationsApi";
import { notificationsListType } from "../api/notificationsApi";
import introspectService from "./IntropsectService";

class NotificationService {
  getNotificationsCount(): Promise<{ count: number }> {
    return notificationsApi
      .getNotificationsCount()
      .then((res: any) => {
        return res;
      })
      .catch((error) => {
        introspectService.checkToken(error.response.status.toString());
      });
  }

  getNotificationsListService(Q: {
    count?: number;
    offset?: number;
    onlyUnread?: boolean;
  }): Promise<notificationsListType> {
    return notificationsApi
      .getNotificationsList(Q)
      .then((res: any) => {
        return res;
      })
      .catch((error) => {
        introspectService.checkToken(error.response.status.toString());
      });
  }

  readNotification(id: string) {
    return notificationsApi
      .readNotification(id)
      .then((res: any) => {
        return res;
      })
      .catch((error) => {
        introspectService.checkToken(error.response.status.toString());
      });
  }

  deleteNotification(id: string) {
    return notificationsApi
      .deleteNotification(id)
      .then((res: any) => {
        return res;
      })
      .catch((error) => {
        introspectService.checkToken(error.response.status.toString());
      });
  }
}

const notifcationsService = new NotificationService();
export default notifcationsService;
