import {
  Button,
  Dialog,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import CloseIcon from '@mui/icons-material/Close';
import DocumentModalForm from "./DocumentModalForm";
import classes from "../PartnerCurrentApplicationPage.module.scss"
import { documentType } from "../../../../core/types/partnerApiTypes";
import partnerService from "../../../../core/services/PartnerService";

type propsType = {
  isOpen: boolean;
  setDocumentModal: (isModal: boolean) => void;
  setDocumentRequestStatus: (
    prev: boolean | ((prev: boolean) => boolean)
  ) => void;
  leadId: any;
};


const DocumentModal: React.FC<propsType> = ({
  isOpen,
  setDocumentModal,
  setDocumentRequestStatus,
  leadId,
}) => {
  const [errMessage, setErrMessage] = useState<string>();
  const handleClose = () => {
    setDocumentModal(false);
  };

  const [docsList, setDocsList] = useState<documentType[]>([
    {
      fileName: "",
      Comment: "",
      isFileNeeded: true,
      isSignatureRequired: false,
    },
  ]);

  const [isBtnActive, setBtnActive] = useState(true);

  useEffect(() => {
    let isActive = true;
    docsList.forEach((el) => {
      if (
        (!el.fileName && !el.doc && el.isFileNeeded) ||
        (!el.fileName && el.doc && el.isFileNeeded) ||
        (el.fileName && el.isFileNeeded && !el.doc) ||
        !el.fileName
      ) {
        isActive = false;
      }
    });
    if (isActive) setBtnActive(true);
    if (!isActive) setBtnActive(false);
  }, [docsList]);

  const handleClickAddDoc = () => {
    setDocsList((state) => [
      ...state,
      {
        fileName: "",
        Comment: "",
        isFileNeeded: true,
        isSignatureRequired: false,
      },
    ]);
  };

  const handleChangeData = (i: number, newDocsList: any) => {
    console.log(newDocsList);
    setDocsList((docsList) => {
      docsList[i] = newDocsList;
      return [...docsList];
    });
  };

  const handleDeleteDocForm = (id: number) => {
    const newDocsList = docsList.filter((el, key) => key !== id);
    setDocsList(newDocsList);
  };

  const successProcessing = () => {
    setDocumentModal(false);
    setDocumentRequestStatus(true);
  };

  const handleSendDocsRequest = () => {
    docsList.forEach(async (el) => {
      try {
        let baseDocumentId = await partnerService.postLeadFileService(
          el,
          leadId
        );

        el.isFileNeeded
          ? partnerService
            .attachFileExampleService(parseInt(baseDocumentId), false, el)
            .then((res) => {
              successProcessing();
            })
            .catch((error) => {
              setErrMessage(error.response.data);
            })
          : successProcessing();

      } catch {
        setErrMessage("Что-то пошло не так");
      }
    });
  };

  return (
    <Dialog onClose={handleClose} maxWidth={false} open={isOpen}>
      <div className={classes.container}>
        <div className={classes.title}>Запрос документа</div>
        <IconButton
          style={{ position: "absolute", top: "33px", right: "50px" }}
          aria-label="close"
          onClick={handleClose}
        >
          <CloseIcon style={{ color: "#D0D9DE" }} />
        </IconButton>
        <Typography variant="body1" component="p" className={classes.subTitle}>
          Здесь вы можете сообщить клиенту о том, какие действия и с какими
          документами необходимо совершить
          <Typography
            variant="body2"
            component="span"
            style={{
              color: "#D62D30",
              position: "absolute",
              left: "0",
              bottom: "-20px",
            }}
          >
            {errMessage}
          </Typography>
          .
        </Typography>

        {docsList.map((el, key) => {
          return (
            <DocumentModalForm
              key={key}
              docsAmount={docsList.length}
              handleDeleteDocForm={handleDeleteDocForm}
              handleChangeData={handleChangeData}
              id={key}
              el={el}
            />
          );
        })}

        <Button
          color={"primary"}
          variant="text"
          className={classes.btnText}
          onClick={handleClickAddDoc}
        >
          <Typography
            variant={"subtitle2"}
            component={"span"}
            className={classes.textDoc}
          >
            Добавить документ
          </Typography>
        </Button>
        <div>
          <Button
            color={"primary"}
            className={classes.changeStatusBtn}
            variant="contained"
            onClick={handleSendDocsRequest}
            disabled={!isBtnActive}
          >
            <Typography
              variant={"subtitle2"}
              className={classes.text}
              component={"span"}
              style={{ fontSize: "16px" }}
            >
              Отправить
            </Typography>
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default DocumentModal;
