import { SVGProps } from "react";

type Props = SVGProps<SVGSVGElement>;

export const Unchecked = (props: Props) => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <rect x="0.5" y="0.5" width="23" height="23" rx="7.5" stroke="#CCCCCC" />
        </svg>
    );
};
