import { styled } from '@mui/material/styles';
import TextField, { TextFieldProps } from '@mui/material/TextField';

import { OutlinedInputProps } from '@mui/material/OutlinedInput';
import { COLORS } from '../core/consts/common';

export const TextFieldTransparent = styled((props: TextFieldProps) => (
  <TextField
    InputProps={{ disableUnderline: true } as Partial<OutlinedInputProps>}
    {...props}
  />
))(({ theme, value }) => ({
  width: "100%",
  '& .MuiInputBase-input': {
    overflow: 'hidden',
    padding: "5px 5px 5px 5px",
    fontFamily: "Inter",
    color: COLORS.ACCEPT,
    backgroundColor: '#FFF',
    border: 'none',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&:hover': {
      backgroundColor: '#FFF',
      borderColor: 'transparent',
    },
    '&.Mui-focused': {
      backgroundColor: '#FFF',
      borderColor: 'transparent',
    },
  },
}));