import { createTheme } from "@mui/material";
import { COLORS } from "./common";

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 480,
      md: 600,
      lg: 1280,
      xl: 1600,
    },
  },
  palette: {
    primary: {
      main: COLORS.ACCEPT,
      light: "#C0D3E0",
      dark: "#204A66",
      contrastText: "#ffffff",
      "900": "#0d47a1",
    },
    info: {
      main: "#ffffff",
      light: "#C0D3E0",
      dark: "#214D6B",
      contrastText: "#ffffff",
      "900": "#0d47a1",
    },
    secondary: {
      main: "#131313",
      light: "#F2F2F2",
      dark: "#050505",
      contrastText: "#ffffff",
    },
    error: {
      main: "#D62D30",
      light: "#F2C0C0",
      dark: "#400D0E",
      contrastText: "#ffffff",
    },
    success: {
      main: "#69A042",
      light: "#C3D9B3",
      dark: "#2A401A",
      contrastText: "#ffffff",
    },
  },
  typography: {
    h1: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "3rem", //48
      lineHeight: "1.2",
      color: "#131313",
    },
    h2: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontSize: "2rem", //32
      fontWeight: 700,
      lineHeight: "1.2",
      color: "#131313",
    },
    h3: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontSize: "24px", //24
      fontWeight: 700,
      lineHeight: "1.2",
      color: "#131313",
    },
    h4: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontSize: "18px", //18
      fontWeight: 600,
      lineHeight: "1.3",
      color: "#131313",
    },
    h5: {
      fontFamily: "Gotham Pro",
      fontStyle: "normal",
      fontSize: "1.13rem", //18
      fontWeight: "normal",
      lineHeight: "1.3",
      color: "#131313",
    },
    subtitle1: {
      fontFamily: "Gotham Pro",
      fontStyle: "normal",
      fontSize: "18px", //18
      fontWeight: "normal",
      lineHeight: "1.3",
      color: "#131313",
    },
    subtitle2: {
      fontFamily: "Gotham Pro",
      fontStyle: "normal",
      fontSize: "1rem", //16
      fontWeight: "normal",
      lineHeight: "1.3",
    },
    body1: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontSize: "16px",
      fontWeight: "normal",
      lineHeight: "1.3",
      color: "#131313",
    },
    body2: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontSize: "14px",
      fontWeight: 300,
      lineHeight: "1.3",
      color: "#131313",
    },
  },
  // overrides: {
  //   MuiTooltip: {
  //     tooltip: {
  //       fontSize: '16px',
  //       fontFamily: 'Gotham Pro',
  //       fontWeight: 'normal',
  //       backgroundColor: 'rgba(19, 19, 19, 0.5)',
  //       borderRadius: '2px',
  //       color: '#ffffff',
  //     }
  //   }
  // }

  components: {
    MuiTextField: {
      variants: [
        {
          props: { variant: "outlined" },
          style: {
            fontFamily: "Inter",
            color: COLORS.SUB_TEXT,
            marginTop: "3px",
            borderRadius: "12px",
            height: "64px",
            border: "none",
          },
        },
        {
          props: { variant: "filled" },
          style: {
            // border: `1px solid ${COLORS.BORDER}`,
            // color: "red",
            // marginTop: "3px",
            // borderRadius: "12px",
            // height: "64px",
            // fontFamily: "Inter",
            // backgroundColor: COLORS.ACCEPT,

            // span: {
            //   color: "red",
            // },
          },
        },
      ],
    },
  },
});
