import {
  CurrentUserActions,
  CurrentUserState,
  CurrentUserTypes,
} from "../../types/currentUser";

const initialState: CurrentUserState = {
  userId: "",
  isAuth: false,
  inn: "",
  phone: "",
  role: "",
  manager: null,
  nameFull: "",
  email: "",
  is_manager_approval: undefined,
  status: undefined,
  code: '',
};

export const currentUserReducer = (
  state = initialState,
  action: CurrentUserActions
) => {
  switch (action.type) {
    case CurrentUserTypes.SET_USER:
      return {
        ...state,
        ...action.payload,
      };
    case CurrentUserTypes.SET_CODE:
      return {
        ...state, code: action.payload
      }
    default:
      return state;
  }
};
