import React, { memo } from "react";
import { MenuItem } from "@mui/material";
import classes from "../../PartnerApplicationPage.module.scss";
import * as selectors from "../../../../../core/store/selectors";
import { TextFieldMain } from "../../../../../UIcomponents/TextFieldMain";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { actions } from "../../../../../core/store";
import { useSelector } from "react-redux";

type PropsType = {
    statusList: string[] | undefined;
    productsList: string[] | undefined;
};

const Search: React.FC<PropsType> = (props) => {
    const filters = useSelector(selectors.partnerAplication.filtersFields);

    const handleStatusChange = (e: any) => {
        actions.setFilterValue({
            field: "status",
            value: e.target.value,
        });
        actions.setCurrentPage(1);
    };

    const handleProductChange = (e: any) => {
        actions.setFilterValue({
            field: "product",
            value: e.target.value,
        });
        actions.setFilterValue({
            field: "status",
            value: "all",
        });
        actions.setCurrentPage(1);
    };

    const handleInnChange = (e: any) => {
        if (e.target.value.match(/\d*$/) !== null) {
            actions.setFilterValue({
                field: "inn",
                value: e.target.value,
            });
            actions.setCurrentPage(1);
        }
    };

    return (
        <div className={classes.filter_container}>
            <div className={classes.filter_box}>
                <div className={classes.filter_item}>
                    <TextFieldMain
                        variant="filled"
                        label="Название компании или ИНН Клиента"
                        value={filters.inn}
                        onChange={handleInnChange}
                    />
                </div>
                <div className={classes.filter_item}>
                    <TextFieldMain
                        variant="filled"
                        label="Вид продукта"
                        select
                        SelectProps={{
                            IconComponent: KeyboardArrowDownIcon,
                        }}
                        value={filters.product}
                        onChange={handleProductChange}
                    >
                        <MenuItem value={"all"}>Все продукты</MenuItem>
                        {props.productsList &&
                            props.productsList.map((product, index) => {
                                return (
                                    <MenuItem key={index} value={index}>
                                        {product}
                                    </MenuItem>
                                );
                            })}
                    </TextFieldMain>
                </div>
            </div>

            <div className={classes.filter_box}>
                <div className={classes.filter_item}>
                    <TextFieldMain
                        variant="filled"
                        select
                        SelectProps={{
                            IconComponent: KeyboardArrowDownIcon,
                        }}
                        label="Статус заявки"
                        value={
                            props.statusList?.indexOf(filters.status) === -1
                                ? "all"
                                : filters.status
                        }
                        onChange={handleStatusChange}
                    >
                        <MenuItem value={"all"}>Все статусы</MenuItem>
                        {props.statusList &&
                            props.statusList.map((status) => (
                                <MenuItem key={status} value={status}>
                                    {status}
                                </MenuItem>
                            ))}
                    </TextFieldMain>
                </div>
                <div className={classes.filter_item}>
                    <TextFieldMain
                        variant="filled"
                        select
                        SelectProps={{
                            IconComponent: KeyboardArrowDownIcon,
                        }}
                        label="Время создания заявки"
                        value={filters.period}
                        onChange={(e: any) =>
                            actions.setFilterValue({
                                field: "period",
                                value: e.target.value,
                            })
                        }
                    >
                        <MenuItem value={"all"}>За все время</MenuItem>
                        <MenuItem key="day" value={"day"}>
                            За день
                        </MenuItem>
                        <MenuItem key="week" value={"week"}>
                            За неделю
                        </MenuItem>
                        <MenuItem key="month" value={"month"}>
                            За месяц
                        </MenuItem>
                        <MenuItem key="year" value={"year"}>
                            За год
                        </MenuItem>
                    </TextFieldMain>
                </div>
            </div>
        </div>
    );
};

export default memo(Search);
