export interface AuthState {
  access_token: string;
  expires_in: number;
  scope: string;
  refresh_token: string;
  token_type: string;
  addInfo: {
    hasInn: boolean;
    code: string;
    isPhoneCheck: boolean;
    role: string;
    userType: 'ul' | 'fl' | '';
  };
  isOpenAddInnModal: boolean;
}

export enum AuthActionTypes {
  SIGN_IN = "SIGN_IN",
  SET_AUTH = "SET_AUTH",
  SIGH_UP = "SIGH_UP",
  SET_AUTH_INFO = "SET_AUTH_INFO",
  SET_IS_OPEN_ADDINN_MODAL = "SET_IS_OPEN_ADDINN_MODAL",
}

export type SetAuthInfoPayload = {
  hasInn: boolean;
  code: string;
  isPhoneCheck: boolean;
  userType: 'ul' | 'fl' | '';
};

interface FetchSignIn {
  type: AuthActionTypes.SIGN_IN;
  payload?: any;
  data?: AuthState;
}

interface FetchSetAuth {
  type: AuthActionTypes.SET_AUTH;
  payload: any[];
}

interface FetchSignUp {
  type: AuthActionTypes.SIGH_UP;
  payload: string;
}

interface SetAuthInfo {
  type: AuthActionTypes.SET_AUTH_INFO;
  payload: SetAuthInfoPayload;
}

interface SetIsOpenAddInnModal {
  type: AuthActionTypes.SET_IS_OPEN_ADDINN_MODAL;
  payload: boolean;
}

export type AuthAction = FetchSignIn | FetchSetAuth | FetchSignUp | SetAuthInfo | SetIsOpenAddInnModal;
