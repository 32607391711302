import clsx from "clsx";
import styles from "./Card.module.css";

export type Props = {
    title: React.ReactNode;
    text: string;
    className?: string;
    classNameText?: string;
    classNameTitle?: string;
};

export function Card(props: Props) {
    return (
        <div className={clsx(styles.base, props.className)}>
            <div className={clsx(styles.title, styles.classNameTitle)}>{props.title}</div>
            <div className={clsx(styles.text, props.classNameText)}>{props.text}</div>
        </div>
    );
}
