import React, { memo, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import classes from "./Preloader.module.scss"


type PreloaderType = 'absolute' | 'block' | 'fixed'

type Styles = {
    preloader: string,
    loader: string
}

type Props = {
    preloaderType: PreloaderType
    sizePixels?: string
}

const Preloader: React.FC<Props> = ({ preloaderType, sizePixels = '150px' }) => {
    const [styles, setStyles] = useState<Styles>({
        preloader: classes.preloaderAbsolute,
        loader: classes.loaderAbsolute
    })

    const checkPreloaderType = (preloaderType: PreloaderType) => {
        switch (preloaderType) {
            case 'fixed':
                return {
                    preloader: classes.preloaderFixed,
                    loader: classes.loaderAbsolute
                }
            case 'absolute':
                return {
                    preloader: classes.preloaderAbsolute,
                    loader: classes.loaderAbsolute
                }
            case 'block':
                return {
                    preloader: classes.preloaderBlock,
                    loader: classes.loaderBlock
                }
        }
    }

    useEffect(() => {
        setStyles(checkPreloaderType(preloaderType))
    }, [preloaderType])

    return (
        <Box className={styles.preloader}>
            <Box className={styles.loader} style={{ width: sizePixels, height: sizePixels }}>

            </Box>
        </Box>
    );
};

export default memo(Preloader)