import styles from "./layoutHome.module.scss";
import Logo from "../../images/logo.svg";
import { useNavigate } from "react-router-dom";
import { COLORS, FormType } from "../../core/consts/common";
import clsx from "clsx";
import useWindowDimensions from "../../core/hooks/useWindowDimensions";

const HeaderHome = ({ isModal }: { isModal?: boolean }) => {
    const navigate = useNavigate();
    const { width } = useWindowDimensions();

    const handleLogin = () => {
        const isRestrationed = window.localStorage.getItem("isRegistration");
        if (isRestrationed) {
            navigate({
                pathname: window.location.pathname,
                search: `?formType=${FormType.LoginFirstEmail}`,
            });
            return;
        }

        navigate({
            pathname: window.location.pathname,
            search: `?formType=${FormType.LoginFirstPhone}`,
        });
    };

    return (
        <div
            className={clsx(styles.containerBlock, styles.header)}
            style={{ backgroundColor: isModal ? COLORS.ACCEPT : "" }}
        >
            <div>
                <img src={Logo} alt="" />
            </div>
            <div onClick={handleLogin} className={styles.enter}>
                {width < 1022 ? "Вход" : "Вход в кабинет"}
            </div>
        </div>
    );
};

export default HeaderHome;
