import { IconButton, Snackbar } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
import classes from "../PartnerCurrentApplicationPage.module.scss"

type propsType = {
  isOpen: boolean;
  handleClose: () => void;
  title: string;
  subTitle: string;
}

const CustomSnackBar: React.FC<propsType> = ({ isOpen, handleClose, title, subTitle }) => {
  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={3000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      sx={{ width: '100%' }}>
      <div
        className={classes.snackBar}
      >
        <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <div className={classes.box}>
            <h2
              style={{ color: "#242424", margin: 0 }}
            >
              {title}
            </h2>
          </div>
          <p style={{ color: "#636363", margin: 0 }}>
            {subTitle}
          </p>

        </div>
        <IconButton
          style={{ width: '24px', height: '24px', margin: '-16px -16px 0 0' }}
          aria-label="close"
          onClick={handleClose}
        >
          <CloseIcon style={{ color: "#242424", width: '24px', height: '24px' }} />
        </IconButton>
      </div>
    </Snackbar>
  )
}

export default CustomSnackBar;
