import {
  AuthActionTypes,
  AuthState,
  SetAuthInfoPayload,
} from "../../types/auth";

export const fetchAuth = (payload: AuthState) => {
  return { type: AuthActionTypes.SIGN_IN, payload };
};

export const setAuthInfo = (payload: SetAuthInfoPayload) => {
  return { type: AuthActionTypes.SET_AUTH_INFO, payload };
};

export const setIsOpenInnModal = (payload: boolean) => {
  return { type: AuthActionTypes.SET_IS_OPEN_ADDINN_MODAL, payload };
};
