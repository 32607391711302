import React from "react";

export type Scrolls = {
    scrollY: number | null;
    scrollX: number | null;
};

export function useTrackingScroll() {
    const [scroll, setScroll] = React.useState<Scrolls>({
        scrollY: null,
        scrollX: null,
    });

    const handleScroll = (e: any) => {
        setScroll({ scrollY: window.scrollY, scrollX: window.scrollX });
    };

    React.useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    return scroll;
}
