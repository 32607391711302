export type BigFormState = {
    errorsFieldsArray: string[]
    isDisplay: boolean
    propsalsId: number[]
}

export type FetchUpdateErrorsArrayPayload = {
    errorsFieldsArray: string[]
}

export type SetIsDisplayInAccordionPayload = {
    isDisplay: boolean
}

export type SetPropsalsIdPayload = {
    propsalsId: number[]
}

export enum bigFormActionTypes {
    UPDATE_ERRORS_ARRAY = 'UPDATE_ERRORS_ARRAY',
    DISPLAY_SIGNALS_IN_ACCORDION='DISPLAY_SIGNALS_IN_ACCORDION',
    SET_PROPSALS_ID = 'SET_PROPSALS_ID'
}

interface FetchUpdateErrorsArray {
    type: bigFormActionTypes.UPDATE_ERRORS_ARRAY;
    payload: FetchUpdateErrorsArrayPayload
}

interface SetIsDisplayInAccordion {
    type: bigFormActionTypes.DISPLAY_SIGNALS_IN_ACCORDION;
    payload: SetIsDisplayInAccordionPayload
}

interface SetPropsalsId {
    type: bigFormActionTypes.SET_PROPSALS_ID;
    payload: SetPropsalsIdPayload
}

export type BigFormAction = FetchUpdateErrorsArray | SetIsDisplayInAccordion | SetPropsalsId

export type SendNewOrderResponse = {
    ids: number[]
}


export type ConditionType = {
  Field: string;
  Value: string | number | boolean;
  condition: string; //изменить на enum
};

export type FieldType = {
  name: string;
  text: string;
  dataType: string;
  url: string;
  values: string;
  isRequeired: boolean;
  isMultiple: boolean;
  childs: FieldType[];
  autoFillUrl?: string;
  suggestionUrl?: string;
  weight: number;
  category?: string;
  show_if: ConditionType[];
  info?: string;
  upSeparator?: boolean;
  downSeparator?: boolean;
  isClientBlock: boolean;
  isSum: boolean;
  sumText: string;
};

export type ValidationErrorType = {
  field: string;
  subField: null;
  text: string;
};

export type ValidationType = {
  proposalId: number;
  isValid: boolean;
  errors: ValidationErrorType[];
};

export type PropsField = {
  field: FieldType;
  text: string;
  propsalId: number;
  onChange?: (
    fieldName: string,
    value: Field,
    propsalId: number,
    parentName?: string,
    indexGroupField?: number
  ) => void;
  className: string;
  classnamelabel: string;
  errorClassName: string;
  error?: string | Record<string, string> | Record<string, string>[];
  parentName?: string;
  indexGroupField?: number;
  classNameShowIf?: string;
  dependentFieldValue?: Field;
  isShowIf?: boolean;
  handleSaveDraftOnBlur?: () => void;
};

export type BankType = {
  id: number;
  name: string;
};

export type PropsalData = {
  name: string;
  fields: FieldType[];
  banks: BankType[];
  id: number;
};

export type FieldWithKey = {
  key: string;
  text: string;
};

export type ErrorsFiledsModal = Record<string, string>;

export type ErrorsFields = Record<
  string,
  string | ErrorsFiledsModal | ErrorsFiledsModal[]
>;

export type ErrorsData = {
  id: number;
  fields: ErrorsFields;
  isErrors: boolean | undefined;
};

export type Field = string | string[] | boolean | FieldWithKey | FieldWithKey[] | Object;

export type FieldModal = Record<string, Field>;

export type FormField = Record<string, Field | FieldModal | FieldModal[] | [] | {}>;

export type FormValues = {
  id: number;
  fields: FormField;
};

export type DataForBigForm = {
  errorsData: Record<number, ErrorsData>;
  formValues: Record<number, FormField>;
};

export type ValidateForm = {
  data: Record<string, string>
  proposalId: string
}

export type ValidateFormModal = {
  data: Record<string, string>
  fieldName: string
  proposalId: string
}

export type ErrorField = {
  field: string
  subField: string | null
  text: string
  index: null | number
}

export type ErrorsResponse = {
  errors: ErrorField[]
  isValid: boolean
  proposalId: number
}

export type ErrorsGroupFields = Record<number, Record<string, boolean> | undefined>

export type ValueOf<T> = T[keyof T]

// from page/new-order*************
