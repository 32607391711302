import React, { memo, useState, useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import classes from "../../Login.module.scss";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

import authApi from "../../../../core/api/authApi";
import { actions } from "../../../../core/store";
import {
  CKeyV2,
  FormType,
  ROUTES,
} from "../../../../core/consts/common";
import { WarningSign } from "../../../../core/consts/images/warningSign";
import { TextFieldMain } from "../../../../UIcomponents/TextFieldMain";
import tokenService from "../../../../core/services/TokenService";
import authService from "../../../../core/services/AuthService";

const re =
  /^(\s*)?([a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,6}(\s*)?$/;

type PropsType = {
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  isCheck?: boolean;
};
const EmailForm: React.FC<PropsType> = ({
  email,
  setEmail,
  isCheck,
}: PropsType) => {
  // const { access_token } = useAppSelector((state) => state.auth);

  const [errMessage, setErrMessage] = useState<string | null>(null);
  const navigate = useNavigate();
  const [isCapchaSuccess, setIsCapchaSuccess] = useState(false);
  // const [token, setToken] = useState("");

  useEffect(() => {
    const token = tokenService.getRefreshToken();
    if (token) {
      authService.refreshLogin().then(() => {
        navigate(`${ROUTES.PARTNER}/applications`);
      });
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      email: email,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .matches(re, "Неверный формат email")
        .required("Обязательное поле"),
    }),
    onSubmit: (values) => {
      setEmail(values.email.trim());
      authApi
        .checkPhoneCode(false, undefined, values.email.trim())
        .then((res) => {
          setErrMessage(null);
          actions.setCurrentUser({
            isAuth: false,
            inn: "",
            userId: res.id,
            phone: "",
            role: "",
            manager: null,
            nameFull: "",
            email: "",
            is_manager_approval: undefined,
            status: undefined,
          });
          navigate({
            pathname: window.location.pathname,
            search: `?formType=${FormType.LoginSecondEmail}`,
          });
        })
        .catch((err) => {
          err && setErrMessage("Произошла ошибка, попробуйте позже.");
          err?.response?.status === 404 &&
            setErrMessage(
              "Пользователь с таким почтовым адресом не зарегистрирован"
            );
          err?.response?.status === 401 &&
            setErrMessage(
              "Необходимо подтвердить почту, пройдя по ссылке в письме, высланном на указанную почту"
            );
          err?.response?.status === 400 && setErrMessage("Некорректный email");
          err?.response?.status === (502 || 500) &&
            setErrMessage("Ошибка на сервере");
        });
    },
  });

  const onCapchaChange = (value: any) => {
    console.log("Captcha value:", value);
    value && setIsCapchaSuccess(true);
  };

  return (
    <>
      <form
        className={classes.formBox}
        onSubmit={formik.handleSubmit}
        noValidate
        autoComplete="off"
      >
        <TextFieldMain
          variant="filled"
          id="email"
          label="Электронная почта"
          placeholder="example@yandex"
          autoFocus={true}
          className={classes.textFieldEmail}
          value={formik.values.email}
          onChange={formik.handleChange}
          inputMode="email"
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          InputLabelProps={{
            shrink: true,
          }}
        />
        {errMessage && (
          <div className={classes.error_flex}>
            <WarningSign />
            <span>{errMessage}</span>
          </div>
        )}
        {isCheck && (
          <div style={{ alignSelf: "center" }}>
            <ReCAPTCHA sitekey={CKeyV2} onChange={onCapchaChange} />
            {/* <SmartCaptcha sitekey={keyYandexCapcha} onSuccess={setToken} />; */}
          </div>
        )}
        <button
          className="button_black"
          type="submit"
          disabled={!isCapchaSuccess && isCheck}
        >
          Продолжить
        </button>
      </form>
    </>
  );
};

export default memo(EmailForm);
