import React, { useEffect, useState } from "react";
import styles from "../Login.module.scss";
import {
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import ArrowRightUp from "../../../core/consts/images/ArrowRightUp.png";
import { useSelector } from "react-redux";
import TooltipModalClientOrAgent from "./TooltipModalClientOrAgent";
import { useNavigate } from "react-router-dom";
import authApi, { ConfirmRoleRDType, KeyTextType } from "../../../core/api/authApi";
import { rootState } from "../../../core/types/rootState";
import { ClientType, FormType, ROLES, ROUTES } from "../../../core/consts/common";
import authService from "../../../core/services/AuthService";
type Props = {
  phone: string;
};

const detectDevice = (): string => {
  const userAgentRes = navigator.userAgent;
  let result = "desk";
  if (/IEMobile|Windows Phone|Lumia/i.test(userAgentRes)) {
    result = "Windows Phone";
  } else if (/iPhone|iP[oa]d/.test(userAgentRes)) {
    result = "iOS";
  } else if (/Android/.test(userAgentRes)) {
    result = "Android";
  } else if (/BlackBerry|PlayBook|BB10/.test(userAgentRes)) {
    result = "BlackBerry";
  } else if (/Mobile Safari/.test(userAgentRes)) {
    result = "Mobile Safari";
  } else if (
    /webOS|Mobile|Tablet|Opera Mini|\bCrMo\/|Opera Mobi/i.test(userAgentRes)
  ) {
    result = "mob";
  }
  return result;
};

const LoginClientOrAgentForm = ({ phone }: Props) => {
  const navigate = useNavigate();
  const [role, setRole] = useState("client");
  const [info, setInfo] = useState<KeyTextType[]>([]);
  const [message, setMessage] = useState<string>("");
  const { addInfo } = useSelector((state: rootState) => state.auth);
  const { currentUser } = useSelector((state: rootState) => state);
  const [errMessage, setErrMessage] = useState<string>("");
  const [chosenRoleForTooltip, setChosenRoleForTooltip] = useState(
    ClientType.Client
  );
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const clickId = localStorage.getItem("cid");
  const cid = clickId ? { cid: clickId } : {};

  const userId = localStorage.getItem("uid");
  const uid = userId ? { c2MUserId: userId } : {};

  const getAuthInfo = async () => {
    try {
      const res = await authApi.getAuthInfo();
      if (res) {
        setInfo(res);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getAuthInfo();
    return () => {
      setErrMessage("");
    };
  }, []);

  const handleChoiceRoutes = (role: string) => {
    if (detectDevice() === "desk") {
      switch (role) {
        case ROLES.CLIENT:
          navigate(`${ROUTES.CLIENT}/products`);
          break;
        case ROLES.AGENT:
          navigate(`${ROUTES.AGENT}/products`);
          break;
        case ROLES.PARTNER:
          navigate(`${ROUTES.PARTNER}/applications`);
          break;
        case ROLES.MANAGER:
          navigate(`${ROUTES.MANAGER}/profile`);
          break;
        case ROLES.ADMIN:
          navigate(`${ROUTES.ADMIN}/profile`);
          break;
        default: {
          // history.push({
          //   pathname: history.location.pathname,
          //   // search: `?formType=${FormType.LoginInn}`,
          //   search: `?formType=${FormType.LoginClientOrAgent}`,
          // });
        }
      }
    } else {
      navigate({
        pathname: window.location.pathname,
        search: `?formType=${FormType.LoginBlockMob}`,
      });
    }
  };

  const handleSubmit = async () => {
    setErrMessage("");
    try {
      const rd: ConfirmRoleRDType = {
        userId: currentUser?.userId,
        role: role,
        code: addInfo.code,
        phone: phone,
        ...cid,
        ...uid,
      };
      setMessage("");
      const resRole = await authService.confirmRole(rd);
      handleChoiceRoutes(resRole);
    } catch (err: any) {
      console.log(err);
      console.log("err?.request?.response", err?.request?.response);
      if (err.request.status === 500) {
        setErrMessage("Ошибка на сервере.");
      } else if (err.request.status === 429) {
        setErrMessage("Попробуйте через 10 секунд");
      } else if (err.request.status === 400 && err?.request?.response?.errors) {
        setMessage(err?.request?.response?.errors?.[0]?.message);
      } else {
        setErrMessage("Ошибка.");
      }
    }
  };

  return (
    <>
      <div className={styles.container}>
        Выберите, в качестве кого вы будете регистрироваться
      </div>
      <RadioGroup onChange={(e) => setRole(e.target.value)} value={role}>
        <div
          className={
            role === ClientType.Client
              ? styles.role_box_active
              : styles.role_box
          }
          style={{ padding: "20px" }}
        >
          <div className={styles.flex}>
            <FormControlLabel
              value={ClientType.Client}
              control={<Radio color="primary" />}
              defaultChecked
              label={
                <Typography variant="body2" className={styles.role_box_title}>
                  Клиент
                </Typography>
              }
            />
          </div>
          <div className={styles.role_box_desc}>
            {info?.find((el) => el.key === "client")?.text}
          </div>
          <div style={{ display: "flex" }}>
            <div
              className={styles.link_back_role}
              onClick={() => {
                setChosenRoleForTooltip(ClientType.Client);
                setTooltipOpen(true);
              }}
            >
              <div>Подробнее</div>
              <div className={styles.arrow}>
                <img
                  src={ArrowRightUp}
                  alt=""
                  className={styles.img_fit}
                  style={{ width: "10px", height: "10px" }}
                />
              </div>
            </div>
          </div>
        </div>

        <div
          className={
            role === ClientType.Agent ? styles.role_box_active : styles.role_box
          }
          style={{ padding: "20px" }}
        >
          <div className={styles.flex}>
            <FormControlLabel
              value={ClientType.Agent}
              control={<Radio color="primary" />}
              style={{ marginRight: "0" }}
              label={
                <Typography variant="body2" className={styles.role_box_title}>
                  Агент
                </Typography>
              }
            />
          </div>
          <div className={styles.role_box_desc}>
            {info?.find((el) => el.key === "agent")?.text}
          </div>
          <div style={{ display: "flex" }}>
            <div
              className={styles.link_back_role}
              onClick={() => {
                setChosenRoleForTooltip(ClientType.Agent);
                setTooltipOpen(true);
              }}
            >
              <div>Подробнее</div>
              <div className={styles.arrow}>
                <img
                  src={ArrowRightUp}
                  alt=""
                  className={styles.img_fit}
                  style={{ width: "10px", height: "10px" }}
                />
              </div>
            </div>
          </div>
        </div>
      </RadioGroup>
      <Button
        className={styles.button_reg}
        variant="contained"
        onClick={handleSubmit}
        color="primary"
      >
        <Typography
          component="span"
          variant="body1"
          style={{ color: "inherit", textTransform: "none", lineHeight: "3" }}
        >
          Зарегистрироваться
        </Typography>
      </Button>
      {errMessage && (
        <Typography variant="body1" component="p" className={styles.error}>
          {errMessage}
        </Typography>
      )}
      <TooltipModalClientOrAgent
        isOpen={tooltipOpen}
        handleClose={() => setTooltipOpen(false)}
        clientType={chosenRoleForTooltip}
        info={info}
      />
    </>
  );
};

export default LoginClientOrAgentForm;
