import {
    PartnerAplicationAction,
    PartnerAplicationState,
    partnerAplicationActionTypes,
} from "../../types/partnerAplication";

const initialState: PartnerAplicationState = {
    filters: {
        inn: "",
        period: "all",
        status: "all",
        product: "all",
    },
    activeTab: 0,
    tabs: [
        { name: "Новые", count: 0 },
        { name: "Все заявки", count: 0 },
        { name: "Архив", count: 0 },
    ],
    isNewOrders: true,
    currentPage: 1,
    isArchiveOrders: undefined,
    scrollY: null
};

export const partnerAplicationReducer = (state = initialState, action: PartnerAplicationAction) => {
    switch (action.type) {
        case partnerAplicationActionTypes.SET_FILTER_VALUE:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    [action.payload.field]: action.payload.value,
                },
            };
        case partnerAplicationActionTypes.SET_ACTIVE_TAB:
            return {
                ...state,
                activeTab: action.payload,
            };
        case partnerAplicationActionTypes.SET_IS_ARCHIVE_ORDERS:
            return {
                ...state,
                isArchiveOrders: action.payload,
            };
        case partnerAplicationActionTypes.SET_IS_NEW_ORDERS:
            return {
                ...state,
                isNewOrders: action.payload,
            };
        case partnerAplicationActionTypes.SET_TABS:
            return {
                ...state,
                tabs: action.payload,
            };
        case partnerAplicationActionTypes.SET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.payload,
            };
        case partnerAplicationActionTypes.SET_SCROLL_Y:
            return {
                ...state,
                scrollY: action.payload,
            };
        default:
            return state;
    }
};
