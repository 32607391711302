import { Button } from "../../../components/HomeMain/Button";
import { Card } from "../../../components/HomeMain/Card";
import { ArrowRight } from "../../../icons/ArrowRight";
import styles from "./HomeMain.module.scss";
import accountScreen from "../../../images/screen-account.png";
import massagesImg from "../../../images/massages.png";
import massagesMobile from "../../../images/massagesMobile.png";
import { ABOUT_US_CARDS, CARDS, POPULAR_QUESTIONS } from "./const";
import { Order } from "../../../components/HomeMain/Order";
import { Accordeon } from "../../../components/HomeMain/Accordeon/Accordeon";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import { FormType } from "../../../core/consts/common";
import React from "react";
import { CreateOrderModal } from "../../../components/modals/CreateOrderModal";
import { ConfirmModal } from "../../../components/modals/ConfirmModal";
import Logo from "../../../images/logo.svg";
import useWindowDimensions from "../../../core/hooks/useWindowDimensions";
import { CookieBox } from "../../../components/CookieBox";

export function HomeMain() {
    const [isOpenCreateModal, setIsOpenCreateModal] = React.useState(false);
    const [isOpenConfirmModal, setIsOpenConfirmModal] = React.useState(false);
    const navigate = useNavigate();
    const { width } = useWindowDimensions();

    function handleCloseCreateModal() {
        setIsOpenCreateModal(false);
    }

    // React.useEffect(() => {

    // }, [size.width])

    const handleLogin = () => {
        const isRestrationed = window.localStorage.getItem("isRegistration");
        if (isRestrationed) {
            navigate({
                pathname: window.location.pathname,
                search: `?formType=${FormType.LoginFirstEmail}`,
            });
            return;
        }

        navigate({
            pathname: window.location.pathname,
            search: `?formType=${FormType.LoginFirstEmail}`,
        });
    };

    return (
        <div className={styles.base}>
            <div className={clsx(styles.containerBlock, styles.header)}>
                <div>
                    <img src={Logo} alt="" />
                </div>
                <div onClick={handleLogin} className={styles.enter}>
                    {width < 1022 ? "Вход" : "Вход в кабинет"}
                </div>
            </div>
            <div className={clsx(styles.containerBlock, styles.main)}>
                <div className={styles.mainTitle}>
                    Финансирование{" "}
                    <span className={styles.whiteText}>для {width < 1022 && <br />} селлеров</span>{" "}
                    {width > 1022 && <br />}
                    на маркетплейсах
                </div>
                <Button onClick={() => setIsOpenCreateModal(true)}>
                    <div className={styles.mainButton}>
                        <div className={styles.mainButtonText}>Оставить заявку</div> <ArrowRight />
                    </div>
                </Button>
            </div>
            <div className={clsx(styles.containerBlock, styles.whyWe)}>
                <div className={styles.title}>
                    Почему клиенты <span className={styles.whiteText}>с нами</span>
                </div>
                <div className={styles.cards}>
                    {CARDS.map((card, index) => (
                        <Card key={index} {...card} className={styles.whyWeCard} />
                    ))}
                </div>
            </div>
            <div className={clsx(styles.containerBlock, styles.aboutAccount)}>
                <div className={styles.aboutAccountContent}>
                    <div className={clsx(styles.title, styles.aboutAccountTitle)}>
                        Все заявки в <span className={styles.whiteText}>личном кабинете</span>
                    </div>
                    <div className={styles.text}>
                        Мы создали собственную IT–систему, чтобы упростить и ускорить процесс
                        получения денег для вас. <br />
                        <br /> Мы сделаем все возможное, чтобы обеспечить вам быструю и удобную
                        финансовую поддержку.
                    </div>
                </div>
                <img
                    className={styles.accountScreen}
                    src={accountScreen}
                    alt="Скриншот личного кабинета"
                />
            </div>
            <div className={styles.aboutSupportWrapper}>
                <div className={clsx(styles.containerBlock, styles.aboutSupport)}>
                    <div className={styles.image}>
                        <img
                            className={styles.massagesImg}
                            src={width < 1022 ? massagesMobile : massagesImg}
                            alt="Скриншот чата с менеджером"
                        />
                    </div>
                    <div className={styles.aboutSupportContent}>
                        <div className={clsx(styles.title, styles.aboutSupportTitle)}>
                            <span className={styles.acceptText}>Специалисты</span>
                            <br />
                            <span className={styles.whiteText}>всегда на связи</span>
                        </div>
                        <div className={clsx(styles.text, styles.aboutSupportText)}>
                            Наши консультанты знают о продуктах все и оперативно подберут
                            финансирование для вашего бизнеса
                        </div>
                    </div>
                </div>
            </div>
            <div className={clsx(styles.containerBlock, styles.aboutUs)}>
                <div className={clsx(styles.title, styles.aboutUsTitle)}>
                    Малый и средний бизнес в России <br />
                    <span className={styles.whiteText}>наш профиль работы</span>
                </div>
                <div className={clsx(styles.cards, styles.aboutUsCards)}>
                    <div className={styles.firstCard}>
                        <div className={styles.count}>500</div>
                        <div className={styles.firstCardContent}>
                            <div className={styles.firstCardTitle}>млн. рублей</div>
                            <div className={styles.firstCardText}>
                                Объем финансирования в 2022 году
                            </div>
                        </div>
                    </div>
                    <div className={styles.cards}>
                        {ABOUT_US_CARDS.map((card, index) => (
                            <Card
                                key={index}
                                {...card}
                                classNameText={styles.aboutUsCardText}
                                className={styles.aboutUsCard}
                            />
                        ))}
                    </div>
                </div>
            </div>
            <div className={clsx(styles.containerBlock, styles.order)}>
                <Order />
            </div>
            <div className={styles.questionsWrapper}>
                <div className={clsx(styles.containerBlock, styles.questions)}>
                    <div className={clsx(styles.title, styles.questionsTitle)}>
                        Часто спрашивают
                    </div>
                    <div className={styles.accordions}>
                        {POPULAR_QUESTIONS.map((item, index) => (
                            <div key={index} className={styles.accordion}>
                                <Accordeon
                                    // initialState={index === 0 ? true : false}
                                    title={item.title}
                                    content={item.text}
                                />
                                <div className={styles.separator} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <CreateOrderModal
                isOpen={isOpenCreateModal}
                confirmFn={() => setIsOpenConfirmModal(true)}
                handleClose={handleCloseCreateModal}
            />
            <ConfirmModal
                title="Заявка отправлена"
                confirmButtonText="Отлично"
                text="Как только заявка будет обработана наш специалист свяжется с вами"
                handleCancel={() => setIsOpenConfirmModal(false)}
                isOpen={isOpenConfirmModal}
            />
            <CookieBox />
        </div>
    );
}
