import * as React from "react";
import { UsePaginationItem } from "@mui/material/usePagination";
import classes from "./Pagination.module.scss";
import useMediaQuery from "@mui/material/useMediaQuery";

type PropType = {
  items: UsePaginationItem[];
};

export const getCurrentPage = (items: UsePaginationItem[]) => {
  return items.find((elem) => elem.selected === true)?.page ?? 1;
};

export default function PaginationMain({ items }: PropType) {
  const isLessThan760 = useMediaQuery("(max-width:760px)");
  const [pageCount, setPagecount] = React.useState(0);
  React.useEffect(() => {
    let count = 0;
    items.forEach((item) => {
      if (item.type === "page") {
        count++;
      }
    });
    setPagecount(count);
  }, [items]);

  return (
    <div className={classes.outer}>
      {isLessThan760 && (
        <div>
          <button
            onClick={items.find((el) => el.type === "next")?.onClick}
            disabled={items.find((el) => el.type === "next")?.disabled}
            className={
              items.find((el) => el.type === "next")?.selected
                ? classes.page_item_current
                : classes.page_item
            }
            style={{
              width: "100%",
              marginBottom: "6px",
              minWidth:
                items.find((el) => el.type === "start-ellipsis") &&
                items.find((el) => el.type === "end-ellipsis")
                  ? "305px"
                  : pageCount === 6
                  ? "336px"
                  : pageCount === 7
                  ? "316px"
                  : pageCount <= 6
                  ? `${pageCount * (48 + 6) - 6}px`
                  : "340px",
            }}
          >
            Дальше
          </button>
        </div>
      )}
      <div className={classes.container}>
        {items.map(({ page, type, selected, ...item }, index) => {
          let children = null;

          if (type === "start-ellipsis" || type === "end-ellipsis") {
            children = "…";
          } else if (
            type === "page" &&
            !(pageCount === 7 && page === 7 && isLessThan760)
          ) {
            children = (
              <button
                type="button"
                className={
                  selected ? classes.page_item_current : classes.page_item
                }
                {...item}
              >
                {page}
              </button>
            );
          } else if (type === "next" && !isLessThan760) {
            children = (
              <button
                type="button"
                {...item}
                className={
                  selected ? classes.page_item_current : classes.page_item
                }
              >
                Дальше
              </button>
            );
          }

          return <div key={index}>{children}</div>;
        })}
      </div>
    </div>
  );
}
