import React from "react";
import { useTrackingScroll } from "../../../../core/hooks/useTrackingScroll";
import { actions } from "../../../../core/store";

export function UseHandleScroll() {
    const scroll = React.useRef<number | null>();
    const { scrollY } = useTrackingScroll();

    React.useEffect(() => {
        scroll.current = scrollY;
    }, [scrollY]);

    React.useEffect(() => {
        return () => {
            actions.setPartnerApplicationScroll(scroll.current ?? null);
        };
    }, []);
    return <></>;
}
