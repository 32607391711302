import { UTM_EVENT_ENUM } from "../consts/common";
import { http } from "../infrastructure/Https";
import tokenService from "../services/TokenService";
import {
  userClientInfoType,
  userAgentInfoType,
  userListQueryType,
  UserInfoType,
} from "../types/userTypes";
const ROOT_URL = "/api/v1/user";

export type UserComment = { message: string };

export type UtmInitBodyType = {
  utmSource: string;
  utmMedium: string;
  event: UTM_EVENT_ENUM;
}
export type UtmBodyType = {
  event: UTM_EVENT_ENUM;
  userId?: string;
  followingLinkEventId?: string;
}

class UserApi {
  blockUser(body: { id: string; block: boolean }) {
    const accessToken = tokenService.getAccessToken();
    return http.put(`${ROOT_URL}/block`, null, body, accessToken);
  }

  addUser(body: userClientInfoType | userAgentInfoType) {
    const accessToken = tokenService.getAccessToken();
    return http.post(`${ROOT_URL}`, null, body, accessToken);
  }

  deleteUser(body: { id: string }) {
    const accessToken = tokenService.getAccessToken();
    return http.delete(`${ROOT_URL}`, null, body, accessToken);
  }

  editUser(body: userClientInfoType | userAgentInfoType) {
    const accessToken = tokenService.getAccessToken();
    return http.put(`${ROOT_URL}`, null, body, accessToken);
  }

  getUserInfo(id: string): Promise<UserInfoType> {
    const accessToken = tokenService.getAccessToken();
    return http.get(`${ROOT_URL}`, { id: id }, accessToken);
  }
  putUserInfo(data: any): Promise<any> {
    const accessToken = tokenService.getAccessToken();
    return http.put(`${ROOT_URL}`, null, data, accessToken);
  }

  getUserRoles() {
    const accessToken = tokenService.getAccessToken();
    return http.get(`${ROOT_URL}/roles`, null, accessToken);
  }

  getUsersList(Q: userListQueryType) {
    const accessToken = tokenService.getAccessToken();
    return http.get(`${ROOT_URL}`, Q, accessToken);
  }

  setUserLimit(): Promise<boolean> {
    const accessToken = tokenService.getAccessToken();
    return http.get(`${ROOT_URL}/limit`, null, accessToken);
  }

  createComment(id: string, message: UserComment) {
    const accessToken = tokenService.getAccessToken();
    return http.post<null, UserComment, null>(
      `${ROOT_URL}/${id}/comment`,
      null,
      message,
      accessToken
    );
  }

  changeVerification(B: { userId: string; set: boolean }): Promise<string> {
    const accessToken = tokenService.getAccessToken();
    return http.post(`${ROOT_URL}/changeVerification`, null, B, accessToken);
  }

  editUserZaChBiz(id: string): Promise<string> {
    const accessToken = tokenService.getAccessToken();
    return http.put(`${ROOT_URL}/zchb/${id}`, null, null, accessToken);
  }

  // POST/api/v1/user/utmreferral Отправка utm метки.
  sendUtm(utmBody: UtmInitBodyType | UtmBodyType): Promise<string> {
    return http.post(`${ROOT_URL}/utmreferral`, null, utmBody,);
  }
}

const userApi = new UserApi();
export default userApi;
