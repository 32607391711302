import React from "react";
import styles from "./UserAgreement.module.scss";
import { WhiteModal } from "../WhiteModal";
import { Typography } from "@mui/material";

export type Props = {
    title?: string;
    isOpen: boolean;
    className?: string;
    handleClose?: () => void;
};

export function UserAgreement(props: Props) {
    return (
        <WhiteModal
            isOpen={props.isOpen}
            className={props.className}
            handleClose={props.handleClose}
            title={props.title}
        >
            <Typography variant="h3" component="h3">
                Cогласие на обработку персональных данных
            </Typography>
            <div className={styles.content}>
                Настоящим даю свое согласие на обработку моих персональных данных Обществу с
                ограниченной ответственностью «ЛИНЕЙНЫЕ КОММУНИКАЦИИ» (далее – Оператор)
                (местонахождение: г. Москва, ул. Уржумская, д. 7, этаж/ком. подвал No0/34, офис 43)
                с целью оказания услуг по дистанционному оформлению и подбору финансовых услуг,
                заключению договоров займа, направлением почтовых сообщений и/или смс-уведомлений, в
                том числе рекламного содержания систематизации и анализа с целью улучшения качества
                товаров и услуг. в порядке и на условиях, определенных Федеральным законом от
                27.07.2006 No 152-ФЗ «О персональных данных» (далее – Федеральный закон No 152-ФЗ) и
                подтверждаю, что, давая такое согласие, я действую своей волей и в своем интересе.
                При этом под моими персональными данными понимаются любые относящиеся ко мне
                сведения: фамилия, имя, отчество, сведения о документе, удостоверяющем личность,
                паспортные данные, ИНН, СНИЛС, адрес регистрации, адрес электронной почты, номер
                телефона, данные о финансовом положении, а также любая иная относящаяся ко мне
                информация, которая была (будет) передана Оператору мною лично или поступила
                (поступит) Оператору иным законным способом. Я проинформирован, что Оператор
                осуществляет обработку персональных данных автоматизированном и не
                автоматизированным способом, включая: сбор, запись, систематизацию, накопление,
                хранение, уточнение (обновление, изменение), извлечение, использование, передачу
                (предоставление, доступ), в случаях, прямо предусмотренных законодательством
                Российской Федерации, обезличивание, блокирование, удаление, уничтожение, а так же
                осуществление любых действий, предусмотренных действующим законодательством
                Российской Федерации. Данное согласие действует до достижения целей обработки
                персональных данных и/или в течении сроков хранения персональных данных в
                соответствии с законодательством Российской Федерации. Я уведомлен(а) о своем праве
                отозвать согласие на обработку персональных данных путем подачи письменного
                заявления в адрес Оператора, при этом прекращение обработки и уничтожение
                персональных данных осуществляется в сроки и в порядке, которые установлены
                законодательством Российской Федерации. Датой согласия на обработку персональных
                данных считается дата подтверждения ознакомления с данным согласием на сайте
                Оператора по адресу: https://payrix.ru. Подтверждаю, что ознакомлен(а) с положениями
                Федерального закона No 152-ФЗ.
            </div>
        </WhiteModal>
    );
}
