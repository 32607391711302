import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  IconButton,
  TextField,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from "@mui/material";
import React, { ChangeEvent, useState } from "react";
import {
  getUserCertificates,
  createDetachedSignature,
  createHash,
} from "crypto-pro";
import { useNavigate } from "react-router-dom";
import { FormType, ROLES, ROUTES } from "../../../../core/consts/common";
import authService from "../../../../core/services/AuthService";
import { WarningSign } from "../../../../core/consts/images/warningSign";
import QuestionIcon from "../../../../core/consts/images/QuestionIcon";
import classes from "../../Login.module.scss"

const strToData = (strDate: string): string => {
  let date = new Date(strDate);
  return date.toLocaleDateString("ru-RU");
};

const getNumberOfDays = (end: string): number => {
  const date1 = new Date();
  const date2 = new Date(end);
  const oneDay = 1000 * 60 * 60 * 24;
  const diffInTime = date2.getTime() - date1.getTime();
  const diffInDays = Math.round(diffInTime / oneDay);
  return diffInDays;
};

// const CustomTooltip = withStyles({
//   tooltip: {
//     color: "white",
//     fontFamily: "Gotham Pro",
//     fontSize: "16px",
//     background: "rgba(19, 19, 19, 0.5)",
//     borderRadius: "2px",
//     maxWidth: "279px",
//     padding: "10px",
//   },
// })(Tooltip);

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    color: "white",
    fontFamily: "Gotham Pro",
    fontSize: "16px",
    background: "rgba(19, 19, 19, 0.5)",
    borderRadius: "2px",
    maxWidth: "279px",
    padding: "10px",
  },
}));

type certificate = {
  userName: string;
  fio: string;
  inn: string;
  dateStart: string;
  dateEnd: string;
  thumbprint: string;
};

const detectDevice = (): string => {
  const userAgentRes = navigator.userAgent;
  let result = "desk";
  if (/IEMobile|Windows Phone|Lumia/i.test(userAgentRes)) {
    result = "Windows Phone";
  } else if (/iPhone|iP[oa]d/.test(userAgentRes)) {
    result = "iOS";
  } else if (/Android/.test(userAgentRes)) {
    result = "Android";
  } else if (/BlackBerry|PlayBook|BB10/.test(userAgentRes)) {
    result = "BlackBerry";
  } else if (/Mobile Safari/.test(userAgentRes)) {
    result = "Mobile Safari";
  } else if (
    /webOS|Mobile|Tablet|Opera Mini|\bCrMo\/|Opera Mobi/i.test(userAgentRes)
  ) {
    result = "mob";
  }
  return result;
};

const CertificateModal: React.FC = () => {
  // const classes = CertificateStyles();
  const [daysToEnd, setDaysToEnd] = useState<number>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [authError, setAuthError] = useState<string | null>(null);
  const [certificates, setCertificates] = useState<certificate[]>([]);
  const [chooseCertificate, setChoosenCertificate] =
    useState<null | certificate>(null);
  const navigate = useNavigate();

  const handleChoiceRoutes = (role: string) => {
    if (detectDevice() === "desk" && role) {
      switch (role) {
        case ROLES.CLIENT:
          navigate(`${ROUTES.CLIENT}/products`);
          break;
        case ROLES.AGENT:
          navigate(`${ROUTES.AGENT}/products`);
          break;
        case ROLES.PARTNER:
          navigate(`${ROUTES.PARTNER}/applications`);
          break;
        case ROLES.MANAGER:
          navigate(`${ROUTES.MANAGER}/profile`);
          break;
        case ROLES.ADMIN:
          navigate(`${ROUTES.ADMIN}/profile`);
          break;
      }
    } else {
      navigate({
        pathname: window.location.pathname,
        search: `?formType=${FormType.LoginBlockMob}`,
      });
    }
  };

  (async function () {
    try {
      const data = await getUserCertificates();
      if (!certificates.length) {
        data.forEach(async (elem) => {
          const newData = [
            ...certificates,
            {
              userName: elem.name.replaceAll(/["|'|\/|\\]/g, ""),
              fio:
                elem.subjectName.indexOf("SN=") !== -1
                  ? elem.subjectName.split("SN=")[1].split(",")[0] +
                    " " +
                    elem.subjectName.split("G=")[1].split(",")[0]
                  : elem.subjectName.indexOf("CN=") !== -1
                  ? elem.subjectName.split("CN=")[1].split(",")[0]
                  : "",
              inn:
                elem.subjectName.indexOf("ИНН ЮЛ=") !== -1
                  ? elem.subjectName.split("ИНН ЮЛ=")[1].split(",")[0]
                  : elem.subjectName.indexOf("OID.1.2.643.100.4=") !== -1
                  ? elem.subjectName
                      .split("OID.1.2.643.100.4=")[1]
                      .split(",")[0]
                  : elem.subjectName.indexOf("ИНН=") !== -1
                  ? elem.subjectName.split("ИНН=")[1].split(",")[0]
                  : elem.subjectName.indexOf("INN=") !== -1
                  ? elem.subjectName.split("INN=")[1].split(",")[0]
                  : "",
              dateStart: elem.validFrom,
              dateEnd: elem.validTo,
              thumbprint: elem.thumbprint,
            },
          ];
          setCertificates(newData);
        });
      }
    } catch (error: any) {
      setErrorMessage(
        error.message ||
          "Внимание! Сертификат не найден или не настроено программное обеспечение для работы в системе."
      );
      console.log({ error });
    }
  })();

  const handleChangeSelect = (_: ChangeEvent<{}>, value: any) => {
    const thumbprint = value.thumbprint;
    const choosenCert = certificates.find((el) => el.thumbprint === thumbprint);
    choosenCert && setChoosenCertificate(choosenCert);
    let daysLeft = choosenCert && getNumberOfDays(choosenCert.dateEnd);
    daysLeft && daysLeft < 31 && setDaysToEnd(daysLeft);
  };

  const handleCheckSystem = () => {
    navigate({
      pathname: window.location.pathname,
      search: `?formType=${FormType.LoginCertificateCheck}`,
    });
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    try {
      setAuthError(null);
      const res1 =
        chooseCertificate &&
        (await authService.keyGenerateService({ inn: chooseCertificate.inn }));
      const hash = await createHash(res1!.key.toString());
      const signature = await createDetachedSignature(
        chooseCertificate!.thumbprint,
        hash
      );
      await authService
        .signatureCheckService({
          userId: res1!.userId,
          sign: signature,
        })
        .then((res) => {
          handleChoiceRoutes(res);
        });
    } catch (error: any) {
      error && setAuthError("Произошла ошибка, попробуйте позже.");
      error.response &&
        error.response.status === 404 &&
        setAuthError(
          "Пользователь с таким почтовым адресом не зарегистрирован"
        );
      error.response &&
        error.response.status === (502 || 500) &&
        setAuthError("Ошибка на сервере");
    }
  };

  return (
    <>
      <form
        className={classes.formBox}
        onSubmit={handleSubmit}
        noValidate
        autoComplete="off"
      >
        {certificates.length > 0 ? (
          <FormControl>
            <Autocomplete
              onChange={handleChangeSelect}
              value={chooseCertificate ? chooseCertificate : undefined}
              getOptionLabel={(option: any) =>
                option.userName ? option.userName : "   "
              }
              freeSolo={true}
              options={certificates}
              classes={{
                option: classes.optionsSelect,
              }}
              disableClearable
              renderInput={(params) => (
                <TextField {...params} label="Выберите сертификат" />
              )}
              renderOption={(option: any, state) => {
                return (
                  <Box
                    component="li"
                    {...state}
                    className={classes.boxSelectOptions}
                  >
                    <Typography
                      variant="body1"
                      component="span"
                      className={classes.selectOptionsName}
                    >
                      {option.userName}
                    </Typography>
                    <Typography
                      variant="body2"
                      className={classes.selectOptionsText}
                      component="span"
                    >
                      {strToData(option.dateStart)} -{" "}
                      {strToData(option.dateEnd)}
                    </Typography>
                    <Typography
                      variant="body2"
                      className={classes.selectOptionsText}
                      component="span"
                    >
                      {option.fio}
                    </Typography>
                  </Box>
                );
              }}
            />
          </FormControl>
        ) : (
          <Typography
            variant="body1"
            className={classes.noCeritificatesText}
            component="span"
          >
            {errorMessage}
          </Typography>
        )}
        {chooseCertificate && (
          <div className={classes.certificaterWrap}>
            {daysToEnd && (
              <Box className={classes.certificateExpireText}>
                <WarningSign />
                &nbsp;&nbsp;
                <Typography
                  className={classes.certificateExpireText}
                  variant="body2"
                  component="span"
                >
                  Срок действия истекает <br /> через {daysToEnd} дней
                </Typography>
              </Box>
            )}
            <Typography
              variant="h4"
              style={{ display: "inline-block" }}
              component="h4"
            >
              {chooseCertificate.userName}
            </Typography>
            <CustomTooltip
              placement="bottom-start"
              title={
                <span>
                  {`Действует до ${strToData(chooseCertificate.dateEnd)}`}
                  <br />
                  <br />
                  <span style={{ fontSize: "14px" }}>
                    Срок действия появится на сертификате за 30 дней до его
                    окончания.
                  </span>
                </span>
              }
            >
              <IconButton>
                <QuestionIcon />
              </IconButton>
            </CustomTooltip>
            <Typography
              className={classes.certificateText}
              variant="body2"
              component="span"
            >
              ИНН: {chooseCertificate.inn}
            </Typography>
            <Typography
              className={classes.certificateText}
              variant="body2"
              component="span"
            >
              {chooseCertificate.fio}
            </Typography>
          </div>
        )}
        {certificates.length > 0 ? (
          <>
            <Button
              className={classes.button}
              variant="contained"
              style={{ width: "300px" }}
              type="button"
              disabled={chooseCertificate ? false : true}
              color="primary"
              onClick={handleSubmit}
            >
              <Typography
                component="span"
                variant="body1"
                style={{
                  color: "inherit",
                  textTransform: "none",
                  lineHeight: "3",
                }}
              >
                Войти
              </Typography>
            </Button>
            {authError && (
              <Typography
                variant="body2"
                component="p"
                className={`${classes.subtitle}`}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "23px",
                }}
              >
                <WarningSign />
                <span style={{ marginLeft: "15px" }}>{authError}</span>
              </Typography>
            )}
          </>
        ) : (
          <Button
            className={classes.button}
            variant="contained"
            style={{ width: "300px" }}
            type="button"
            disabled={false}
            onClick={handleCheckSystem}
            color="primary"
          >
            <Typography
              component="span"
              variant="body1"
              style={{
                color: "inherit",
                textTransform: "none",
                lineHeight: "3",
              }}
            >
              Проверить компоненты
            </Typography>
          </Button>
        )}
      </form>
    </>
  );
};

export default CertificateModal;
