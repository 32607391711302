import React from "react";
import { memo, useState, useEffect } from "react";
import classes from "../../Login.module.scss";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

import authApi from "../../../../core/api/authApi";
import { actions } from "../../../../core/store";
import {
  CKeyV2,
  FormType,
  ROUTES,
} from "../../../../core/consts/common";
import { WarningSign } from "../../../../core/consts/images/warningSign";
import { PatternFormat } from "react-number-format";
import { TextFieldMainForPhone } from "../../../../UIcomponents/TextFieldMain";
import tokenService from "../../../../core/services/TokenService";
import authService from "../../../../core/services/AuthService";

type PropsType = {
  phoneGl: string;
  setPhone: React.Dispatch<React.SetStateAction<string>>;
  isCheck?: boolean;
};

const PhoneForm: React.FC<PropsType> = ({ phoneGl, setPhone, isCheck }: PropsType) => {
  const [errMessage, setErrMessage] = useState<string | null>(null);
  const [phone, setPhoneLocal] = useState({ number: phoneGl });
  const navigate = useNavigate();
  const [isCapchaSuccess, setIsCapchaSuccess] = useState(false);
  // const [token, setToken] = useState("");

  useEffect(() => {
    const token = tokenService.getRefreshToken();
    if (token) {
      authService.refreshLogin().then(() => {
        navigate(`${ROUTES.PARTNER}/applications`);
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePressKey = (e: any) => {
    if (e.key === "Enter") {
      handleClickSendPhone();
    }
  };
  const handleClickSendPhone = () => {
    setPhone(phone.number);
    const phoneNumberLength = phone.number.replaceAll(" ", "").length;
    if (phone.number.length === 0) {
      setErrMessage("Заполните номер телефона");
      return;
    } else if (phoneNumberLength < 16) {
      setErrMessage("Некорректный номер телефона");
      return;
    } else if (!/\+7 \(\d\d\d\) \d\d\d-\d\d-\d\d/g.test(phone.number)) {
      setErrMessage("Неверный формат номера телефона");
      return;
    }

    authApi
      .checkPhoneCode(true, phone.number)
      .then((res) => {
        setErrMessage(null);
        actions.setCurrentUser({
          isAuth: false,
          inn: "",
          userId: res.id,
          phone: "",
          role: "",
          manager: null,
          nameFull: "",
          email: "",
          is_manager_approval: undefined,
          status: undefined,
        });
        navigate({
          pathname: window.location.pathname,
          search: `?formType=${FormType.LoginSecondPhone}`,
        });
      })
      .catch((err) => {
        err && setErrMessage("Произошла ошибка, попробуйте позже.");
        err?.response?.status === 400 &&
          setErrMessage("Некорректный номер телефона");
        err?.response?.status === 404 &&
          setErrMessage("Пользователь с таким номером не зарегистрирован");
        err?.response?.status === (502 || 500) &&
          setErrMessage("Ошибка на сервере");
      });
  };

  const handleChangePhone = (event: React.ChangeEvent<HTMLInputElement>) => {
    setErrMessage(null);
    let localPhoneNumber = event.target.value;
    const phoneNumberLength = localPhoneNumber.replaceAll(" ", "").length;
    if (phoneNumberLength === 16 && localPhoneNumber[1] !== "7") {
      localPhoneNumber =
        "+7" + localPhoneNumber.substring(2, localPhoneNumber.length);
    } else if (localPhoneNumber[1] !== "7" && localPhoneNumber !== "") {
      if (localPhoneNumber[1] === "8") {
        localPhoneNumber = "+7";
      } else {
        localPhoneNumber =
          "+7" + localPhoneNumber.substring(1, localPhoneNumber.length);
      }
    } else if (localPhoneNumber === "") {
      localPhoneNumber = "";
    }
    setPhoneLocal({ number: localPhoneNumber });
  };

  const onCapchaChange = (value: any) => {
    console.log("Captcha value:", value);
    value && setIsCapchaSuccess(true);
  };

  return (
    <div className={classes.formBox}>
      <PatternFormat
        id="phone"
        label="Ваш номер"
        customInput={TextFieldMainForPhone}
        type="tel"
        format="+# (###) ###-##-##"
        placeholder="+7 (800) 000-00-00"
        // className="main_input"
        onKeyPress={handlePressKey}
        value={phone.number}
        error={Boolean(errMessage)}
        onChange={handleChangePhone}
        autoFocus={true}
        inputMode="numeric"
        InputLabelProps={{
          shrink: true,
        }}
      />
      {errMessage && (
        <div className={classes.error_flex}>
          <WarningSign />
          <span>{errMessage}</span>
        </div>
      )}
      {isCheck && (
        <div style={{ alignSelf: "center" }}>
          <ReCAPTCHA sitekey={CKeyV2} onChange={onCapchaChange} />
          {/* <SmartCaptcha sitekey={keyYandexCapcha} onSuccess={setToken} />; */}
        </div>
      )}
      <button
        disabled={!isCapchaSuccess && isCheck}
        className="button_black"
        onClick={handleClickSendPhone}
      >
        Продолжить
      </button>
    </div>
  );
};

export default memo(PhoneForm);
