export const MARKETPLACES = [
    { key: "Wildberries", name: "Wildberries" },
    { key: "OZON", name: "OZON" },
    { key: "Яндекс.Маркет", name: "Яндекс.Маркет" },
    { key: "СберМегаМаркет", name: "СберМегаМаркет" },
    { key: "AliExpress Россия", name: "AliExpress Россия" },
    { key: "KazanExpress", name: "KazanExpress" },
    { key: "СДЭК.МАРКЕТ", name: "СДЭК.МАРКЕТ" },
    { key: "JoomPRO", name: "JoomPRO" },
    { key: "Авито", name: "Авито" },
    { key: "Lamoda", name: "Lamoda" },
    { key: "Натали", name: "Натали" },
    { key: "Supl", name: "Supl" },
    { key: "Цифровая Витрина", name: "Цифровая Витрина" },
    { key: "Satom", name: "Satom" },
    { key: "Пульс Цен", name: "Пульс Цен" },
    { key: "Na.Market", name: "Na.Market" },
    { key: "Berito", name: "Berito" },
    { key: "Kaspi", name: "Kaspi" },
];
