import { SVGProps } from "react";

type Props = SVGProps<SVGSVGElement>;

export const ArrowRight = (props: Props) => {
    return (
        <svg
            width="6"
            height="12"
            viewBox="0 0 6 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.375342 11.7809C-0.0559202 11.4359 -0.125842 10.8066 0.219168 10.3753L3.71941 6.00002L0.219168 1.62472C-0.125842 1.19346 -0.0559207 0.564164 0.375341 0.219154C0.806603 -0.125855 1.4359 -0.055934 1.78091 0.375328L5.78091 5.37533C6.07308 5.74055 6.07308 6.2595 5.78091 6.62472L1.78091 11.6247C1.4359 12.056 0.806604 12.1259 0.375342 11.7809Z"
                fill="currentColor"
            />
        </svg>
    );
};
