import { DataForOrderActionTypes, DataForOrderState, ChangeDataForOrderAction } from '../../types/dataForOrder'

const initialState: DataForOrderState = {
    banksList: [],
    inn: '',
    draftIds: [],
    proposalIdForDeleteAccordion: undefined,
    requiredFields: [],
    isNewBasedOnOld: false
};

export const dataForOrderReducer = (state = initialState, action: ChangeDataForOrderAction) => {
    switch (action.type) {
        case DataForOrderActionTypes.DATA_FOR_ORDER_PUSH:
            return { ...state, ...action.payload }
        case DataForOrderActionTypes.DATA_FOR_DRAFT_ORDER_PUSH:
            return { ...state, draftIds: action.payload }
        case DataForOrderActionTypes.INN_FOR_ORDER_PUSH:
            return { ...state, inn: action.payload.inn }
        case DataForOrderActionTypes.PROPOSAL_ID_FOR_DELETE:
            return { ...state, proposalIdForDeleteAccordion: action.payload.proposalIdForDeleteAccordion }
        case DataForOrderActionTypes.SET_REQUIRED_FIELDS:
            return { ...state, requiredFields: action.requiredFields }
        case DataForOrderActionTypes.SET_IS_NEW_BASED_ON_OLD:
            return { ...state, isNewBasedOnOld: action.payload }
        case DataForOrderActionTypes.DELETE_BANK_FROM_BANK_LIST:
            const newBankList = state.banksList.filter((bank) => {
                return bank.id !== action.proposalId
            })
            return { ...state, banksList: newBankList }
        default: {
            return state
        }
    }
}
