import { Outlet } from "react-router-dom";
import "../../App.scss";
import styles from "./LayoutUser.module.scss";
import { actions } from "../../core/store";
import HeaderUser from "./HeaderUser";
import FooterUser from "./FooterUser";
import React from "react";

const LayoutUser = () => {
    React.useEffect(() => {
        return () => {
            actions.setPartnerApplicationScroll(null);
            actions.setFilterValue({
                field: "inn",
                value: "",
            });
            actions.setFilterValue({
                field: "period",
                value: "all",
            });
            actions.setFilterValue({
                field: "status",
                value: "all",
            });
            actions.setFilterValue({
                field: "product",
                value: "all",
            });
        };
    }, []);

    return (
        <div className={styles.layout_main}>
            <HeaderUser />
            <div className={styles.container}>
                <Outlet />
            </div>
            <div className={styles.spacer} />
            <FooterUser />
        </div>
    );
};

export default LayoutUser;
