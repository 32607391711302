type LikeProps = {
    color?: string;
}
export const Likes: React.FC<LikeProps> = ({ color = "#828282" }) =>
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.2 5.71995C13.0127 5.49505 12.7783 5.314 12.5133 5.1896C12.2484 5.06519 11.9594 5.00045 11.6667 4.99995H8.62668L9.00001 4.04662C9.1553 3.6292 9.20701 3.18035 9.15072 2.73856C9.09443 2.29677 8.9318 1.87523 8.6768 1.5101C8.4218 1.14497 8.08203 0.847146 7.68664 0.642177C7.29125 0.437208 6.85203 0.331212 6.40668 0.333282C6.27844 0.333549 6.15299 0.370799 6.04539 0.440564C5.93779 0.510329 5.85259 0.609648 5.80001 0.726615L3.90001 4.99995H2.33334C1.80291 4.99995 1.2942 5.21066 0.91913 5.58573C0.544057 5.96081 0.333344 6.46952 0.333344 6.99995V11.6666C0.333344 12.197 0.544057 12.7058 0.91913 13.0808C1.2942 13.4559 1.80291 13.6666 2.33334 13.6666H10.82C11.2879 13.6665 11.7409 13.5023 12.1002 13.2026C12.4596 12.903 12.7025 12.4868 12.7867 12.0266L13.6333 7.35995C13.6857 7.07153 13.674 6.77514 13.5992 6.49173C13.5243 6.20833 13.388 5.94485 13.2 5.71995ZM3.66668 12.3333H2.33334C2.15653 12.3333 1.98696 12.263 1.86194 12.138C1.73691 12.013 1.66668 11.8434 1.66668 11.6666V6.99995C1.66668 6.82314 1.73691 6.65357 1.86194 6.52854C1.98696 6.40352 2.15653 6.33328 2.33334 6.33328H3.66668V12.3333ZM12.3333 7.11995L11.4867 11.7866C11.4583 11.9419 11.3757 12.0821 11.2536 12.1822C11.1315 12.2823 10.9779 12.3359 10.82 12.3333H5.00001V5.80661L6.81334 1.72662C7 1.78103 7.17335 1.87355 7.32245 1.99834C7.47155 2.12312 7.59316 2.27746 7.67961 2.45161C7.76606 2.62576 7.81547 2.81594 7.82471 3.01015C7.83396 3.20436 7.80285 3.39837 7.73334 3.57995L7.38001 4.53328C7.30472 4.73479 7.2793 4.95151 7.30593 5.16497C7.33255 5.37842 7.41042 5.58227 7.5329 5.75911C7.65538 5.93595 7.81882 6.08053 8.00928 6.18051C8.19974 6.28049 8.41157 6.33291 8.62668 6.33328H11.6667C11.7646 6.33312 11.8614 6.35455 11.9501 6.39603C12.0388 6.43751 12.1173 6.49803 12.18 6.57328C12.2442 6.6475 12.2913 6.73498 12.3178 6.82948C12.3443 6.92397 12.3496 7.02316 12.3333 7.11995Z" fill={color} />
    </svg>

export const Views = () =>
    <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.6133 5.73329C12.2667 2.60663 9.73332 0.666626 6.99998 0.666626C4.26665 0.666626 1.73332 2.60663 0.38665 5.73329C0.349941 5.8174 0.330994 5.90819 0.330994 5.99996C0.330994 6.09173 0.349941 6.18251 0.38665 6.26663C1.73332 9.39329 4.26665 11.3333 6.99998 11.3333C9.73332 11.3333 12.2667 9.39329 13.6133 6.26663C13.65 6.18251 13.669 6.09173 13.669 5.99996C13.669 5.90819 13.65 5.8174 13.6133 5.73329ZM6.99998 9.99996C4.88665 9.99996 2.88665 8.47329 1.73332 5.99996C2.88665 3.52663 4.88665 1.99996 6.99998 1.99996C9.11332 1.99996 11.1133 3.52663 12.2667 5.99996C11.1133 8.47329 9.11332 9.99996 6.99998 9.99996ZM6.99998 3.33329C6.47257 3.33329 5.95699 3.48969 5.51846 3.78271C5.07993 4.07572 4.73814 4.4922 4.5363 4.97947C4.33447 5.46674 4.28166 6.00292 4.38456 6.5202C4.48745 7.03748 4.74143 7.51264 5.11437 7.88558C5.48731 8.25852 5.96246 8.51249 6.47974 8.61539C6.99703 8.71828 7.5332 8.66547 8.02047 8.46364C8.50774 8.2618 8.92422 7.92001 9.21724 7.48148C9.51025 7.04295 9.66665 6.52738 9.66665 5.99996C9.66665 5.29272 9.3857 4.61444 8.8856 4.11434C8.3855 3.61424 7.70723 3.33329 6.99998 3.33329ZM6.99998 7.33329C6.73628 7.33329 6.47849 7.25509 6.25922 7.10858C6.03996 6.96208 5.86906 6.75384 5.76814 6.5102C5.66723 6.26657 5.64082 5.99848 5.69227 5.73984C5.74372 5.4812 5.8707 5.24362 6.05717 5.05715C6.24364 4.87068 6.48122 4.74369 6.73986 4.69225C6.9985 4.6408 7.26659 4.6672 7.51023 4.76812C7.75386 4.86904 7.9621 5.03993 8.10861 5.2592C8.25512 5.47846 8.33332 5.73625 8.33332 5.99996C8.33332 6.35358 8.19284 6.69272 7.94279 6.94277C7.69274 7.19282 7.35361 7.33329 6.99998 7.33329Z" fill="#828282" />
    </svg>


export const Arrow = () =>
    <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.1667 10C9.94565 10 9.73368 9.91221 9.5774 9.75593C9.42112 9.59965 9.33333 9.38769 9.33333 9.16667V2.84167L2.42499 9.75834C2.26807 9.91526 2.05524 10.0034 1.83333 10.0034C1.61141 10.0034 1.39858 9.91526 1.24166 9.75834C1.08474 9.60142 0.996582 9.38859 0.996582 9.16667C0.996582 8.94476 1.08474 8.73193 1.24166 8.57501L8.15833 1.66667H1.83333C1.61231 1.66667 1.40035 1.57888 1.24407 1.4226C1.08779 1.26632 0.999992 1.05435 0.999992 0.833341C0.999992 0.612327 1.08779 0.400365 1.24407 0.244084C1.40035 0.0878038 1.61231 7.62939e-06 1.83333 7.62939e-06H10.1667C10.2756 0.0013237 10.3831 0.0239735 10.4833 0.0666742C10.6869 0.151237 10.8488 0.31305 10.9333 0.516675C10.976 0.61686 10.9987 0.724443 11 0.833341V9.16667C11 9.38769 10.9122 9.59965 10.7559 9.75593C10.5996 9.91221 10.3877 10 10.1667 10Z" fill="#306F9A" />
    </svg>

export const MenuIconMobile = () =>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M3 5C3 4.44772 3.44772 4 4 4H20C20.5523 4 21 4.44772 21 5C21 5.55228 20.5523 6 20 6H4C3.44772 6 3 5.55228 3 5Z" fill="#242424" />
        <path d="M3 12C3 11.4477 3.44772 11 4 11H20C20.5523 11 21 11.4477 21 12C21 12.5523 20.5523 13 20 13H4C3.44772 13 3 12.5523 3 12Z" fill="#242424" />
        <path d="M4 18C3.44772 18 3 18.4477 3 19C3 19.5523 3.44772 20 4 20H20C20.5523 20 21 19.5523 21 19C21 18.4477 20.5523 18 20 18H4Z" fill="#242424" />
    </svg>

export const Close = () =>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path fillRule="evenodd" clipRule="evenodd" d="M4.29289 4.29289C4.68342 3.90237 5.31658 3.90237 5.70711 4.29289L12 10.5858L18.2929 4.29289C18.6834 3.90237 19.3166 3.90237 19.7071 4.29289C20.0976 4.68342 20.0976 5.31658 19.7071 5.70711L13.4142 12L19.7071 18.2929C20.0976 18.6834 20.0976 19.3166 19.7071 19.7071C19.3166 20.0976 18.6834 20.0976 18.2929 19.7071L12 13.4142L5.70711 19.7071C5.31658 20.0976 4.68342 20.0976 4.29289 19.7071C3.90237 19.3166 3.90237 18.6834 4.29289 18.2929L10.5858 12L4.29289 5.70711C3.90237 5.31658 3.90237 4.68342 4.29289 4.29289Z" fill="#242424" />
    </svg>

export const RollArrowIcon = () =>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path fillRule="evenodd" clipRule="evenodd" d="M6.21917 9.37534C6.56418 8.94408 7.19347 8.87416 7.62473 9.21917L12 12.7194L16.3753 9.21917C16.8066 8.87416 17.4359 8.94408 17.7809 9.37534C18.1259 9.8066 18.056 10.4359 17.6247 10.7809L12.6247 14.7809C12.2595 15.0731 11.7406 15.0731 11.3753 14.7809L6.37534 10.7809C5.94408 10.4359 5.87416 9.8066 6.21917 9.37534Z" fill="#242424" />
    </svg>
export const StatusCircleIcon = (props: any) =>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
        <circle cx="8" cy="8.5" r="6" fill="white" stroke={`#${props.color}`} strokeWidth="4" />
    </svg>
export const CopyButton = () =>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="inherit">
        <path fillRule="evenodd" clipRule="evenodd" d="M5 2C4.44772 2 4 2.44772 4 3V17C4 17.5523 4.44772 18 5 18C5.55228 18 6 17.5523 6 17V5C6 4.44772 6.44772 4 7 4H13C13.5523 4 14 3.55228 14 3C14 2.44772 13.5523 2 13 2H5ZM15 12C14.4477 12 14 11.5523 14 11V8.4C14 8.17909 13.8209 8 13.6 8H10.4C10.1791 8 10 8.17909 10 8.4V19.6C10 19.8209 10.1791 20 10.4 20H17.6C17.8209 20 18 19.8209 18 19.6V12.4C18 12.1791 17.8209 12 17.6 12H15ZM14.7515 6C14.9106 6 15.0632 6.06321 15.1757 6.17574L19.8243 10.8243C19.9368 10.9368 20 11.0894 20 11.2485V21C20 21.5523 19.5523 22 19 22H9C8.44772 22 8 21.5523 8 21V7C8 6.44772 8.44771 6 9 6H14.7515Z" fill="inherit" />
    </svg>