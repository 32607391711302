import { Typography, IconButton, Dialog, useMediaQuery } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import classes from "./WhiteModal.module.scss";

export type Props = React.PropsWithChildren<{
    title?: string;
    isOpen: boolean;
    className?: string;
    handleClose?: () => void;
}>;

export function WhiteModal(props: Props) {
    const isLagerThan480 = useMediaQuery("(min-width:480px)");
    const handleClose = () => {
        props?.handleClose?.();
    };

    return (
        <Dialog
            open={props.isOpen}
            onClose={handleClose}
            scroll={"body"}
            fullScreen={isLagerThan480 ? false : true}
            className={classes.dialog}
            maxWidth={"md"}
            fullWidth={true}
            // maxWidth={false}
            sx={
                {
                    // width: ["100%"],
                    // margin: "0px"
                }
            }
            PaperProps={{
                style: { borderRadius: isLagerThan480 ? "24px" : 0 },
            }}
        >
            <div className={classes.paper}>
                <div className={classes.header}>
                    <div className={classes.title}>
                        {props?.title && (
                            <Typography
                                variant="h3"
                                component="h3"
                            >
                                {props.title}
                            </Typography>
                        )}
                    </div>
                    <IconButton aria-label="close" onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </div>
                <div className={classes.content}>{props.children}</div>
            </div>
        </Dialog>
    );
}
